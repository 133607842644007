import { call, put, delay, takeLatest, select } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { marketValues } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { waitFor } from '../../../services';
import { activeFiltersSelector } from '../../ui/portfolio/selectors';

// Create actions and a reducer for the network resource
export const [actions, reducer, resourceSagas] =
  resourceReducer('marketvalues');

// Create the sagas for this entity
function* readMarketValues() {
  yield takeLatest(actions.indexRequestBegin, function* () {
    yield call(waitFor, (state) => state.ui.portfolio.initialized);
    const activeFilters = yield select(activeFiltersSelector);
    const args = {
      ssids: activeFilters.ssids,
      taxClass: activeFilters.taxClass,
      showInternalPortfolio: activeFilters.showInternalPortfolio,
      showExternalPortfolio: activeFilters.showExternalPortfolio,
      account: activeFilters.account,
    };

    if (!activeFilters.startDate || !activeFilters.endDate) {
      args.period = activeFilters.period;
    } else {
      args.startDate = activeFilters.startDate;
      const _endDate = new Date(activeFilters.endDate);
      args.endDate = new Date(
        _endDate.getFullYear(),
        _endDate.getMonth() + 1,
        2 // Add two days to account for timezone issues
      )
        .toISOString()
        .split('T')[0];
    }

    try {
      const result = yield call(marketValues.get, args);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readMarketValues, ...resourceSagas];
