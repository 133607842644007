import React from 'react';
import styled, { css } from 'styled-components';

import CheckIconUrl from '../../assets/icons/check-icon.svg';
import DashIconUrl from '../../assets/icons/dash-icon.svg';

import {
  backgroundWhite,
  blackTranslucent,
  checkboxCustom,
  accent,
} from '../../constants/colors';
import { SPACING_16, SPACING_8 } from '../../constants/spacing';
import { Paragraph } from '../../components/texts';

const StyledCheckboxWrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_16};
  margin-bottom: ${SPACING_8};
`;

const StyledToggleInner = styled.div`
  border: ${(props) => (props.checked ? 0 : `1px solid ${accent.ocean470}`)};
  border-radius: 2px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: block;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  background: ${(props) =>
    props.checked ? checkboxCustom.background : backgroundWhite};

  ${(props) =>
    !props.checked &&
    css`
      background: none;
    `}

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        border-color: ${(props) => !props.checked && accent.ocean4};
      }
    `}

  &:after {
    height: ${(props) => `${props.size}px`};
    width: ${(props) => `${props.size}px`};
    content: '';
    background-image: url(${(props) =>
      props.partialChecked ? DashIconUrl : CheckIconUrl});
    display: ${(props) => (props.checked ? 'flex' : 'none')};
    justify-content: center;
    padding: 0;
    line-height: 100%;
    scale: ${(props) => props.scale};
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const StyledCheckbox = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 30px;
`;

export const Checkbox = (props) => {
  const {
    id,
    label,
    onChange = () => {},
    disabled,
    checked,
    partialChecked = false,
    labelColor,
    backgroundColor = blackTranslucent(0.04),
    backgroundColorActive = checkboxCustom.background,
    type = 'checkbox',
    size = 20,
    ...rest
  } = props;

  let { name } = props;

  if (!name) {
    name = id;
  }

  return (
    <StyledCheckboxWrapper className="toggle" htmlFor={id} {...rest}>
      <StyledToggleInner
        checked={checked}
        backgroundColor={backgroundColor}
        backgroundColorActive={backgroundColorActive}
        size={size}
        scale={size / 20}
        disabled={disabled}
        partialChecked={partialChecked}
      >
        <StyledCheckbox
          type={type}
          name={name}
          id={id}
          checked={checked}
          onChange={(event) => onChange(event)}
          disabled={disabled}
        />
      </StyledToggleInner>
      {label && (
        <label htmlFor={id}>
          <Paragraph style={{ color: labelColor }}>{label}</Paragraph>
        </label>
      )}
    </StyledCheckboxWrapper>
  );
};
