import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { times } from 'lodash';
import { useMediaQuery } from 'react-responsive';

import { selectors, t, useResource } from '@formue-app/core';
import { EventCarouselItem } from './EventCarouselItem';
import { BaseSection } from '../common/BaseSection';
import { accent } from '../../constants/colors';
import { tabletCondition } from '../../constants/media';
import { AppTeaserWidget } from '../common/AppTeaserWidget';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';
import { MeetingCarouselItem } from './MeetingCarouselItem';

const imageFallback = require('../../assets/images/default/formue.png');

const StyledButtonBack = styled(ButtonBack)`
  position: absolute;
  left: 5px;
  bottom: 50%;
  margin-bottom: -10px;
  outline: none;
  background-color: transparent;
  border: none;
`;

const StyledButtonNext = styled(ButtonNext)`
  position: absolute;
  right: 5px;
  bottom: 50%;
  margin-bottom: -10px;
  outline: none;
  background-color: transparent;
  border: none;
`;

const StyledDotGroup = styled(DotGroup)`
  position: absolute;
  bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledDot = styled.div`
  margin: 0 6px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${(props) => (props.active ? accent.ocean490 : '#E0D5C6')};
`;

const {
  entities: {
    meetings: { upcomingMeetingsSelector, pastMeetingsSelector },
    events: { upcomingEventSelector },
    webinars: { allWebinarsSelector },
    podcastEpisodes: { allPodcastEpisodesSelector },
  },
} = selectors;

export const EventCarousel = ({ columns }) => {
  const upcomingMeetings = useSelector(upcomingMeetingsSelector);
  const pastMeetings = useSelector(pastMeetingsSelector);
  const upcomingEvent = useSelector(upcomingEventSelector);
  const webinars = useSelector(allWebinarsSelector);
  const podcastEpisodes = useSelector(allPodcastEpisodesSelector);
  const loading = useResource([
    'MEETINGS/INDEX',
    'EVENTS/INDEX',
    'WEBINARS/INDEX',
    'PODCAST_EPISODES/INDEX',
  ]);

  const latestWebinar = webinars[0];
  const latestPodcastEpisode = podcastEpisodes[0];

  const isTablet = useMediaQuery({
    query: tabletCondition,
  });

  let sliderHeight = 62;
  let sliderWidth = 100;

  if (isTablet) {
    sliderHeight = 50;
    sliderWidth = 150;
  }

  const renderDots = ({ totalSlides, currentSlide }) => {
    return times(totalSlides, (index) => {
      return <StyledDot key={`dot-${index}`} active={index === currentSlide} />;
    });
  };

  if (loading) {
    return (
      <BaseSection columns={columns}>
        <CenteredActivityIndicator />
      </BaseSection>
    );
  }

  if (
    !upcomingEvent &&
    !latestWebinar &&
    !latestPodcastEpisode &&
    !upcomingMeetings
  ) {
    return <AppTeaserWidget columns={columns} />;
  }

  const numberOfItems = [
    upcomingEvent,
    latestWebinar,
    latestPodcastEpisode,
    upcomingMeetings[0],
  ].filter((item) => item).length;

  return (
    <BaseSection
      style={{ padding: 0, overflow: 'hidden', position: 'relative' }}
      columns={columns}
    >
      <CarouselProvider
        naturalSlideWidth={sliderWidth}
        naturalSlideHeight={sliderHeight}
        totalSlides={numberOfItems}
        isPlaying={true}
        infinite={true}
      >
        <Slider>
          {upcomingMeetings[0] && (
            <Slide key="upcoming-meetings">
              <MeetingCarouselItem
                slideTitle={t('eventCalendar:meetings:nextWeekend')}
                meeting={upcomingMeetings[0]}
              />
            </Slide>
          )}
          {upcomingEvent && (
            <Slide key="upcoming-event">
              <EventCarouselItem
                imageFallback={imageFallback}
                slideTitle={t('eventCalendar:events')}
                title={upcomingEvent.title}
                description={t('eventCalendar:nextUpcomingEvent')}
                image={upcomingEvent.image}
                link={`/events/${upcomingEvent.id}`}
                linkToArchive="/events"
                linkToArchiveText={t('eventCalendar:seeAllEvents')}
                date={upcomingEvent.startsAt}
              />
            </Slide>
          )}
          {latestWebinar && (
            <Slide key="latest-webinar">
              <EventCarouselItem
                imageFallback={imageFallback}
                slideTitle={t('eventCalendar:ourWebArchive')}
                title={latestWebinar.title}
                image={latestWebinar.image}
                link={`/webinars/${latestWebinar.id}`}
                linkToArchive="/webinars"
                linkToArchiveText={t('eventCalendar:seeAllWebinars')}
                date={latestWebinar.publishedAt}
              />
            </Slide>
          )}
          {latestPodcastEpisode && (
            <Slide key="latest-podcast">
              <EventCarouselItem
                imageFallback={imageFallback}
                slideTitle={t('dashboard:podcast:latest')}
                title={latestPodcastEpisode.podcast.title}
                image={latestPodcastEpisode.podcast.image}
                link={latestPodcastEpisode.podcast.link}
                externalLink
                linkToArchive="/podcasts"
                linkToArchiveText={t('dashboard:podcast:previous')}
                date={latestPodcastEpisode.publishedAt}
              />
            </Slide>
          )}
        </Slider>
        {numberOfItems > 1 && (
          <>
            <StyledButtonBack>
              <SpriteIconFunctional
                id="Simple arrow"
                direction="Left"
                color={accent.sand2}
              />
            </StyledButtonBack>
            <StyledButtonNext>
              <SpriteIconFunctional
                id="Simple arrow"
                direction="Right"
                color={accent.sand2}
              />
            </StyledButtonNext>
            <StyledDotGroup renderDots={renderDots} />
          </>
        )}
      </CarouselProvider>
    </BaseSection>
  );
};
