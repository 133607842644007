import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { capitalize } from 'lodash';

import {
  formatPeriodLabel,
  getLocalizedMonthName,
  t,
  MONTHS,
  getStartAndEndDate,
  getPredefinedRanges,
} from '@formue-app/core';

import {
  H2,
  H2Small,
  Paragraph,
  ParagraphSmall,
  ParagraphXSmall,
} from '../../texts';
import {
  SPACING_12,
  SPACING_16,
  SPACING_2,
  SPACING_24,
  SPACING_32,
  SPACING_4,
  SPACING_48,
  SPACING_8,
} from '../../../constants/spacing';
import { accent, backgroundWhite, uiHover } from '../../../constants/colors';
import { Button, ButtonRound } from '../../buttons';
import { SpriteIcon } from '../../common/SpriteIcon';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';
import { HorizontalDivider } from '../../common/HorizontalDivider';

const ButtonContainer = styled.div`
  display: flex;
  gap: ${SPACING_8};
  margin-top: ${SPACING_32};
  margin-bottom: ${SPACING_24};
`;

const YearSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${SPACING_16};
  margin-top: ${SPACING_24};
  margin-bottom: ${SPACING_12};
`;

const ArrowWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
`;

const StyledArrows = styled(SpriteIconFunctional)`
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.3;
    `}
`;

const MonthWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${SPACING_8};
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${SPACING_32};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
  margin-left: auto;
`;

const Month = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 0 0 calc((100% - 5 * ${SPACING_8}) / 6);
  align-items: center;
  box-shadow: inset 0px 0px 0px 1px ${accent.ocean420};
  padding: ${SPACING_16};
  border-radius: 4px;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.3;
    `}

  ${(props) =>
    props.betweenActiveMonth &&
    css`
      background: ${uiHover};
      box-shadow: inset 0px 0px 0px 1px ${accent.ocean190};
    `}

    ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        box-shadow: inset 0px 0px 0px 2px ${accent.ocean490};
      }
    `}
  ${(props) =>
    props.activeMonth &&
    css`
      background: ${uiHover};
      box-shadow: inset 0px 0px 0px 2px ${accent.ocean490};
    `}
`;

const StyledHorizontalDivider = styled(HorizontalDivider)`
  background: ${accent.ocean420};
  margin: ${SPACING_24} 0;
  margin-left: -${SPACING_48};
  margin-right: -${SPACING_48};
  width: calc(100% + 2 * ${SPACING_48});
`;

const StyledButtonRound = styled(ButtonRound)`
  background: ${backgroundWhite};
  border: 1px solid ${accent.ocean420};
  color: ${accent.ocean470};

  ${(props) =>
    props.active &&
    css`
      color: ${accent.ocean490};
      background: ${accent.ocean230};
      border: 1px solid ${accent.ocean490};
    `};

  &:hover:not(:disabled) {
    background-color: ${accent.ocean230};
  }
`;

export function DateFilter({
  onRequestClose,
  onChange,
  minYear = 2013,
  excludedPeriods = [],
}) {
  const periodValues = useSelector(
    (state) => state.ui.portfolio.filterValues.period
  );
  const activePeriod = useSelector(
    (state) => state.ui.portfolio.filters.period
  );

  const filteredPeriodValues = periodValues.filter(
    (period) => !excludedPeriods.includes(period)
  );

  const [year, setYear] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [localPeriod, setLocalPeriod] = useState('CUSTOM');
  const [customDateEditor, setCustomDateEditor] = useState(
    activePeriod === 'CUSTOM'
  );

  useEffect(() => {
    if (activePeriod !== 'CUSTOM') predefinedRanges(activePeriod);
  }, [activePeriod]);

  useEffect(() => {
    setCustomDateEditor(localPeriod === 'CUSTOM');
  }, [localPeriod]);

  const incrementYear = () => {
    if (year < new Date().getFullYear()) {
      setYear(year + 1);
    }
  };

  const decrementYear = () => {
    if (year > minYear) {
      setYear(year - 1);
    }
  };

  const onMonthClick = (monthIndex) => {
    const { newStartDate, newEndDate } = getStartAndEndDate(
      monthIndex,
      year,
      startDate,
      endDate
    );

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const predefinedRanges = (period) => {
    const { newStartDate, newEndDate } = getPredefinedRanges(period);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    if (customDateEditor) {
      setStartDate(null);
      setEndDate(null);
      setLocalPeriod('CUSTOM');
    }
  }, [customDateEditor]);

  return (
    <>
      <H2>{t('filters:dateRangeTitle')}</H2>
      <ButtonContainer>
        {filteredPeriodValues.map((period, index) => (
          <StyledButtonRound
            buttonstyle="outline"
            key={`period-${index}`}
            onClick={() => {
              setLocalPeriod(period);
              predefinedRanges(period);
            }}
            active={localPeriod === period && !customDateEditor}
          >
            {formatPeriodLabel(period)}
          </StyledButtonRound>
        ))}
        <StyledButtonRound
          buttonstyle="outline"
          onClick={() => {
            setLocalPeriod('CUSTOM');
          }}
          active={customDateEditor}
        >
          {t('filters:period:CUSTOM')}
        </StyledButtonRound>
      </ButtonContainer>

      <StyledHorizontalDivider />

      {customDateEditor ? (
        <>
          <YearSelector>
            <ArrowWrapper>
              <StyledArrows
                id="Pointer arrow"
                direction="Left"
                onClick={decrementYear}
                disabled={year === minYear}
                size={SPACING_24}
              />
              <H2Small>{year}</H2Small>
              <StyledArrows
                id="Pointer arrow"
                direction="Right"
                onClick={incrementYear}
                disabled={year === new Date().getFullYear()}
                size={SPACING_24}
              />
            </ArrowWrapper>
            <ParagraphSmall>{t('filters:dateRange')}</ParagraphSmall>
          </YearSelector>
          <MonthWrapper>
            {MONTHS.map((month, index) => {
              const now = new Date();
              const activeMonth =
                (startDate &&
                  startDate.getMonth() === index &&
                  startDate.getFullYear() === year) ||
                (endDate &&
                  endDate.getMonth() === index &&
                  endDate.getFullYear() === year);

              const betweenActiveMonth =
                startDate &&
                endDate &&
                new Date(year, index, 1) > startDate &&
                new Date(year, index, 1) < endDate;

              const highlightedMonth = activeMonth || betweenActiveMonth;
              const disabled =
                index > now.getMonth() && year === now.getFullYear();

              return (
                <Month
                  key={`month-${index}`}
                  onClick={() => !disabled && onMonthClick(index)}
                  activeMonth={activeMonth}
                  betweenActiveMonth={betweenActiveMonth}
                  disabled={disabled}
                >
                  <SpriteIcon
                    id={highlightedMonth ? 'Calendar folded' : 'Calendar'}
                    navigational={highlightedMonth ? false : true}
                    color={!highlightedMonth ? accent.ocean450 : null}
                    accentColor={!highlightedMonth ? accent.ocean450 : null}
                    tertiaryColor={!highlightedMonth ? accent.ocean450 : null}
                    size={20}
                    style={{ marginBottom: SPACING_4 }}
                  />
                  <Paragraph
                    style={{
                      color: !highlightedMonth
                        ? accent.ocean470
                        : accent.ocean490,
                      marginBottom: SPACING_2,
                      fontWeight: 500,
                    }}
                  >
                    {capitalize(month)}
                  </Paragraph>
                  <ParagraphXSmall
                    style={{
                      color: !highlightedMonth
                        ? accent.ocean470
                        : accent.ocean490,
                    }}
                  >
                    {year}
                  </ParagraphXSmall>
                </Month>
              );
            })}
          </MonthWrapper>

          <StyledHorizontalDivider />
        </>
      ) : null}

      <Footer>
        {startDate || endDate ? (
          <Paragraph>
            {`${t('filters:selectedDate')}: `}
            <span style={{ fontWeight: 600 }}>
              {startDate &&
                !endDate &&
                `${getLocalizedMonthName(
                  startDate.getMonth(),
                  true
                )} ${startDate.getFullYear()}`}
              {startDate &&
                endDate &&
                `${getLocalizedMonthName(
                  startDate.getMonth(),
                  true
                )} ${startDate.getFullYear()} - ${getLocalizedMonthName(
                  endDate.getMonth(),
                  true
                )} ${endDate.getFullYear()}`}
            </span>
          </Paragraph>
        ) : null}

        <ButtonWrapper>
          <Button
            style={{
              border: `1px solid ${accent.ocean1}`,
              background: backgroundWhite,
              color: accent.ocean1,
              textTransform: 'capitalize',
            }}
            onClick={() => onRequestClose()}
          >
            {t('globals:cancel')}
          </Button>
          <Button
            disabled={!startDate && customDateEditor}
            onClick={() => {
              let _endDate = endDate;

              if (!_endDate) {
                _endDate = new Date(
                  startDate.getFullYear(),
                  startDate.getMonth() + 1,
                  startDate.getDate()
                );
                setEndDate(_endDate);
              }

              onChange([localPeriod, startDate, _endDate]);
              onRequestClose();
            }}
          >
            {t('filters:selectDate')}
          </Button>
        </ButtonWrapper>
      </Footer>
    </>
  );
}
