import React, { useState } from 'react';
import { formatReturnPerYearChartData } from '@formue-app/core';
import styled, { css } from 'styled-components';
import { maxBy, minBy } from 'lodash';

import { accent } from '../../../../constants/colors';
import { PortfolioReturnChartLabel } from './PortfolioReturnChartLabel';
import { H3 } from '../../../texts';
import {
  BORDER_RADIUS_LARGE,
  SPACING_32,
  SPACING_16,
} from '../../../../constants/spacing';
import { useBoundingRect } from '../../../../services/hooks/layout';
import {
  easeInOut,
  longAnimationTime,
  shortAnimationTime,
} from '../../../../constants/animation';
import { desktopCondition, mobileCondition } from '../../../../constants/media';
import { H5LineHeight, H5Size } from '../../../../constants/text';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';

const barWidthPercent = 20;
const barWidthMobilePercent = 40;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  align-self: flex-end;
  position: relative;
  padding-top: 22px;
  padding-bottom: 22px;

  @media ${desktopCondition} {
    overflow: hidden;
  }
`;

const ChartInner = styled.div`
  align-self: flex-end;
  width: ${(props) => 100 + (props.count - 5) * barWidthPercent}%;
  margin-right: ${(props) => -barWidthPercent * props.offset}%;
  transition: margin-right ${shortAnimationTime} ${easeInOut};
  height: 100%;

  @media ${mobileCondition} {
    margin-right: ${(props) => -barWidthMobilePercent * props.offset}%;
    width: ${(props) => 100 + (props.count - 5) * barWidthMobilePercent}%;
  }
`;

const Bars = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

const BarWrapper = styled.div`
  width: ${barWidthPercent}%;
  text-align: center;
  height: 100%;

  @media ${mobileCondition} {
    width: ${barWidthMobilePercent}%;
  }
`;

const BarInner = styled.div`
  top: ${(props) => props.axisPosition}px;
  position: relative;
  width: calc(100% - 36px);
  ${(props) =>
    !props.lastItem &&
    css`
      &:after {
        content: '';
        display: block;
        position: relative;
        height: 2px;
        background: ${accent.ocean2};
        width: 24px;
        margin-left: 6px;
        align-self: flex-start;
        border-radius: 1px;
        transition: all ${longAnimationTime} ${easeInOut};
        left: 100%;

        @media ${mobileCondition} {
          margin-left: 2px;
          width: 12px;
        }
      }
    `}

  @media ${mobileCondition} {
    width: calc(100% - 16px);
  }
`;

const BarArea = styled.div`
  width: 100%;
  background-color: ${accent.ocean4};
  border-radius: ${BORDER_RADIUS_LARGE};
  height: ${(props) => Math.abs(props.height)}px;
  transition: all ${longAnimationTime} ${easeInOut};
  position: absolute;
  bottom: ${(props) => (!props.isNegative ? 0 : 'auto')};
`;

const Labels = styled.div`
  display: flex;
  padding-top: ${SPACING_16};
  position: absolute;
  width: ${(props) => 100 + (props.count - 5) * barWidthPercent}%;

  @media ${mobileCondition} {
    width: ${(props) => 100 + (props.count - 5) * barWidthMobilePercent}%;
  }
`;

const LabelWrapper = styled.div`
  width: 20%;
  padding-right: 36px;
  text-align: center;
  position: relative;
  top: ${(props) => (!props.isNegative ? '16px' : '-24px')};

  @media ${mobileCondition} {
    padding-right: 16px;
  }
`;

const Label = styled(H3)`
  color: ${accent.ocean4};

  @media ${mobileCondition} {
    font-size: ${H5Size};
    line-height: ${H5LineHeight};
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${SPACING_32};

  @media ${mobileCondition} {
    justify-content: center;
  }

  @media ${desktopCondition} {
    position: absolute;
    width: calc(100% + 48px + 32px);
    top: 50%;
    left: -51px;
  }
`;

const Arrow = styled.div`
  background: ${accent.ocean220};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: ${(props) => (props.disable ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disable ? 0.4 : 1)};

  @media ${mobileCondition} {
    width: 40px;
    height: 40px;
  }
`;

const Bar = (props) => {
  const {
    value = 0,
    lastItem,
    dataType,
    axisPosition,
    chartHeight,
    range,
  } = props;

  const coef = Math.abs(value) / range;
  const barHeight = chartHeight * coef;

  return (
    <BarWrapper>
      <BarInner
        axisPosition={axisPosition}
        maxHeight={chartHeight}
        lastItem={lastItem}
      >
        <BarArea height={barHeight} isNegative={value < 0} />
        <PortfolioReturnChartLabel
          twrColor={accent.ocean4}
          dataType={dataType}
          value={value}
          offsetY={barHeight + 10}
          isNegative={value < 0}
        />
      </BarInner>
    </BarWrapper>
  );
};

export const PortfolioReturnPerYearChart = ({
  financialValues = [],
  dataType,
}) => {
  const { ref, dimensions } = useBoundingRect();
  const data = formatReturnPerYearChartData(financialValues);
  // For data with less then 5 items we want to display the bars as if there is 5 items
  // and therefor fake the amount
  const count = Math.max(data.length, 5);

  const [pagination, setPagination] = useState(0);

  if (!data.length) return null;

  const { height } = dimensions;
  const maxY = maxBy(data, dataType)[dataType];
  const minY = minBy(data, dataType)[dataType];

  // 15% headroom for the icons
  let range = Math.abs(minY) + Math.abs(maxY);

  // range can't be zero, otherwise we'll get an "infinite" coef value
  if (range === 0) {
    range = 1;
  }

  let axisPosition = height * (Math.abs(maxY) / range);

  if (minY >= 0) {
    axisPosition = height - 1;
  }

  return (
    <>
      <ChartWrapper>
        <ChartInner ref={ref} count={count} offset={pagination}>
          <Bars>
            {data.map((item, index) => {
              return (
                <Bar
                  key={`bar-${index}`}
                  data={data}
                  index={index}
                  dataType={dataType}
                  value={item[dataType]}
                  axisPosition={axisPosition}
                  chartHeight={height}
                  range={range}
                  lastItem={index === data.length - 1}
                />
              );
            })}
          </Bars>
          <Labels style={{ top: axisPosition }} count={count}>
            {data.map(({ label, gain, twr }) => (
              <LabelWrapper
                key={`label-${label}`}
                isNegative={gain < 0 || twr < 0}
              >
                <Label>{label}</Label>
              </LabelWrapper>
            ))}
          </Labels>
        </ChartInner>
      </ChartWrapper>
      {data.length > 5 ? (
        <PaginationWrapper>
          <Arrow
            disable={pagination === data.length - 5}
            onClick={() =>
              setPagination(Math.min(pagination + 1, data.length - 5))
            }
          >
            <SpriteIconFunctional
              id="Simple arrow"
              size={14}
              direction="Left"
            />
          </Arrow>
          <Arrow
            disable={pagination === 0}
            onClick={() => setPagination(Math.max(pagination - 1, 0))}
          >
            <SpriteIconFunctional
              id="Simple arrow"
              size={14}
              direction="Right"
            />
          </Arrow>
        </PaginationWrapper>
      ) : null}
    </>
  );
};
