import jwtDecode from 'jwt-decode';

import { getAccessToken } from '../../services/tokens';

export const getIsLeadFromAccessToken = async (accessToken) => {
  try {
    const decodedToken = jwtDecode(accessToken);
    if (!decodedToken.userType) {
      return false;
    }
    return decodedToken.userType === 'lead';
  } catch (error) {
    console.log('Error retriving access token:', error);
    return false;
  }
};

export const getEmployeeClientUserFromAccessToken = async () => {
  try {
    const accessToken = await getAccessToken();
    const decodedToken = jwtDecode(accessToken);
    return decodedToken.employeeClientUser;
  } catch (error) {
    console.log("Error fetching employee's client user from token:", error);
    return null;
  }
};

export const getFlagsFromAccessToken = async (
  accessToken,
  loggedInAsEmployeeClientUser = false
) => {
  try {
    const decodedToken = jwtDecode(accessToken);

    // If impersonating return the features from employeeClientUser
    if (loggedInAsEmployeeClientUser) {
      return decodedToken.employeeClientUser.features || {};
    }

    if (decodedToken.isEmployee) {
      return {
        ...decodedToken?.employeeClientUser?.features,
        ...decodedToken?.features,
      };
    }

    if (!decodedToken.features) {
      return {};
    }
    return decodedToken.features;
  } catch (error) {
    console.log('Error retriving access token:', error);
    // We don't really care to much about this error, so just return
    // an empty object so the caller get's what it expects.
    return {};
  }
};

export const getIsEmployeeFromAccessToken = async () => {
  try {
    const accessToken = await getAccessToken();
    const decodedToken = jwtDecode(accessToken);
    return decodedToken.isEmployee;
  } catch (error) {
    console.log('Error retriving access token:', error);
    // We don't really care to much about this error, so just return
    // an empty object so the caller get's what it expects.
    return false;
  }
};
