import React from 'react';
import {
  formatGainPerPeriodChartData,
  formatGainValue,
  formatTwrValue,
  t,
} from '@formue-app/core';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { accent } from '../../../../constants/colors';
import { SPACING_64 } from '../../../../constants/spacing';
import { mobileCondition } from '../../../../constants/media';

import { H4 } from '../../../texts';
import { BarChart } from '../../../graphs/BarChart';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-self: center;
  height: 100%;
  margin-top: ${SPACING_64};

  @media ${mobileCondition} {
    width: 90%;
    margin: auto;
  }
`;

const CostumValue = (props) => {
  const { data } = props;

  return (
    <>
      <H4 style={{ color: accent.ocean3, marginBottom: 8 }}>
        {formatTwrValue(data.twr, 1, true)}
      </H4>
      <H4 style={{ color: accent.ocean2 }}>{formatGainValue(data.y)}</H4>
    </>
  );
};

export const PortfolioGainPerPeriodChart = (props) => {
  const { style } = props;
  const gainPerPeriod = useSelector(
    (state) => state.entities.portfolios.meta.gainPerPeriod
  );
  const { THISYEAR, TWELVEMONTHS, THREEYEARS, FIVEYEARS, TENYEARS } =
    gainPerPeriod;

  const colorSet = [
    accent.ocean250,
    accent.ocean3,
    '#7DBEFF',
    accent.ocean2,
    accent.ocean230,
  ];
  const data = formatGainPerPeriodChartData([
    { ...TENYEARS, label: t(`filters:period:${'TENYEARS'}`) },
    { ...FIVEYEARS, label: t(`filters:period:${'FIVEYEARS'}`) },
    { ...THREEYEARS, label: t(`filters:period:${'THREEYEARS'}`) },
    { ...TWELVEMONTHS, label: t(`filters:period:${'TWELVEMONTHS'}`) },
    { ...THISYEAR, label: t(`filters:period:${'THISYEAR'}`) },
  ]);

  return (
    <Wrapper style={style}>
      <BarChart
        data={data}
        valueKey={'twr'}
        labelKey={'label'}
        colorScheme={colorSet}
        color={accent.ocean2}
        labelColor={accent.ocean2}
        costumValueComponent={(data) => <CostumValue {...data} />}
      />
    </Wrapper>
  );
};
