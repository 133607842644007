import { useSelector } from 'react-redux';
import { orderBy, times } from 'lodash';
import moment from 'moment';
import { marketValuesSelector } from '../../store/entities/selectors/marketValues';
import { formatBigMoney } from './money';
import { accent } from '../../../../web/src/constants/colors';
import { toLocaleDateString, periodToDate } from './dates';
import { round } from '@formue-app/core';

export const formatPieChartData = (data, colors, ascending) => {
  return orderBy(data, 'amount', ascending ? 'asc' : 'desc').map(
    (entry, index) => {
      let color = colors[index];

      if (entry.hasOwnProperty('index')) {
        color = colors[entry.index];
      }

      return {
        name: entry.name,
        value: entry.amount,
        color: color,
      };
    }
  );
};

export const generateSpeedometerCustomColors = (value, color1, color2) => {
  return times(5, (index) => {
    return index === value ? color1 : color2;
  });
};

export const generateSpeedometerRiskChartColors = (value) => {
  return times(5, (index) => {
    return index === value ? '#385795' : '#CCDDEF';
  });
};

export const generateSpeedometerRiskChartColorsInvers = (value) => {
  return times(5, (index) => {
    return index === value ? '#CCDDEF' : '#385795';
  });
};

export const generateSpeedometerTrendChartColors = (value) => {
  return times(5, (index) => {
    return index === value ? '#3b6d65' : '#9ec7c7';
  });
};

export const formatGainPerPeriodChartData = (data) => {
  const result = [];

  data.forEach(({ gain, twr, label }) => {
    if (twr || gain) {
      result.push({ y: gain, twr: twr, label });
    }
  });

  return result;
};

export const formatReturnPerYearChartData = (data) => {
  if (!data) return [];
  return data.map(({ gain, twr, p }) => ({
    gain,
    twr,
    label: p.toString().substring(0, 4),
  }));
};

export const formatCashflowAxisPointsData = (data) => {
  return data.reduce(
    (accumulator, { assetClass, strategy, strategyMin, strategyMax }) => {
      return {
        ...accumulator,
        [assetClass]: [
          {
            label: `Strategi (${formatBigMoney(strategy, false)})`,
            amount: strategy,
            color: accent.velvet3,
          },
          {
            label: `Minimum (${formatBigMoney(strategyMin, false)})`,
            amount: strategyMin,
          },
          {
            label: `Maksimum (${formatBigMoney(strategyMax, false)})`,
            amount: strategyMax,
          },
        ],
      };
    },
    {}
  );
};

export const formatCashflowDetailsChartData = (data) => {
  return data
    .sort((a, b) => b.amount - a.amount)
    .map((item) => {
      return {
        label: item.name,
        value: item.amount || 0,
        color: item.assetClass === 'i' ? accent.velvet1 : accent.velvet3,
        ...item,
      };
    });
};

export const formatPerformanceChartData = (data, maxDataLength) => {
  // We want to limit the data length since charts look weird otherwise.
  let dataLength = maxDataLength > 6 ? 6 : maxDataLength;
  const sorted = data.sort((a, b) => parseInt(a.year) - parseInt(b.year));
  if (sorted.length > dataLength) sorted.splice(0, sorted.length - dataLength);
  return sorted;
};

export const usePortfolioChartData = (
  includeDateZero,
  returnJsDate = false,
  label = ''
) => {
  const data = useSelector(marketValuesSelector);
  const portfolio = useSelector((state) => state.ui.portfolio);
  const { calculatedAt } = portfolio;

  if (!data.length) return [];
  // The TWR data needs to start from the value of zero. We need to fake
  // the first value in order to achieve that.
  // This will also help for "THIS MONTH" period filter since we will have
  // an actual pair of values to represent.
  const dateString = data[0].p.toString();
  // Make a new date that is same month, but first day of the month
  const year = dateString.substring(0, 4);
  const month = parseInt(dateString.substring(4), 10) - 1;
  // Make zero day be the last day of the previous month. As the first day of returns also would be
  // the first day of month. This way we can show the first day of month as the first day of returns.
  const dateZero = new Date(year, month, 0);
  const dataLength = data.length;

  // Create a new array of marketvalues
  let marketValuesFromZero = [
    // First object should have TWR 0, but marketvalue we cannot
    // "guess", so it needs to be null
    { twr: 0, mv: null, x: dateZero },
    // Add the rest of marketvalues
    ...data.map(({ twr, mv, p }, index) => {
      const lastDayOfMonth = periodToDate(p);

      let date = moment(lastDayOfMonth);

      // We get the dates as the last days of the month.
      // For example if today is: 05052020 the input will be 31052020.
      // If that's the case, show the "calculatedAt" day instead.
      // Basically, the last date displayed
      // in the tooltip should be the last calculated value.
      if (date > moment(calculatedAt)) date = moment(calculatedAt);

      return {
        twr: twr ? twr : 0,
        mv: mv ? mv : 0,
        x: returnJsDate ? new Date(date) : date,
        label: index === dataLength - 1 ? label : null,
      };
    }),
  ];

  if (!includeDateZero) marketValuesFromZero.shift();

  return marketValuesFromZero;
};

export const usePortfolioChartDataDates = (dataExtractor) => {
  const data = usePortfolioChartData(dataExtractor === 'twr', true);

  let startDate = null;
  let endDate = null;
  if (data && data.length > 0) {
    // We want to set the start date/zero date to the first item in the data. For MV this is just
    // the first datapoint which is the last day in the month. But for TWR we add a fake point
    // representing the zero point as the last day of the previous month
    startDate = toLocaleDateString(new Date(data[0].x));
    endDate = toLocaleDateString(new Date(data[data.length - 1]?.x));
  }

  return { startDate, endDate };
};

export const formatHistoricalPerformanceChartData = (data) => {
  // The input data here is a list of index changes over time. We want to transfer
  // that to a simulated market value change. So we take a startvalue, and then
  // apply the change in percentage to that number over time.
  let totalAggregatedValue = 100;
  const aggregatedData = data.map((datum) => {
    const change = totalAggregatedValue * (1 + datum.change);
    const aggregatedDatum = {
      x: new Date(datum.changedAt),
      change,
      id: datum.id,
    };

    // Store the value for the next iteration
    totalAggregatedValue = change;

    return aggregatedDatum;
  });

  // Since we get the data in a "per each month" timeseries we want to reduce the data points.
  // Ideally we would want to be smarter about this, e.g reduce to x per year, but
  // but make sure we show the extremes (lowest and highest) values.
  return aggregatedData.filter((datum) => datum.x.getMonth() % 4 === 0);
};

export const formatHistoricalDrawdownChartData = (data) => {
  if (!data || !data.length) {
    return [];
  }

  let currentBalance = 0;
  let prevBalance = 100;

  let currentMax = 0;
  let prevMax = 100;

  // Iterate of index changes and create a new data set with only the drawdown data.
  // Drawdown measures the decline of a time series from a historical peak.
  //
  // This article explains the concept quite well:
  // https://gregorygundersen.com/blog/2021/08/27/drawdown/
  const aggregatedData = data.map((datum) => {
    // Find the current "balance", balance being the previous balance + the _change_
    // If we have 2 changes in the set, 10% and 5% we can calculate that by starting on 100% and accumulating:
    // 100 + (100*0.1) = 110
    // Next iteration would be
    // 110 + (110*0.05) = 115.5
    // and so we can continue through a large data set.
    currentBalance = prevBalance + datum.change * prevBalance;
    // But given that we want to represent drawdown we only care about the _distance_ to the previous _max_
    // value. So calculate the current max value by picking the highest option between the current balance
    // and the previous recorded max value. The one that is the highest value is the one keep.
    currentMax = Math.max(currentBalance, prevMax);

    // Now we have the current max value we can calculate the draw down/distance to that number
    // If the current balance is higher or equal to historical peak/current max we return 0
    // If not we calculate the distance to it.
    const drawDown = round(
      currentBalance >= currentMax ? 0 : (currentBalance - prevMax) / prevMax,
      4
    );
    // Create a copy of the datum we iterate over with the changedAt data
    const drawDownDatum = {
      x: new Date(datum.changedAt),
      change: drawDown,
      id: datum.id,
    };

    // Store current values as previous ones, so we can use them on the next iteration
    prevMax = currentMax;
    prevBalance = currentBalance;

    return drawDownDatum;
  });

  // The dataset is a time series of changes, but we want to start with 0 in graphs
  // so add a 0 value to the start of the array
  const firstDate = new Date(data[0].changedAt);
  let newDate = new Date(firstDate);
  newDate = new Date(newDate.setMonth(firstDate.getMonth() - 1));
  const zeroDataPoint = {
    x: newDate,
    change: 0,
  };

  return [
    // add this to make the chart start at 0
    zeroDataPoint,
    // Since we get the data in a "per each month" timeseries we want to reduce the data points
    // by half. Ideally we would want to be smarter about this, e.g reduce to 2 per year, but
    // but make sure we show the extremes (lowest and highest) values.
    ...aggregatedData.filter((datum) => datum.x.getMonth() % 6 === 0),
  ];
};

export const filterSustainabilityTimelineChartData = (data, activePeriod) => {
  // This function should "trim" the timeline data to a given period
  // Data is already sorted by date, so we basically just need to figure out
  // the "first item" in our timeline and return everything in-between.
  const lastItem = data[data.length - 1];
  let firstItem;
  switch (activePeriod) {
    case 'THISMONTH':
      firstItem = data[data.length - 2];
      break;
    case 'THISYEAR':
      // If the current month is January we want to select the December of the previous year
      // as the "first item" since we need to return at least two items in total to draw our chart.
      if (lastItem.date.getMonth() === 0) {
        firstItem = data[data.length - 2];
      } else {
        firstItem = data.find(
          (item) =>
            item.date.getFullYear() === lastItem.date.getFullYear() &&
            item.date.getMonth() === 0
        );
      }
      break;
    case 'TWELVEMONTHS':
      // Make sure we have enough data first
      if (data.length > 12) {
        firstItem = data[data.length - 13];
      } else {
        firstItem = data[0];
      }
      break;
    case 'THREEYEARS':
      // Make sure we have enough data first
      if (data.length > 36) {
        firstItem = data[data.length - 37];
      } else {
        firstItem = data[0];
      }
      break;
    default:
      firstItem = data[0];
      break;
  }

  return data.filter((item) => item.date >= firstItem.date);
};
