import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, localizeFloat, useResource } from '@formue-app/core';

import { SummaryBoxClickable } from '../../common/SummaryBox';
import {
  accent,
  backgroundWhite,
  textLightest,
} from '../../../constants/colors';
import { H3 } from '../../texts/H3';
import {
  paragraphSmallSize,
  paragraphSmallLineHeight,
} from '../../../constants/text';
import {
  SPACING_8,
  SPACING_24,
  SPACING_12,
  SPACING_16,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../constants/spacing';
import { numbersFontStack } from '../../../fonts';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { SpriteIcon } from '../../common/SpriteIcon';

import backgroundUrl from '../../../assets/portfolio/esg-summary-background.svg';
import { SustainabilityText } from '../lenses/sustainability/SustainabilityText';

const {
  entities: {
    esgData: { esgDataSelector },
  },
} = selectors;

const StyledSummaryBox = styled(SummaryBoxClickable)`
  margin-top: ${SPACING_8};
  margin-bottom: -48px;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 148px !important;
    padding-top: ${SPACING_24};
    background: #173621 url(${backgroundUrl}) no-repeat top right;
    border-bottom-left-radius: ${BORDER_RADIUS_EXTRA_LARGE};
    border-bottom-right-radius: ${BORDER_RADIUS_EXTRA_LARGE};
    padding: ${SPACING_16};
  }

  footer {
    background: transparent;
    position: relative;
    top: -48px;
    border-top: 1px solid ${accent.moss1};

    a {
      color: ${textLightest};
    }
  }
`;

const StyledEsgHeader = styled(H3)`
  color: ${textLightest};
`;

const StyledEsgNumber = styled.p`
  color: #a2c99b;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: ${SPACING_8};

  span {
    color: inherit;
  }

  .number {
    font-size: 50px;
    line-height: 50px;
    font-family: ${numbersFontStack};
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    svg {
      margin-right: 9px;
      position: relative;
      bottom: 0px;
      flex-shrink: 0;
    }
  }

  .definition {
    position: relative;
    font-size: ${paragraphSmallSize};
    line-height: ${paragraphSmallLineHeight};
    top: -4px;
    margin-left: ${SPACING_8};
    flex-basis: auto;
    flex: 1;
  }
`;

const EsgSummaryContent = (props) => {
  const { value } = props;
  const { t } = useTranslation();

  return (
    <>
      <StyledEsgHeader>{t('esg:carbonFootprint:title')}</StyledEsgHeader>
      <StyledEsgNumber>
        <span className="number">
          <SpriteIcon id="Preserve and Develop" inverted={true} size={43} />
          {value}
        </span>
        <span className="definition">{t('esg:carbonFootprint:subtitle')}</span>
      </StyledEsgNumber>
    </>
  );
};

export const EsgSummary = (props) => {
  const { columns } = props;
  const esgData = useSelector(esgDataSelector);
  const { t } = useTranslation();
  const loading = useResource(['ESG_DATA/INDEX']);

  let value = 0;

  if (esgData) {
    // return null;
    value = localizeFloat(esgData.weightedAverageCarbonIntensity, 0);
  }

  return (
    <StyledSummaryBox
      icon={<SpriteIcon id="Sustainability" size={24} />}
      iconSpacing={12}
      titleFontSize={15}
      title={t('esg:title')}
      url="/portfolio/lenses"
      label={t('esg:title')}
      linkColor={textLightest}
      columns={columns}
    >
      {loading ? (
        <CenteredActivityIndicator loadingColor={backgroundWhite} />
      ) : (
        <SustainabilityText fontSize={18} lineHeight={21} />
      )}
    </StyledSummaryBox>
  );
};
