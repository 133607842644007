import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { shuffle } from 'lodash';

import { selectors, t } from '@formue-app/core';
import { BaseSection } from '../../../common/BaseSection';
import { H2, Paragraph, ParagraphSmall } from '../../../texts';
import { accent } from '../../../../constants/colors';
import {
  SPACING_8,
  SPACING_32,
  SPACING_12,
  SPACING_48,
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
} from '../../../../constants/spacing';
import { ComparisonItem } from './ComparisonItem';
import { Button } from '../../../buttons';
import { CarbonFootprintValue } from './CarbonFootprintValue';
import { Modal } from '../../../common/Modal';
import { CarbonFootprintModalContent } from './CarbonFootprintModalContent';

const {
  entities: {
    portfolioLenses: { carbonFootprintDataSelector },
  },
} = selectors;

const StyledLabel = styled(Paragraph)`
  color: ${accent.forest2};
  padding-bottom: ${SPACING_8};
`;

const StyledBaseSection = styled(BaseSection)`
  background-color: ${accent.forest4};
  padding: ${SPACING_32};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const ComparisonsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${SPACING_12};
`;

const ComparisonsRow = styled.div`
  display: flex;
  gap: ${SPACING_12};
  flex: 1;
  align-items: center;
`;

const StyledButton = styled(Button)`
  color: ${accent.forest4};
  background-color: ${accent.forest2};
  margin-left: auto;
  margin-top: ${SPACING_32};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  &:hover {
    background-color: ${accent.forest250};
  }
`;

export const CarbonFootprint = ({ onClick, maxItems = 1, ...props }) => {
  const carbonFootprintData = useSelector(carbonFootprintDataSelector);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // We want to randomize the displayed items on each re-render
  // For that reason we use "shuffle" from lodash
  const comparisonsToShow = shuffle(
    carbonFootprintData.emissionComparison
  ).filter((item, index) => index < maxItems);

  return (
    <StyledBaseSection {...props}>
      <StyledLabel>{t('sustainability:entryTextWidget')}</StyledLabel>
      <CarbonFootprintValue
        color={accent.forest2}
        style={{ paddingBottom: SPACING_16 }}
        value={carbonFootprintData.carbonFootprint}
      />
      <ComparisonsWrapper>
        {comparisonsToShow.map((item, index) => (
          <ComparisonsRow key={`comparison-item-${index}`}>
            <ComparisonItem {...item} style={{ maxWidth: 380 }} />
            {index < comparisonsToShow.length - 1 && (
              <ParagraphSmall style={{ color: accent.forest2 }}>
                or
              </ParagraphSmall>
            )}
          </ComparisonsRow>
        ))}
      </ComparisonsWrapper>
      <StyledButton onClick={() => setModalIsOpen(true)}>
        {t('globals:readMore')}
      </StyledButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={t('sustainability:carbonFootprintScreen:title')}
        labelComponent={H2}
        padding={SPACING_48}
      >
        <CarbonFootprintModalContent
          coverage={carbonFootprintData.carbonFootprintCoverage}
        />
      </Modal>
    </StyledBaseSection>
  );
};
