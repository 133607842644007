import { ExtendedReportingDynamicPage } from '../pages/extendedReporting';

/**
 * A config for pages and the components we want to render inside each advanced
 * reporting page. We might need to manually create pages if there is going to
 * be a lot of custom UI on each page. But if we can keep with a config like
 * this, we are ready to in the future have _different_ advanced reporting
 * page configs for each user.
 *
 *  {
 *    id?: 'qapObjectId',
 *    columns: numberOfColumnsIn12ColumnGrid,
 *    heightClass?: 'small|medium|large',
 *    style?: { height: '200px' },
 *    options?: {
 *      noInteractions: true|false,
 *      noSeletions: true|false,
 *    },
 *    qvObjects?: [
 *      {
 *        id:,
 *        columns:,
 *        style?
 *      },
 *      ...
 *    ]
 *  },
 */
const pageConfig = [
  {
    id: 'holdings',
    title: 'Holdings',
    icon: 'holdings',
    qvObjects: [
      { id: 'UWEDuv', columns: 3, heightClass: 'medium' },
      { id: 'Zutyan', columns: 3, heightClass: 'medium' },
      { id: 'YShSmd', columns: 3, heightClass: 'medium' },
      { id: 'EBzbPmm', columns: 3, heightClass: 'medium' },

      { id: 'FCVaN', columns: 12, heightClass: 'large' },

      { id: 'AGsGz', columns: 4, heightClass: 'small' },
      { id: 'fwaaq', columns: 4, heightClass: 'small' },
      { id: 'ashVZXV', columns: 4, heightClass: 'small' },

      { id: 'mZAPHpH', columns: 4, heightClass: 'medium' },
      { id: 'hQBjeNp', columns: 4, heightClass: 'medium' },
      { id: 'uaGHX', columns: 4, heightClass: 'medium' },

      { id: 'uHzRnm', columns: 3, heightClass: 'large' },
      { id: 'TQsX', columns: 9, heightClass: 'large' },
    ],
  },
  {
    id: 'sustainability',
    title: 'Sustainability',
    icon: 'sustainability',
    qvObjects: [
      { id: 'sqgt', columns: 3, heightClass: 'small' },
      { id: 'muJappZ', columns: 3, heightClass: 'small' },
      { id: 'XLbnWj', columns: 3, heightClass: 'small' },
      { id: 'dXyfue', columns: 3, heightClass: 'small' },

      { id: 'cmzruV', columns: 6, heightClass: 'medium' },
      { id: 'NQjsfX', columns: 6, heightClass: 'medium' },

      { id: 'ZpPWp', columns: 3, heightClass: 'xlarge' },
      { id: 'PnCJ', columns: 9, heightClass: 'xlarge' },
    ],
  },
  {
    id: 'return',
    title: 'Returns',
    icon: 'return',
    qvObjects: [
      { id: 'NdNknTF', columns: 6, heightClass: 'medium' },
      { id: 'SuBH', columns: 6, heightClass: 'medium' },
      { id: 'tEKhyU', columns: 6, heightClass: 'small' },
      { id: 'YmLPH', columns: 6, heightClass: 'small' },

      { id: 'tDGGb', columns: 12, heightClass: 'large' },

      { id: 'mrK', columns: 12, heightClass: 'medium' },
    ],
  },
  {
    id: 'geography',
    title: 'Geography',
    icon: 'geography',
    qvObjects: [
      { id: 'KxTUNC', columns: 3, heightClass: 'small' },
      { id: 'WhEMeP', columns: 3, heightClass: 'small' },
      { id: 'pTZbxQ', columns: 3, heightClass: 'small' },
      { id: 'MfbPZ', columns: 3, heightClass: 'small' },

      { id: 'kVyJVJ', columns: 12, heightClass: 'large' },

      { id: 'ZvWjjUJ', columns: 4, heightClass: 'medium' },
      { id: 'TJbkJ', columns: 4, heightClass: 'medium' },
      { id: 'HnQPSQe', columns: 4, heightClass: 'medium' },
    ],
  },
  {
    id: 'sectors',
    title: 'Sectors',
    icon: 'sectors',
    qvObjects: [
      { id: 'XKSBAm', columns: 4, heightClass: 'small' },
      { id: 'ApdzADp', columns: 4, heightClass: 'small' },
      { id: 'pxmTPX', columns: 4, heightClass: 'small' },

      { id: 'bUMuJ', columns: 12, heightClass: 'large' },

      { id: 'hkBqQg', columns: 4, heightClass: 'medium' },
      { id: 'JjmaL', columns: 4, heightClass: 'medium' },
      { id: 'evTvL', columns: 4, heightClass: 'medium' },
    ],
  },
  {
    id: 'exposure',
    title: 'Exposure',
    icon: 'exposure',
    qvObjects: [
      { id: 'DkwvsR', columns: 3, heightClass: 'large' },
      { id: 'PuEUXka', columns: 9, heightClass: 'large' },

      { id: 'pEFJ', columns: 3, heightClass: 'small' },
      { id: 'paRpnK', columns: 3, heightClass: 'small' },
      { id: 'cexUgmR', columns: 3, heightClass: 'small' },
      { id: 'YRVzp', columns: 3, heightClass: 'small' },

      { id: 'pcPYByC', columns: 3, heightClass: 'large' },
      { id: 'MCKPPmn', columns: 9, heightClass: 'large' },
    ],
  },
  {
    id: 'strategy',
    title: 'Strategy',
    icon: 'strategy',
    qvObjects: [
      {
        id: 'fd61d239-32b1-4518-914d-52c2abb76a89',
        columns: 6,
        heightClass: 'large',
      },
      { id: 'dydYkpj', columns: 6, heightClass: 'large' },
      { id: 'CDvtY', columns: 12, heightClass: 'small' },
    ],
  },
  {
    id: 'benchmarking',
    title: 'Benchmarking',
    icon: 'benchmarking',
    qvObjects: [
      { id: 'JRQxk', columns: 9, heightClass: 'small' },
      { id: 'jkvzd', columns: 3, heightClass: 'small' },

      { id: 'MmjjdBw', columns: 9, heightClass: 'large' },
      { id: 'zGHPpA', columns: 3, heightClass: 'large' },

      { id: 'hznFZ', columns: 12, heightClass: 'medium' },
    ],
  },
];

/**
 * Maps the pageConfig above into a route config we can use in routes.js
 */
export const extendedReportingRoutes = pageConfig.map((item) => {
  return {
    path: `/extended-reporting/${item.id}`,
    component: ExtendedReportingDynamicPage,
    title: item.title,
    exact: true,
    icon: item.icon,
    disabled: item.disabled,
    qvObjects: item.qvObjects,
  };
});
