import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { generateFullName, selectors } from '@formue-app/core';

import { useRoutes } from '../../config/routes';
import { actions as navigationActions } from '../../store/ui/navigation';

import { MainMenuItem as MenuItem } from './MainMenuItem';
import { MainMenuWrapper } from './MainMenuWrapper';
import { ScreenResizeTrigger } from './ScreenResizeTrigger';
import { MainAdvisor } from './MainAdvisor';
import { MobileMenuToggle } from './MobileMenuToggle';

import {
  mobileCondition,
  notMobileCondition,
  NotMobileWrapper,
  MobileWrapper,
} from '../../constants/media';
import { SPACING_32, SPACING_24, SPACING_16 } from '../../constants/spacing';
import { accent, backgroundWhite, overlayColor } from '../../constants/colors';
import { mobileMenuMaxWidth } from '../../constants/grid';
import { mediumAnimationTime, easeInOut } from '../../constants/animation';
import { Logo } from '../common/Logo';
import { ProfileIcon } from '../common/ProfileIcon';

import { AdvisorsMainMenu } from '../advisorsCorner/AdvisorsMainMenu';

const MainMenuInnerWrapper = styled.nav`
  height: 100vh;

  @media ${mobileCondition} {
    background-color: ${accent.ocean4};
    height: 100vh;
    position: fixed;
    top: 0;
    overflow: auto;
    translate: 0%;
    transition: translate ${mediumAnimationTime} ${easeInOut};
    width: ${mobileMenuMaxWidth}px;
    max-width: 100vw;
  }

  ${(props) =>
    !props.open &&
    css`
      @media ${mobileCondition} {
        translate: -100%;
      }
    `}
`;

const Overlay = styled.div`
  ${(props) =>
    props.open &&
    css`
      @media ${mobileCondition} {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: ${overlayColor};
      }
    `}
`;

const ProfileLink = styled(Link)`
  padding: 5px;
  display: flex;
  align-items: center;
  text-decoration: none;

  @media ${notMobileCondition} {
    display: none;
  }
`;

const StyledMainMenu = styled.ul`
  list-style: none;
  padding: 0;
`;

const MainMenuHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${SPACING_24};
  margin-top: ${SPACING_32};
  margin-bottom: ${SPACING_32};

  @media ${mobileCondition} {
    margin: ${SPACING_16};
  }
`;

const StyledMainAdvisor = styled(MainAdvisor)`
  border-radius: 5px;
  margin: ${SPACING_16};
  margin-top: 85px;
`;

const LogoLinkWrapper = styled.div`
  min-height: 55px;
  display: flex;
  align-items: center;
  padding-left: 10px;

  @media ${mobileCondition} {
    padding-left: 0px;

    svg {
      fill: ${(props) => (props.inverted ? backgroundWhite : accent.ocean4)};
    }
  }
`;

const { currentUserSelector } = selectors.ui.user;

export const MainMenuList = (props) => {
  const { routes, mainMenuOpen } = props;

  return (
    <StyledMainMenu>
      {routes.map((route, index) => (
        <MenuItem
          {...props}
          title={route.title}
          to={route.path}
          icon={route.icon}
          showinmenu={route.showInMenu}
          exact={route.exact}
          routes={route.routes}
          selector={route.selector}
          key={`main-menu-item-${index}`}
          open={mainMenuOpen}
        />
      ))}
    </StyledMainMenu>
  );
};

export const MainMenu = (props) => {
  const routes = useRoutes();
  const { mainMenuOpen, subPageProps = {} } = props;
  const { inverted = false } = subPageProps;

  const dispatch = useDispatch();

  const user = useSelector(currentUserSelector);
  // If you are logged in as an advisor, but _not_ impersonating a user
  // we don't want to show the menu since that would enable advisors to
  // navigate to parts of the site that wouldn't work without the data.
  const isEmployee = useSelector((state) => state.auth.isEmployee);
  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);
  const mainMenuDisabled = isEmployee && !isImpersonating;

  let name = null;

  if (user) {
    name = generateFullName(user);
  }

  return (
    <MainMenuWrapper open={mainMenuOpen}>
      <ScreenResizeTrigger />
      <MainMenuHeaderWrapper open={mainMenuOpen}>
        <MobileMenuToggle inverted={inverted} />

        <LogoLinkWrapper inverted={inverted}>
          <Link to="/">
            <NotMobileWrapper>
              {mainMenuOpen ? (
                <Logo
                  style={{ verticalAlign: 'bottom' }}
                  monochrome={true}
                  height="24"
                />
              ) : (
                <Logo
                  style={{ verticalAlign: 'bottom' }}
                  monochrome={true}
                  size="small"
                  height="24"
                />
              )}
            </NotMobileWrapper>
            <MobileWrapper>
              <Logo
                style={{ verticalAlign: 'bottom' }}
                monochrome={inverted}
                height="24"
              />
            </MobileWrapper>
          </Link>
        </LogoLinkWrapper>

        {name && (
          <MobileWrapper>
            <ProfileLink to="/profile">
              <ProfileIcon name={name} size={32} />
            </ProfileLink>
          </MobileWrapper>
        )}
      </MainMenuHeaderWrapper>

      <Overlay
        open={mainMenuOpen}
        onClick={() => dispatch(navigationActions.toggleMainMenu())}
      />
      <MainMenuInnerWrapper open={mainMenuOpen}>
        {!mainMenuDisabled ? (
          <>
            <MainMenuHeaderWrapper open={mainMenuOpen}>
              <MobileMenuToggle />
            </MainMenuHeaderWrapper>

            <MainMenuList
              routes={routes}
              mainMenuOpen={mainMenuOpen}
              {...props}
            />
          </>
        ) : null}

        {!isEmployee ? <StyledMainAdvisor /> : null}

        {isEmployee && isImpersonating ? (
          <AdvisorsMainMenu open={mainMenuOpen} />
        ) : null}
      </MainMenuInnerWrapper>
    </MainMenuWrapper>
  );
};
