import { takeLatest, call, put } from 'redux-saga/effects';

import { qlikSessions } from '../../../api';
import { actions } from './actions';

const QLIK_COOKIE_NAME = 'X-Qlik-Session-Id';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const deleteCookie = (name) => {
  // TODO:qlik - Make cookie work on other domains as well
  document.cookie = `${name}=; Domain=.formue.no; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

function* createSession() {
  yield takeLatest(actions.createSession, function* () {
    const result = yield call(qlikSessions.post);
    const { sessionId } = result.body;
    yield put(actions.setSessionId(sessionId));
  });
}

function* hydrateSession() {
  yield takeLatest('APP/INIT', function* () {
    try {
      const sessionId = getCookie(QLIK_COOKIE_NAME);
      yield put(actions.setSessionId(sessionId));
    } catch (_) {
      // Ignore this, won't work in the mobile app, and if it for some
      // reason fails in the browser it doesn't do any harm, we just end
      // up creating another session.
    }
  });
}

/**
 * Make sure we clear out the session cookie when the app flush event
 * is called. This makes sure we don't persist cookies between sessions
 * in our system. We also want to reset the sessionId stored in the store
 * to make sure that we create a new session the next time we open the
 * extended reporting.
 */
function* removeSessionCookieOnFlush() {
  yield takeLatest('APP/FLUSH', function* () {
    yield put(actions.setSessionId(null));
    try {
      deleteCookie(QLIK_COOKIE_NAME);
    } catch (_) {
      // Ignore this, won't work in the mobile app
    }
  });
}

export const sagas = [
  createSession,
  hydrateSession,
  removeSessionCookieOnFlush,
];
