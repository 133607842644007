import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, t, useResource } from '@formue-app/core';

import { backgroundMain } from '../../constants/colors';
import { SPACING_32, SPACING_48 } from '../../constants/spacing';

import { Modal } from '../../components/common/Modal';
import { GridElement } from '../../components/layout/GridElement';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { H2 } from '../../components/texts';
import { CarbonFootprint } from '../../components/portfolio/lenses/sustainability/CarbonFootprint';
import { CompanyPromise } from '../../components/portfolio/lenses/sustainability/CompanyPromise';
import { EsgNewsfeed } from '../../components/portfolio/lenses/esg/EsgNewsfeed';
import { EsgFooterSection } from '../../components/portfolio/lenses/esg/EsgFooterSection';
import { EsgScoreReadMore } from '../../components/portfolio/lenses/esg/modals/EsgScoreReadMore';
import { EsgCoverageReadMore } from '../../components/portfolio/lenses/EsgCoverageReadMore';
import { CarbonFootprintReadMore } from '../../components/portfolio/lenses/esg/modals/CarbonFootprintReadMore';

import { TemperatureRise } from '../../components/portfolio/lenses/sustainability/TemperatureRise';
import { CarbonFootprintChart } from '../../components/portfolio/lenses/sustainability/CarbonFootprintChart';
import { ScienceBasedTargets } from '../../components/portfolio/lenses/sustainability/ScienceBasedTargets';
import { SocialResponsibility } from '../../components/portfolio/lenses/sustainability/SocialResponsibility';
import { FundClassification } from '../../components/portfolio/lenses/sustainability/FundClassification';

const {
  entities: {
    portfolioLenses: { allPortfolioLensesSelector },
  },
} = selectors;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

export const SustainabilityPage = () => {
  const loading = useResource(['ESG_DATA/INDEX', 'PORTFOLIO_LENSES/INDEX']);
  const portfolioLenses = useSelector(allPortfolioLensesSelector);
  const [modal, setModal] = useState('');

  if (loading) {
    return (
      <GridElement columns={12}>
        <StyledCenteredActivityIndicator
          columns={12}
          size={30}
          background={backgroundMain}
        />
      </GridElement>
    );
  }

  if (!portfolioLenses.length) return null;

  return (
    <>
      <CarbonFootprint columns={6} />
      <CarbonFootprintChart columns={6} />
      <TemperatureRise columns={3} temperature={2.3} />
      <ScienceBasedTargets columns={3} />
      <SocialResponsibility columns={3} />
      <FundClassification columns={3} />
      <CompanyPromise columns={12} style={{ marginTop: SPACING_32 }} />
      <EsgNewsfeed columns={12} />
      <EsgFooterSection
        onClick={() => {
          setModal('coverage');
        }}
      />
      <Modal
        isOpen={modal.length ? true : false}
        onRequestClose={() => setModal('')}
        padding={SPACING_48}
        labelComponent={H2}
      >
        {modal === 'esgScore' && <EsgScoreReadMore />}
        {modal === 'coverage' && <EsgCoverageReadMore />}
        {modal === 'carbonFootprint' && <CarbonFootprintReadMore />}
      </Modal>
    </>
  );
};
