import React from 'react';
import styled from 'styled-components';

import {
  H3Size,
  H3LineHeight,
  H3SizeMobile,
  H3LineHeightMobile,
} from '../../constants/text';
import { notDesktopCondition } from '../../constants/media';

export const StyledH3 = styled.h3`
  font-size: ${H3Size};
  line-height: ${H3LineHeight};
  font-weight: 500;

  @media ${notDesktopCondition} {
    font-size: ${H3SizeMobile};
    line-height: ${H3LineHeightMobile};
  }
`;

export const H3 = (props) => {
  const { children } = props;

  return <StyledH3 {...props}>{children}</StyledH3>;
};
