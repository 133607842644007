import React from 'react';
import {
  getAssetClassName,
  getColorForAssetClass,
  percentage,
  sortByAssetClass,
} from '@formue-app/core';

import styled, { css } from 'styled-components';
import { H4, Paragraph } from '../../texts';
import {
  accent,
  assetClassDefaultColorScheme,
} from '../../../constants/colors';
import {
  easeInOut,
  fadeInFromBottom,
  mediumAnimationTime,
} from '../../../constants/animation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
`;

const Item = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 6px 0;
  padding: 6px 0;

  &:nth-child(3),
  &:nth-child(5) {
    border-bottom: 1px solid ${accent.sand2};
    padding-bottom: 18px;
    margin-bottom: 12px;
  }

  opacity: 0;
  transform: translateY(60px);

  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeInFromBottom} ${mediumAnimationTime} ${easeInOut}
        forwards;
      animation-delay: ${(props) => props.delay}s;
    `}
`;

const Icon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(H4)``;

const Value = styled(Paragraph)`
  font-weight: 500;
`;

export const AssetClassesList = (props) => {
  const { assetAllocations, animate, index, ...rest } = props;

  if (!assetAllocations) return null;

  return (
    <Container {...rest}>
      {assetAllocations
        .sort(sortByAssetClass)
        .reverse()
        .map((item, index) => (
          <Item
            key={`asset-class-item-${index}`}
            animate={animate}
            delay={1.3 + index * 0.1}
          >
            <IconWrapper>
              <Icon
                style={{
                  backgroundColor: getColorForAssetClass(
                    item.assetClass,
                    assetClassDefaultColorScheme
                  ),
                }}
              />
              <Title>{getAssetClassName(item.assetClass)}</Title>
            </IconWrapper>
            <Value>{percentage(item.strategyShare * 100)}</Value>
          </Item>
        ))}
    </Container>
  );
};
