import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { BORDER_RADIUS_SMALL, SPACING_24 } from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { H6, ParagraphSmall } from '../../texts';

import limitBorderUrl from '../../../assets/icons/portfolio/strategy-limit-border.svg';
import limitBorderLightUrl from '../../../assets/icons/portfolio/strategy-limit-border-light.svg';

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: ${SPACING_24};
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Icon = styled.div`
  width: 10px;
  height: 10px;
  background: ${accent.velvet3};
  border-radius: ${BORDER_RADIUS_SMALL};
`;

export const CurrentDistributionLabel = (props) => {
  const { textColor, value } = props;

  return (
    <Label>
      <Icon />
      <H6 style={{ color: textColor }}>
        {t('portfolio:portfolioStrategy:currentDistribution')}
      </H6>
      {value ? (
        <ParagraphSmall style={{ marginLeft: 'auto' }}>{value}</ParagraphSmall>
      ) : null}
    </Label>
  );
};

export const StrategyLabel = (props) => {
  const { textColor, light, value } = props;

  return (
    <Label>
      <Icon
        style={{
          borderRadius: 2,
          width: 2,
          height: 12,
          backgroundColor: light ? accent.velvet250 : 'black',
        }}
      />
      <H6 style={{ color: textColor }}>
        {t('portfolio:portfolioStrategy:strategy')}
      </H6>
      {value ? (
        <ParagraphSmall style={{ marginLeft: 'auto' }}>{value}</ParagraphSmall>
      ) : null}
    </Label>
  );
};

export const BoundariesLabel = (props) => {
  const { textColor, light, value } = props;

  return (
    <Label>
      <Icon
        style={{
          borderRadius: 2,
          width: 12,
          height: 12,
          background: 'none',
          borderWidth: 4,
          borderStyle: 'solid',
          borderImageSource: `url(${
            light ? limitBorderLightUrl : limitBorderUrl
          })`,
          borderImageSlice: 8,
          borderImageWidth: '8px',
          borderImageRepeat: 'round',
        }}
      />
      <H6 style={{ color: textColor }}>
        {t('portfolio:portfolioStrategy:boundaries')}
      </H6>
      {value ? (
        <ParagraphSmall style={{ marginLeft: 'auto' }}>{value}</ParagraphSmall>
      ) : null}
    </Label>
  );
};

export const PortfolioStrategyLabels = (props) => {
  const { textColor, light, ...rest } = props;

  return (
    <Labels {...rest}>
      <CurrentDistributionLabel {...props} />
      <StrategyLabel {...props} />
      <BoundariesLabel {...props} />
    </Labels>
  );
};
