import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
  contentPadding,
  contentColumnGap,
  contentRowGap,
  contentColumnCount,
  contentMaxWitdh,
  contentRowGapMobile,
  contentColumnGapMobile,
  contentPaddingMobile,
} from '../../constants/grid';
import { mobileCondition, printCondition } from '../../constants/media';

export const StyledMainGrid = styled.main`
  display: grid;
  flex: 1;
  grid-gap: ${contentRowGap} ${contentColumnGap};
  grid-template-columns: repeat(${contentColumnCount}, 1fr);
  grid-template-rows: auto;
  height: fit-content;
  padding: 0 ${contentPadding};
  max-width: ${contentMaxWitdh}px;
  margin: 0 auto;
  ${(props) => props.gridTemplate}

  @media ${mobileCondition} {
    grid-gap: ${contentRowGapMobile} ${contentColumnGapMobile};
    padding: 0 ${contentPaddingMobile};
  }

  @media ${printCondition} {
    display: block;
  }
`;

export const MainGrid = forwardRef((props, ref) => {
  const { children } = props;

  return <StyledMainGrid ref={ref} {...props}>{children}</StyledMainGrid>;
});
