import React from 'react';
import styled from 'styled-components';

import {
  getAssetClassName,
  percentage,
  getColorForAssetClass,
  t,
  formatMoney,
} from '@formue-app/core';

import { DotIcon } from '../../common/DotIcon';
import { BaseSection } from '../../common/BaseSection';
import { H3, H4, H6, Paragraph, ParagraphSmall } from '../../texts';

import { Input } from '../../formElements';
import { GridElement, MainGrid } from '../../layout';
import {
  accent,
  assetClassDefaultColorScheme,
  blackTranslucent,
} from '../../../constants/colors';
import {
  SPACING_8,
  SPACING_16,
  BORDER_RADIUS_LARGE,
  BORDER_RADIUS_SMALL,
} from '../../../constants/spacing';
import { paragraphSize } from '../../../constants/text';
import { bodyFontStack } from '../../../fonts';
import { HorizontalDivider } from '../../common/HorizontalDivider';

const StyledMainGrid = styled(MainGrid)`
  padding: 0;
`;

const StyledGridElement = styled(GridElement)`
  display: flex;
  gap: ${SPACING_16};
  flex-direction: column;
`;

const Values = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${SPACING_8};
  margin-left: auto;
`;

const Line = styled.div`
  width: 1px;
  height: calc(100% - 6px);
  background-color: ${accent.sand2};
`;

const Field = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_16};
`;

const ExternalField = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_16};
`;

const ExternalLabel = styled(H6)`
  background: ${accent.ocean230};
  padding: 4px;
  border-radius: ${BORDER_RADIUS_SMALL};
`;

const StyledInput = styled(Input)`
  border: 1px solid ${accent.ocean220};
  width: 100%;
`;

const PercentWrapper = styled.div`
  position: relative;

  &:after {
    display: block;
    content: '%';
    position: absolute;
    top: 11px;
    right: 10px;
    font-family: ${bodyFontStack};
    font-size: ${paragraphSize};
  }
`;

const PercentInput = styled(StyledInput)`
  min-width: 65px;
  width: 65px;
  text-align: right;
  padding-right: 25px;
`;

const AddProduct = styled.div`
  padding: 8px 10px;
  width: 100%;
  cursor: pointer;
  border-radius: ${BORDER_RADIUS_LARGE};

  &:hover {
    background-color: ${blackTranslucent(0.05)};
  }
`;

const ProductItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_16};
  padding-top: ${SPACING_16};
  border-top: 1px solid red;
  border-color: ${accent.ocean220};
`;

export const ProductSectionLight = (props) => {
  const {
    products,
    item: { assetClass },
    strategy,
  } = props;

  const formuePortfolio = strategy
    ? (strategy.totalPortfolio - strategy.externalPortfolio)
    : null;

  return (
    <div>
      <StyledMainGrid>
        <StyledGridElement columns={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: SPACING_8,
            }}
          >
            <DotIcon
              color={getColorForAssetClass(
                assetClass,
                assetClassDefaultColorScheme
              )}
            />
            <H4>{getAssetClassName(assetClass)}</H4>
          </div>
        </StyledGridElement>

        {/* <StyledGridElement columns={6}>
          {products
            .filter((item) => item.type !== 'external')
            .map((product) => (
              <Paragraph>{product.name}</Paragraph>
            ))}
        </StyledGridElement> */}

        <StyledGridElement columns={12}>
          {products
            .filter((item) => item.type !== 'external')
            .map((product, index) => (
              <ProductItem key={`product-${product.id}-${index}`}>
                <Paragraph>{product?.name}</Paragraph>
                <Values style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                  <H4>{percentage(product?.strategyShare * 100)}</H4>
                  <Paragraph style={{ whiteSpace: 'nowrap' }}>{formatMoney(formuePortfolio * product.strategyShare)}</Paragraph>
                </Values>
              </ProductItem>
            ))}
        </StyledGridElement>
      </StyledMainGrid>
    </div>
  );
};

export const ProductSection = (props) => {
  const {
    products,
    item: { assetClass, strategyShare },
    strategy,
    ...rest
  } = props;

  const filteredProducts = products.filter((item) => item.type !== 'external');

  const formuePortfolio = strategy
    ? strategy.totalPortfolio - strategy.externalPortfolio
    : null;

  return (
    <BaseSection columns={12} {...rest}>
      <StyledMainGrid>
        <StyledGridElement columns={5}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: SPACING_8,
            }}
          >
            <DotIcon
              color={getColorForAssetClass(
                assetClass,
                assetClassDefaultColorScheme
              )}
            />
            <H3>{getAssetClassName(assetClass)}</H3>
          </div>
          <ParagraphSmall style={{ fontWeight: 500, marginTop: 'auto' }}>
            {t('advisor:strategySimulator:product:strategy')}:{' '}
            {percentage(strategyShare * 100)}
          </ParagraphSmall>
        </StyledGridElement>

        <StyledGridElement columns={3}>
          {filteredProducts.map((product, index) => (
            <Paragraph key={`product-${product.id}-${index}`}>{product.name}</Paragraph>
          ))}
        </StyledGridElement>

        <StyledGridElement columns={4}>
          {filteredProducts.map((product, index) => (
            <Values key={`product-${product.id}-${index}`}>
              {product && formuePortfolio ? (
                <>
                  <Paragraph>
                    {formatMoney(formuePortfolio * product.strategyShare)}
                  </Paragraph>
                  <Line />
                </>
              ) : null}
              <Paragraph>{percentage(product?.strategyShare * 100)}</Paragraph>
            </Values>
          ))}
        </StyledGridElement>
      </StyledMainGrid>
    </BaseSection>
  );
};

export const ProductEditSection = (props) => {
  const {
    products,
    item: { assetClass, strategyShare },
    editableProducts,
    setEditableProducts,
    ...rest
  } = props;

  const filteredProducts = products.filter((item) => item.type !== 'external');
  const externalProducts = products.filter((item) => item.type === 'external');

  return (
    <BaseSection columns={12} {...rest}>
      <StyledMainGrid>
        <StyledGridElement columns={7} style={{ gap: SPACING_8 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: SPACING_8,
            }}
          >
            <DotIcon
              color={getColorForAssetClass(
                assetClass,
                assetClassDefaultColorScheme
              )}
            />
            <H3>{getAssetClassName(assetClass)}</H3>
          </div>
          <div style={{ marginTop: 'auto' }}>
            <ParagraphSmall style={{ fontWeight: 500 }}>
              {t('advisor:strategySimulator:product:strategy')}:{' '}
              {percentage(strategyShare * 100)}
            </ParagraphSmall>
            <ParagraphSmall
              style={{
                color: accent.ocean470,
                fontWeight: 500,
              }}
            >
              {t('advisor:strategySimulator:product:currentValue')}:{' '}
              {percentage(
                products
                  .map((item) => item.strategyShare)
                  .reduce((total, acc) => acc + total, 0) * 100
              )}
            </ParagraphSmall>
          </div>
        </StyledGridElement>

        <StyledGridElement columns={5} style={{ gap: SPACING_8 }}>
          {filteredProducts.map((product, index) => (
            <Field key={`products-${index}`}>
              <StyledInput value={product.name} />
              <PercentWrapper>
                <PercentInput
                  value={product.strategyShare * 100}
                  defaultValue="0"
                />
              </PercentWrapper>
            </Field>
          ))}

          <Field>
            <AddProduct>
              <ParagraphSmall style={{ fontWeight: 500 }}>
                {t('advisor:strategySimulator:product:addProduct')}
              </ParagraphSmall>
            </AddProduct>
          </Field>
          {externalProducts.length ? <HorizontalDivider /> : null}
          {externalProducts.map((product) => (
            <ExternalField style={{ marginTop: SPACING_8 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: SPACING_16,
                }}
              >
                <Paragraph>{product.title}</Paragraph>
                <ExternalLabel>External</ExternalLabel>
              </div>
              <Paragraph style={{ marginLeft: 'auto' }}>
                {percentage(product.strategyShare * 100)}
              </Paragraph>
            </ExternalField>
          ))}
        </StyledGridElement>
      </StyledMainGrid>
    </BaseSection>
  );
};
