import React, { useState } from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { GridElement } from '../../../../components/layout';
import { CollapsibleListItem } from '../../../../components/common/CollapsibleListItem';
import { H2, H3, H4, Paragraph } from '../../../../components/texts';
import { TableList } from '../../../../components/lists';
import { List, ListItem } from '../../../../components/common/List';

import { Toggle } from '../../../../components/formElements';
import { desktopCondition, tabletCondition } from '../../../../constants/media';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { InfoCard } from '../../../../components/common/InfoCard';
import { OrderedList } from '../../../../components/texts/OrderedList';

const StyledInfoCard = styled(InfoCard)`
  margin-top: 10px;

  @media ${desktopCondition} {
    margin-right: 40px;
  }

  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const StyledIntroSection = styled(GridElement)`
  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  max-width: 75%;
  padding: ${SPACING_24};
  padding-block: 0;
`;

const StyledList = styled(List)`
  margin: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 55px;
  margin-bottom: ${SPACING_16};
`;

export const GeneralPage = (props) => {
  const year = 2023;
  const [openAll, setOpenAll] = useState(false);

  const faqData = [
    {
      title: 'Tidspunkter for publisering av rapporter og oppgaver',
      details: (
        <TableList
          sortable={false}
          columns={[
            {
              key: 'title',
              label: 'Rapport/Publiseringstidspunkt',
              render: ({ title, publishDate }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <H4>{title}</H4>
                  <Paragraph>{publishDate}</Paragraph>
                </div>
              ),
            },
            {
              key: 'type',
              label: 'Type',
            },
            { key: 'source', label: 'Kilde/sted' },
          ]}
          rows={[
            {
              title: 'Innskudd, utlån og rente m.v. (Formueskonto)',
              publishDate: 'Slutten av Januar',
              type: 'Storebrand Nettbank',
              source: 'Storebrand Nettbank',
            },
            {
              title: 'Innskudd, utlån og rente m.v. 2023 (Kundekonto)',
              publishDate: 'Slutten av Januar',
              type: 'Formue',
              source: 'Kundenett > Dokumenter',
            },
            {
              title: 'Deltakeroppgaver fra deltakerliknende selskaper (IS, DA)',
              publishDate: 'Januar - mai',
              type: 'Formue Forretningsførsel',
              source: 'Kundenett > Dokumenter',
            },
            {
              title:
                'VPS-årsoppgave for VPS-registrerte verdipapirfond, AS og obligasjoner',
              publishDate: 'Februar',
              type: 'Verdipapirsentralen (VPS)',
              source: 'VPS',
            },
            {
              title:
                'Deltakeroppgaver fra deltakerliknende selskaper (IS, DA, KS)',
              publishDate: 'Februar - mai',
              type: 'Eksterne forretningsførere',
              source: 'Eksterne forretningsførere',
            },
            {
              title: 'Skatterapport Nominee 2023 (Årsoppgave)',
              publishDate: 'Ca midten av februar',
              type: 'Formue',
              source: 'Kundenett > Dokumenter',
            },
            {
              title:
                'Fradragsberettigede honorarer og skattepliktige inntekter',
              publishDate: 'Ca midten av mars',
              type: 'Formue',
              source: 'Kundenett > Skatt',
            },
          ]}
          keyExtractor={(item, index) => `faq-${index}`}
        />
      ),
    },
    {
      title: 'Hva trenger jeg å fylle ut selv i skattemeldingen?',
      details: (
        <ContentWrapper>
          <Paragraph style={{ fontWeight: 500 }}>
            Fradragsberettigede honorarer og skattepliktige inntekter
          </Paragraph>
          <Paragraph>
            I år som i fjor, finner du informasjon om fradragsberettigede
            honorarer digitalt her. Rapporten kan skrives ut og inneholder
            bekreftede fradragsberettigede honorarer og skattepliktige inntekter
            med informasjon om hvor i skattemeldingen du skal føre disse. Kommer
            i mars
          </Paragraph>
          <Paragraph style={{ fontWeight: 500 }}>
            Deltakere i Ansvarlig selskap (ANS), selskap med Delt Ansvar (DA),
            Indre Selskap (IS) eller Kommandittselskap (KS)
          </Paragraph>
          <Paragraph>
            Deltakere i Ansvarlig selskap (ANS), selskap med Delt Ansvar (DA),
            Indre Selskap (IS) og/eller Kommandittselskap (KS)) må benytte
            skattemelding for næringsdrivende, selv om man har mottatt
            skattemelding for lønnstakere. Deltakeroppgaver publiseres mellom
            januar og mai.
          </Paragraph>
          <Paragraph>
            Fremgangsmåte for levering via skatteetaten.no (Min skatt)
          </Paragraph>
          <OrderedList style={{ margin: 0, paddingLeft: SPACING_16 }}>
            <li>
              Velg «Ønsker du å oppgi noen andre opplysninger?» nederst i
              skattemeldingen
            </li>
            <li>Velg «Formue og inntekt i selskap med deltakerfastsetting»</li>
            <li>
              Legg inn disse opplysningene fra den mottatte deltakeroppgaven:
              <ul>
                <li>Navn på selskapet</li>
                <li>Organisasjonsnummer på selskapet</li>
                <li>Andel netto formue/andel negativ netto formue</li>
                <li>Ev. sum alminnelig inntekt/negativ alminnelig inntekt</li>
                <li>Ev. tillegg i alminnelig inntekt</li>
                <li>Ev. arbeidsgodtgjørelse</li>
                <li>Ev. gevinst/tap ved realisasjon av andel</li>
              </ul>
            </li>
            <li>Trykk deretter Ok</li>
          </OrderedList>
          <Paragraph style={{ fontWeight: 500 }}>
            Skattepliktig formue og fradragsberettiget gjeld
          </Paragraph>
          <Paragraph>
            Hvis du har private fordringer eller lån, f.eks. mellomværende med
            eget aksjeselskap, må du føre opp beløpet som skattepliktig formue
            eller fradragsberettiget gjeld i skattemeldingen din.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Hva er allerede fylt ut for meg i skattemeldingen?',
      details: (
        <ContentWrapper>
          <Paragraph>
            I skattemeldingen for 2023 er det viktig at du kontrollerer:
          </Paragraph>
          <StyledList>
            <ListItem>Alle forhåndsutfylte opplysninger </ListItem>
            <ListItem>Om noen opplysninger mangler</ListItem>
          </StyledList>
          <div>
            <H3>Formue</H3>
            <Paragraph>
              Vi rapporterer følgende opplysninger til skattemyndighetene:
            </Paragraph>
          </div>
          <StyledList>
            <ListItem>
              Aksjesparekonto, Verdipapirfond og Notes hos Formue finner du i
              dokumentet <b>Skatterapport Nominee 2023</b> i dokumentarkivet
            </ListItem>
            <ListItem>
              Oversikt over bankinnskudd og renter hos Formue finner du i
              dokumentet <b>Innskudd, utlån og rente m.v. 2023</b> i
              dokumentarkivet
            </ListItem>
          </StyledList>
          <div>
            <H3>Formueskonto </H3>
            <Paragraph>
              Formueskontoen rapporteres av Storebrand. Saldo- og renteoppgave
              finner du ved å logge deg inn på 
              <a
                href="https://nettbank.storebrand.no"
                target="_blank"
                rel="noreferrer"
              >
                https://nettbank.storebrand.no
              </a>
              .
            </Paragraph>
          </div>
          <div>
            <H3>VPS-konto</H3>
            <Paragraph>
              Hvis du har verdipapirkonto hos VPS finner du årsoppgave for
              VPS-kontoen ved å logge på VPS Investortjenester via 
              <a href="https://www.vps.no/" target="_blank" rel="noreferrer">
                https://www.vps.no
              </a>
              .
            </Paragraph>
          </div>
          <Paragraph>
            Pålogging kan skje med BankID/BankID på mobil, alternativt med
            brukernavn/passord fra VPS. Dersom du har glemt ditt
            påloggingspassord kan dette bestilles på samme side. Vi gjør
            oppmerksom på at det kun er privatpersoner som kan bestille nytt
            passord elektronisk og logge på med BankID/BankID på mobil. For
            bedriftskunder må tilsendt passord benyttes sammen med org.nr.
          </Paragraph>
          <Paragraph>
            For bestilling av nytt passord, send e-post til 
            <a href="mailto:kundenett@formue.no">kundenett@formue.no</a>.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Tilgang til kundenett for regnskapsførere eller andre',
      details: (
        <ContentWrapper>
          <Paragraph>
            Dersom du ønsker å gi tilgang til familiemedlemmer, regnskapsfører,
            revisor, ansatte eller styremedlemmer i selskap kan du kontakte din
            rådgiver eller sende oss en e-post{' '}
            <a href="mailto:kundeservice@formue.no">kundeservice@formue.no</a>.
          </Paragraph>
          <Paragraph>
            Vi må få oppgitt navn, fødsels- og personnummer, telefonnummer og
            adresse på den som skal ha lesetilgang. Vi vil deretter legge ut et
            skjema på kundenett/app som signaturberettiget må signere.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title:
        'Hvor finner jeg informasjon om den skattemessige behandlingen av de ulike verdipapirene mine hos Formue?',
      details: (
        <ContentWrapper>
          <Paragraph>
            Under finner du informasjon om den skattemessige behandlingen av de
            ulike verdipapirene dine hos Formue.
          </Paragraph>
          <Paragraph>
            <a
              href="https://formue.my.salesforce.com/sfc/p/0Y000000pG6P/a/TY000000Z6Tx/oJJq2oBMLd60wUs9hquGW5XaOcXAFimaZnW16XGAGm0"
              target="_blank"
              rel="noreferrer"
            >
              Skattemessige behandlingen
            </a>
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Hvor finner jeg  formuesverdier  på aksjer for 2023?',
      details: (
        <ContentWrapper>
          <Paragraph>
            Alle aksjeselskap tilrettelagt av Formue AS med forretningsførsel
            hos Formue Accounting AS rapporteres gjennom VPS og godkjente
            formuesverdier finnes i Årsoppgaven fra VPS for 2023. Dette må
            sammenholdes med opplysninger i aksjeoppgaven (RF-1088).
          </Paragraph>
          <Paragraph>
            Aksjeselskap og andre juridiske innretninger (ikke privatpersoner)
            som skal benytte verdi 31.12.2023, kan henvende seg til{' '}
            <a href="mailto:accounting@formue.no">accounting@formue.no</a> og få
            tilsendt verdien direkte.
          </Paragraph>
        </ContentWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledInfoCard
        title="Fradragsberettigede honorarer og skattepliktige inntekter 2023"
        body="For å se dine fradragsberettigede honorarer og skattepliktige inntekter har vi laget en oversikt over dette på linken nedenfor."
        navLabel="Se oversikt"
        disabled={false}
        to={`/portfolio/tax/declaration/${year}`}
      />
      <StyledIntroSection columns={5} style={{ marginTop: SPACING_8 }}>
        <H3 style={{ marginBottom: SPACING_8 }}>Viktig for privatpersoner</H3>
        <Paragraph style={{ marginBottom: 35 }}>
          Vi minner om at lønnsmottakere og pensjonister mottar sin
          skattemelding i Min skatt, hvor opplysningene er inndelt etter temaer
          (ikke poster som tidligere).
        </Paragraph>
      </StyledIntroSection>
      <GridElement columns={12}>
        <TitleWrapper>
          <H2>Ofte stilte spørsmål</H2>
          <Toggle
            label="Se alle"
            value={openAll}
            onChange={() => setOpenAll(!openAll)}
          />
        </TitleWrapper>
        {faqData.map((q, index) => (
          <CollapsibleListItem
            details={q.details}
            detailsStyle={q.detailsStyle}
            startExpanded={openAll}
            style={{ marginTop: SPACING_8, padding: SPACING_24 }}
            key={`faq-${camelcase(q.title)}-${index}`}
          >
            <H3 style={{ marginRight: 'auto' }}>{q.title}</H3>
          </CollapsibleListItem>
        ))}
      </GridElement>
    </>
  );
};
