import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';
import { BaseSectionWithTitle } from '../../../common/BaseSectionWithTitle';
import { H4, Paragraph, ParagraphSmall } from '../../../texts';
import { HorizontalBarChart } from '../../../graphs/HorizontalBarChart';
import { accent, textLink } from '../../../../constants/colors';
import { SPACING_48, SPACING_24 } from '../../../../constants/spacing';
import { HorizontalDivider } from '../../../common/HorizontalDivider';
import { SpriteIcon } from '../../../common/SpriteIcon';

const { operatingCountrySelector } = selectors.auth;
const {
  entities: {
    esgData: { esgDataSelector, esgIndexSelector },
  },
} = selectors;

const StyledH4 = styled(H4)`
  color: ${textLink};
`;

const StyledLink = styled.button`
  text-decoration: none;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 12px 0 0 0;
`;

export const CarbonFootprint = ({ onClick, ...props }) => {
  const esgData = useSelector(esgDataSelector);
  const esgIndex = useSelector(esgIndexSelector);
  const operatingCountry = useSelector(operatingCountrySelector);

  if (!esgData || !esgIndex) return null;

  const local =
    esgData.carbonIntensity > esgIndex.local?.weightedAverageCarbonIntensity
      ? 'more'
      : 'less';

  const global =
    esgData.carbonIntensity > esgIndex.world?.weightedAverageCarbonIntensity
      ? 'more'
      : 'less';

  const title = `${t('esg:carbonFootprint:moreLess1')} ${t(
    `globals:${local}`
  )} ${t('esg:carbonFootprint:moreLess2')} ${t(`globals:${global}`)} ${t(
    'esg:carbonFootprint:moreLess3'
  )}`;

  return (
    <BaseSectionWithTitle
      label={t('esg:carbonFootprint:title')}
      title={title}
      subtitle={t('esg:benchmark:subtitle', {
        local:
          esgData.qualityScore > esgIndex.local?.esgQualityScore
            ? t('globals:higher')
            : t('globals:lower'),
        world:
          esgData.qualityScore > esgIndex.world?.esgQualityScore
            ? t('globals:higher')
            : t('globals:lower'),
      })}
      icon={<SpriteIcon id="Barchart" />}
      {...props}
    >
      <HorizontalBarChart
        animate={true}
        data={[
          {
            label: t('esg:localization:your'),
            value: Math.round(esgData.weightedAverageCarbonIntensity * 10) / 10,
            color: accent.moss3,
            barType: 'rounded',
            delay: 0,
          },
          {
            label: t('esg:localization:country', {
              context: operatingCountry,
            }),
            value:
              Math.round(esgIndex.local?.weightedAverageCarbonIntensity * 10) /
              10,
            color: accent.moss1,
            barType: 'triangle',
            delay: 100,
          },
          {
            label: t('esg:localization:world'),
            value:
              Math.round(esgIndex.world?.weightedAverageCarbonIntensity * 10) /
              10,
            color: accent.moss2,
            barType: 'triangleInverted',
            delay: 200,
          },
        ]}
        style={{ paddingTop: SPACING_24, paddingBottom: SPACING_48 }}
        angle={15}
      />

      <ParagraphSmall
        style={{ whiteSpace: 'pre-wrap', fontStyle: 'italic', fontWeight: 200 }}
      >
        {t('esg:localization:index', {
          context: operatingCountry,
        })}
      </ParagraphSmall>

      <HorizontalDivider style={{ marginTop: 40, marginBottom: 40 }} />

      <Paragraph>{t('esg:carbonFootprint:info')}</Paragraph>

      <StyledLink
        onClick={() => {
          onClick();
        }}
      >
        <StyledH4>{t('esg:carbonFootprint:link')}</StyledH4>
      </StyledLink>
    </BaseSectionWithTitle>
  );
};
