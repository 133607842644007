import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import { Paragraph, ParagraphSmall, H3, H5 } from '../../../../texts';
import { MainGrid } from '../../../../layout';
import { BaseSection } from '../../../../common/BaseSection';
import { UnorderedList } from '../../../../common/UnorderedList';
import { NumberBox } from '../../../../common/NumberBox';
import { SPACING_24, SPACING_16 } from '../../../../../constants/spacing';
import { accent } from '../../../../../constants/colors';

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledBaseSection = styled(BaseSection)`
  padding: 0;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0;
`;

const StyledTitle = styled(H3)`
  padding-top: ${SPACING_24};
`;

export const CarbonFootprintReadMore = (props) => {
  return (
    <StyledSubGrid>
      <StyledBaseSection columns={6}>
        <StyledTitle>{t('esg:carbonFootprintReadMore:what')}</StyledTitle>
        <Paragraph>{t('esg:carbonFootprintReadMore:whatText')}</Paragraph>
        <StyledTitle>{t('esg:carbonFootprintReadMore:how')}</StyledTitle>
        <Paragraph>{t('esg:carbonFootprintReadMore:howText')}</Paragraph>
        <div style={{ paddingTop: SPACING_24 }}>
          <StyledRow>
            <NumberBox number={1} size={60} />
            <div style={{ flex: 1, paddingLeft: SPACING_16 }}>
              <H5 style={{ paddingBottom: 6 }}>
                {t('esg:carbonFootprintReadMore:howList1:title')}
              </H5>
              <ParagraphSmall>
                {t('esg:carbonFootprintReadMore:howList1:text')}
              </ParagraphSmall>
            </div>
          </StyledRow>
          <StyledRow>
            <NumberBox
              number={2}
              size={60}
              color={accent.ocean1}
              backgroundColor={accent.ocean2}
            />
            <div style={{ flex: 1, paddingLeft: SPACING_16 }}>
              <H5 style={{ paddingBottom: 6 }}>
                {t('esg:carbonFootprintReadMore:howList2:title')}
              </H5>
              <ParagraphSmall>
                {t('esg:carbonFootprintReadMore:howList2:text')}
              </ParagraphSmall>
            </div>
          </StyledRow>
        </div>
      </StyledBaseSection>
      <StyledBaseSection columns={1} />
      <StyledBaseSection columns={4}>
        <StyledTitle>{t('esg:carbonFootprintReadMore:why')}</StyledTitle>
        <Paragraph>{t('esg:carbonFootprintReadMore:whyText')}</Paragraph>
        <UnorderedList
          items={[
            t('esg:carbonFootprintReadMore:whyList1'),
            t('esg:carbonFootprintReadMore:whyList2'),
          ]}
          style={{ paddingTop: SPACING_24, marginBottom: 0 }}
        />
      </StyledBaseSection>
      <StyledBaseSection columns={1} />
    </StyledSubGrid>
  );
};
