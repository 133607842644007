import React from 'react';
import styled from 'styled-components';

import { t, sortByAssetClass } from '@formue-app/core';

import {
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_2,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import {
  mobileCondition,
  NotDesktopWrapper,
  notMobileCondition,
} from '../../../constants/media';

import { H4, H6 } from '../../texts';

import { PortfolioStrategyItem } from './PortfolioStrategyItem';
import { PortfolioStrategyLabels } from './PortfolioStrategyLabels';
import { BaseSection } from '../../common/BaseSection';

const ListHeader = styled.div`
  display: flex;
  padding: ${SPACING_16} 0;
  width: 100%;
  border-bottom: 1px solid ${accent.sand3};

  & > * {
    width: 25%;
    text-align: right;
  }
`;

const List = styled.div`
  border-radius: 4px;
  flex: 1;
  position: relative;
`;

const StyledBaseSection = styled(BaseSection)`
  background: none;

  @media ${notMobileCondition} {
    padding: 0;
    border-radius: 0;
  }

  @media ${mobileCondition} {
    margin-bottom: ${SPACING_2};
    cursor: pointer;
  }
`;

const ErrorWrapper = styled.div`
  width: 60%;

  @media ${mobileCondition} {
    margin-top: ${SPACING_24};
    width: 100%;
  }
`;

export const StrategyList = (props) => {
  const {
    strategy,
    overviewCondition = true,
    dataExtractor = 'overview',
    openItems = [],
    setOpenItems,
    showOverviewDetails,
    style,
    ...rest
  } = props;

  if (!strategy) {
    return null;
  }

  const maxValue = Math.max(
    ...strategy.map((item) => Math.max(item.marketShare, item.upperLimit))
  );

  // Handler to call if you want toggle the open state of an item.
  const onSelectedItem = (index) => {
    if (setOpenItems) {
      const itemIndex = openItems.indexOf(index);

      if (itemIndex > -1) {
        openItems.splice(itemIndex, 1);
        setOpenItems([...openItems]);
      } else {
        setOpenItems([...new Set([...openItems, index])]);
      }
    }
  };

  return (
    <>
      <div style={{ position: 'relative', ...style }} {...rest}>
        {strategy.sort(sortByAssetClass).map((item, index) => (
          <StyledBaseSection
            onClick={() => onSelectedItem(index)}
            key={`strategy-item-${index}`}
            className="strategy-item"
          >
            <PortfolioStrategyItem
              {...item}
              {...rest}
              showOverviewDetails={showOverviewDetails}
              maxValue={maxValue}
              index={index}
              total={strategy.length}
              dataExtractor={dataExtractor}
              openDetails={openItems.includes(index)}
            />
          </StyledBaseSection>
        ))}
      </div>
      <NotDesktopWrapper>
        {overviewCondition && (
          <PortfolioStrategyLabels
            style={{ marginTop: SPACING_32 }}
            showOverviewDetails={showOverviewDetails}
            {...rest}
          />
        )}
      </NotDesktopWrapper>
    </>
  );
};

export const PortfolioStrategyList = (props) => {
  const { dataExtractor, style, activeStrategy, ...rest } = props;

  const overviewCondition = !!(activeStrategy && dataExtractor === 'overview');
  const detailsCondition = !!(activeStrategy && dataExtractor === 'details');

  if (!activeStrategy || activeStrategy?.strategyShare === null) {
    return (
      <ErrorWrapper>
        <H4>{t('portfolio:portfolioStrategy:emptyData')}</H4>
      </ErrorWrapper>
    );
  }

  return (
    <List style={style}>
      {detailsCondition && (
        <ListHeader>
          <H6 style={{ textAlign: 'left' }}>
            {t('portfolio:portfolioStrategy:assetClasses')}
          </H6>
          <H6>{t('portfolio:portfolioStrategy:currentDistribution')}</H6>
          <H6>{t('portfolio:portfolioStrategy:strategy')}</H6>
          <H6 style={{ paddingRight: SPACING_24 }}>
            {t('portfolio:portfolioStrategy:boundaries')}
          </H6>
        </ListHeader>
      )}

      <StrategyList
        strategy={activeStrategy.strategy}
        dataExtractor={dataExtractor}
        overviewCondition={overviewCondition}
        showOverviewDetails={true}
        animate={true}
        {...rest}
      />
    </List>
  );
};
