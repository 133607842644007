import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  selectors,
  formatTwrValue,
  formatGainValue,
  t,
  useResource,
  useErrorState,
} from '@formue-app/core';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
} from '../../../constants/spacing';
import { MultipleSwitch } from '../../formElements';
import { mobileCondition } from '../../../constants/media';
import { PortfolioReturnPerYearTitle } from '../slideComponents/portfolio/PortfolioReturnPerYearTitle';
import { accent, textLightest } from '../../../constants/colors';
import { SlideErrorMessage } from '../slideComponents/SlideErrorMessage';
import { PortfolioReturnPerYearChart } from '../slideComponents/portfolio/PortfolioReturnPerYearChart';

const Base = styled.div`
  height: 100%;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const RowWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 95px;
`;

const ContentWrapper = styled.div`
  width: calc(40% - 95px / 2);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 100px;
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  margin-left: auto;

  @media ${mobileCondition} {
    margin-left: 0;
  }
`;

const {
  entities: {
    financialValues: { allfinancialValuesItemsSelector },
  },
} = selectors;

export const PortfolioReturnPerYearSlide = (props) => {
  const financialValues = useSelector(allfinancialValuesItemsSelector);
  const loading = useResource(['FINANCIALVALUES/INDEX']);
  const error = useErrorState(['FINANCIALVALUES/INDEX']);
  const [dataType, setDataType] = useState('twr');

  if (loading) return null;
  if (error) return <SlideErrorMessage />;

  const thisYear = financialValues[financialValues.length - 1];
  const lastYear = financialValues[financialValues.length - 2];

  return (
    <Base>
      <Wrapper>
        <RowWrapper>
          <ContentWrapper style={{ alignSelf: 'flex-start' }}>
            {!loading ? (
              <PortfolioReturnPerYearTitle
                lastYear={lastYear?.p}
                lastYearValue={
                  dataType === 'twr'
                    ? formatTwrValue(lastYear?.twr, 1, true)
                    : formatGainValue(lastYear?.gain)
                }
                thisYearValue={
                  dataType === 'twr'
                    ? formatTwrValue(thisYear?.twr, 1, true)
                    : formatGainValue(thisYear?.gain)
                }
                {...props}
                style={{ paddingRight: 20 }}
              />
            ) : null}
          </ContentWrapper>
          <ContentWrapper
            style={{
              width: `calc(60% - 95px / 2)`,
              padding: `${SPACING_48} 0`,
            }}
          >
            <StyledMultipleSwitch
              backgroundColor={accent.ocean270}
              activeBackgroundColor={accent.ocean1}
              color={accent.ocean1}
              activeColor={textLightest}
              onChange={(value) => {
                setDataType(value);
              }}
              options={[
                {
                  label: t(
                    'presentation:annualReview:returnPerYear:returnInPercentage'
                  ),
                  value: 'twr',
                },
                {
                  label: t(
                    'presentation:annualReview:returnPerYear:returnInKr'
                  ),
                  value: 'gain',
                },
              ]}
            />
            <ChartWrapper>
              {!loading ? (
                <PortfolioReturnPerYearChart
                  financialValues={financialValues}
                  dataType={dataType}
                />
              ) : null}
            </ChartWrapper>
          </ContentWrapper>
        </RowWrapper>
      </Wrapper>
    </Base>
  );
};
