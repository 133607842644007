import React from 'react';
import styled from 'styled-components';

import { backgroundWhite } from '../../constants/colors';
import { SPACING_48, SPACING_16 } from '../../constants/spacing';
import { PaddedBaseSection } from './BaseSection';
import { H6, H3 } from '../texts';
import { tabletCondition } from '../../constants/media';
import { contentColumnCount } from '../../constants/grid';

const StyledPaddedBaseSection = styled(PaddedBaseSection)`
  @media ${tabletCondition} {
    grid-column: span ${contentColumnCount};
    padding: ${SPACING_48};
  }
`;

const StyledHeader = styled.header`
  background: ${backgroundWhite};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${SPACING_16};
  gap: ${SPACING_16};
`;

export const BaseSectionWithTitle = ({
  title,
  label,
  icon,
  children,
  ...rest
}) => {
  return (
    <StyledPaddedBaseSection
      style={{ backgroundColor: backgroundWhite }}
      {...rest}
    >
      {label && (
        <StyledHeader>
          {icon}
          <H6>{label}</H6>
        </StyledHeader>
      )}
      <H3>{title}</H3>
      {children}
    </StyledPaddedBaseSection>
  );
};
