import { Agenda } from './Agenda';
import { Esg } from './Esg';
import { EsgDetails } from './EsgDetails';
import { EsgPreferences } from './EsgPreferences';
import { EsgPreferencesDetails } from './EsgPreferencesDetails';
import { SustainabilityPreferences } from './SustainabilityPreferences';
import { SustainabilityPreferencesDetails } from './SustainabilityPreferencesDetails';
import { FamilyAndOwnership } from './FamilyAndOwnershipSlide';
import { FamilyAndOwnershipDetails } from './FamilyAndOwnershipDetails';
import { Framework } from './Framework';
import { Geography } from './Geography';
import { GeographyDetails } from './GeographyDetails';
import { GoalsSlide } from './GoalsSlide';
import { InvestmentProfileSlide } from './InvestmentProfileSlide';
import { InvestmentProfileDetails } from './InvestmentProfileDetails';
import { InvestmentStrategyConstructionSlide } from './InvestmentStrategyConstructionSlide';
import { PortfolioConstructionBreakdownDetails } from './PortfolioConstructionBreakdownDetails';
import { PortfolioConstructionBreakdownSlide } from './PortfolioConstructionBreakdownSlide';
import { PortfolioStrategyDetails } from './PortfolioStrategyDetails';
import { PortfolioStrategySlide } from './PortfolioStrategySlide';
import { AgendaItemHighlight } from './AgendaItemHighlight';
import { CashFlowSlide } from './CashFlowSlide';
import { CashFlowDetails } from './CashFlowDetails';
import { ThankYou } from './ThankYou';
import { TodoSlide } from './TodoSlide';
import { WealthSlide } from './WealthSlide';
import { WealthDevelopmentSlide } from './WealthDevelopmentSlide';
import { WealthDevelopmentDetails } from './WealthDevelopmentDetails';
import { WealthDetails } from './WealthDetails';
import { Welcome } from './Welcome';
import { PortfolioReturnSlide } from './PortfolioReturnSlide';
import { PortfolioReturnPerYearSlide } from './PortfolioReturnPerYearSlide';
import { ReturnContributionSlide } from './ReturnContributionSlide';
import { ReturnContributionPerAssetClassSlide } from './ReturnContributionPerAssetClassSlide';
import { Sectors } from './Sectors';
import { SectorsDetails } from './SectorsDetails';

export { AgendaItemHighlight, ThankYou, Welcome };

export const slideTypes = {
  Agenda,
  AgendaItemHighlight,
  Esg,
  EsgDetails,
  EsgPreferences,
  EsgPreferencesDetails,
  SustainabilityPreferences,
  SustainabilityPreferencesDetails,
  FamilyAndOwnership,
  FamilyAndOwnershipDetails,
  Framework,
  Geography,
  GeographyDetails,
  GoalsSlide,
  InvestmentProfileSlide,
  InvestmentProfileDetails,
  TodoSlide,
  InvestmentStrategyConstructionSlide,
  PortfolioConstructionBreakdownDetails,
  PortfolioConstructionBreakdownSlide,
  PortfolioStrategyDetails,
  PortfolioStrategySlide,
  WealthDevelopmentSlide,
  WealthDevelopmentDetails,
  Welcome,
  WealthSlide,
  WealthDetails,
  ThankYou,
  PortfolioReturnSlide,
  PortfolioReturnPerYearSlide,
  ReturnContributionSlide,
  ReturnContributionPerAssetClassSlide,
  Sectors,
  SectorsDetails,
  CashFlowSlide,
  CashFlowDetails,
};
