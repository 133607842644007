import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { localizeFloat, selectors, t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_32,
} from '../../../constants/spacing';
import { H2Size } from '../../../constants/text';
import {
  easeInOut,
  extraLongAnimationTime,
} from '../../../constants/animation';
import { titleFontStack } from '../../../fonts';

import { H1, H3 } from '../../texts';

import { HorizontalBarChart } from '../../graphs/HorizontalBarChart';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: 0;
  flex-direction: column;
`;

// This is a bit of a hack to style the label, but there is unfortnetly no good way to pass styles
// down to the chart
const GraphWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 100px;

  h5 {
    color: ${(props) => accent[props.highlightColor]};
  }

  p {
    color: ${(props) => accent[props.titleColor]};
  }

  h5,
  p {
    font-size: ${H2Size};
    font-family: ${titleFontStack};
    font-weight: 700;
  }
`;

const {
  entities: {
    esgData: { esgDataSelector, esgIndexSelector },
  },
  auth: { operatingCountrySelector },
} = selectors;

export const Esg = (props) => {
  const { isActiveSlide, titleColor, highlightColor } = props;

  const esgData = useSelector(esgDataSelector);
  const esgIndex = useSelector(esgIndexSelector);
  const operatingCountry = useSelector(operatingCountrySelector);

  if (!esgData || !esgIndex) return null;

  const local =
    esgData.carbonIntensity > esgIndex.local?.weightedAverageCarbonIntensity
      ? 'more'
      : 'less';

  const global =
    esgData.carbonIntensity > esgIndex.world?.weightedAverageCarbonIntensity
      ? 'more'
      : 'less';

  const title = `${t('esg:carbonFootprint:moreLess1')} ${t(
    `globals:${local}`
  )} ${t('esg:carbonFootprint:moreLess2')} ${t(`globals:${global}`)} ${t(
    'esg:carbonFootprint:moreLess3'
  )}`;

  return (
    <Wrapper>
      {esgData ? (
        <>
          <H1
            style={{
              color: accent[titleColor],
              whiteSpace: 'pre-line',
              opacity: isActiveSlide ? 1 : 0,
              transition: `all ${easeInOut} ${extraLongAnimationTime} 0.8s`,
            }}
          >
            <Trans
              i18nKey={t('presentation:annualReview:esg:pageTitle')}
              values={{
                carbonFootprint: localizeFloat(
                  esgData.weightedAverageCarbonIntensity
                ),
              }}
              components={{
                strong: (
                  <span
                    style={{
                      color: accent[highlightColor],
                      fontWeight: 700,
                      fontFamily: titleFontStack,
                    }}
                  />
                ),
              }}
            />
          </H1>

          <GraphWrapper titleColor={titleColor} highlightColor={highlightColor}>
            <HorizontalBarChart
              style={{ width: '80%' }}
              barWidth={50}
              valueMargin={35}
              barPadding={12}
              animate={isActiveSlide}
              animationDelay={900}
              data={[
                {
                  label: t('esg:localization:your'),
                  value:
                    Math.round(esgData.weightedAverageCarbonIntensity * 10) /
                    10,
                  color: accent.moss3,
                  barType: 'rounded',
                  delay: 0,
                },
                {
                  label: t('esg:localization:country', {
                    context: operatingCountry,
                  }),
                  value:
                    Math.round(
                      esgIndex.local?.weightedAverageCarbonIntensity * 10
                    ) / 10,
                  color: accent.moss1,
                  barType: 'triangle',
                  delay: 100,
                },
                {
                  label: t('esg:localization:world'),
                  value:
                    Math.round(
                      esgIndex.world?.weightedAverageCarbonIntensity * 10
                    ) / 10,
                  color: accent.moss2,
                  barType: 'triangleInverted',
                  delay: 200,
                },
              ]}
              angle={15}
            />

            <H3
              style={{
                color: accent[titleColor],
                maxWidth: '50%',
                marginTop: SPACING_32,

                bottom: isActiveSlide ? 0 : -25,
                position: 'relative',
                opacity: isActiveSlide ? 1 : 0,
                transition: `all ${easeInOut} ${extraLongAnimationTime} 1.4s`,
              }}
            >
              {title}
            </H3>
          </GraphWrapper>
        </>
      ) : null}
    </Wrapper>
  );
};
