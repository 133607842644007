import React from 'react';
import styled from 'styled-components';

import { H5Size, H5LineHeight } from '../../constants/text';

export const StyledH5 = styled.h5`
  font-size: ${H5Size};
  line-height: ${H5LineHeight};
  font-weight: 500;
`;

export const H5 = (props) => {
  const { children } = props;

  return <StyledH5 {...props}>{children}</StyledH5>;
};
