export const getStartAndEndDate = (monthIndex, year, startDate, endDate) => {
  let newStartDate = startDate;
  let newEndDate = endDate;

  // By default set the date to be the last day in month
  let selectedDate = new Date(Date.UTC(year, monthIndex + 1, 0));

  // If there is no start or end date, set to the first day of the selected month
  if (!startDate && !endDate) {
    selectedDate = new Date(Date.UTC(year, monthIndex, 1));
  }

  // If it's the current month, set to the current date
  const today = new Date();
  const currentMonth = today.getUTCMonth();
  const currentYear = today.getUTCFullYear();

  if (monthIndex === currentMonth && year === currentYear) {
    selectedDate = new Date(Date.UTC(currentYear, currentMonth, 1));
  }

  if (!startDate || (startDate && endDate)) {
    newStartDate = selectedDate;
    newEndDate = null;
  } else if (startDate && !endDate) {
    if (selectedDate.toISOString() === new Date(startDate).toISOString()) {
      newStartDate = null;
    } else if (selectedDate > startDate) {
      newEndDate = selectedDate;
    } else {
      // If the selected date is after the end date we need to flip the dates
      // and set the start date to the first day of the selected month and enddate to be the last
      // day of the previously selected month
      const startDateYear = new Date(startDate).getUTCFullYear();
      const startDateMonthIndex = new Date(startDate).getUTCMonth();

      newStartDate = new Date(Date.UTC(year, monthIndex, 1));
      newEndDate = new Date(
        Date.UTC(startDateYear, startDateMonthIndex + 1, 0)
      );
    }
  }

  return { newStartDate, newEndDate };
};

export const getPredefinedRanges = (period) => {
  let newStartDate = null;
  let newEndDate = null;

  const now = new Date();
  switch (period) {
    case 'THISMONTH':
      newStartDate = new Date(now.getFullYear(), now.getMonth(), 2);
      newEndDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      break;
    case 'THISYEAR':
      newStartDate = new Date(Date.UTC(now.getFullYear(), 0, 1));
      newEndDate = now;
      break;
    case 'TWELVEMONTHS':
      newStartDate = new Date(now.getFullYear() - 1, now.getMonth(), 2);
      newEndDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      break;
    case 'THREEYEARS':
      newStartDate = new Date(now.getFullYear() - 3, now.getMonth(), 2);
      newEndDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      break;
    case 'FIVEYEARS':
      newStartDate = new Date(now.getFullYear() - 5, now.getMonth(), 2);
      newEndDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      break;
    case 'TENYEARS':
      newStartDate = new Date(now.getFullYear() - 10, now.getMonth(), 2);
      newEndDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      break;
    default:
      break;
  }

  return { newStartDate, newEndDate };
};
