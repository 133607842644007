import React, { useState, useEffect } from 'react';
import { VictoryPie, VictoryContainer } from 'victory';
import styled from 'styled-components';
import { accent, primary } from '../../../../constants/colors';

class Point extends React.Component {
  render() {
    const { x, y, datum, showLabels, data } = this.props;

    const { showLabel, showLabelBar, labelColor } = data[datum._x];
    if (!showLabels || !showLabel) {
      return null;
    }

    const height = 27;
    const width = 22.5;

    return (
      <svg x={x - width / 2} y={y - height / 2}>
        <path
          d="M0.131043 16.5393C0.131042 7.53929 11.381 0.789286 11.381 0.789286C11.381 0.789286 22.631 8.66428 22.631 16.5393C22.631 22.7525 17.5942 27.7893 11.381 27.7893C5.16784 27.7893 0.131043 22.7525 0.131043 16.5393Z"
          fill={labelColor}
          fillOpacity="0.9"
          transform={`rotate(${datum.endAngle} 11.381 16.2893)`}
        />
        <ellipse
          cx="11.381"
          cy="16.2893"
          rx="9"
          ry="9.5"
          transform={`rotate(${datum.endAngle} 11.381 16.2893)`}
          fill="#F5F3F0"
        />
        {showLabelBar && (
          <react
            transform={`rotate(${datum.endAngle} 11.381 16.2893)`}
            y={-height + datum.label / 10}
            x={width / 2}
            height="25"
            width="2"
            stroke={accent.moss1}
            fill={accent.moss1}
          />
        )}
        <text
          fontSize="10"
          x={width / 2}
          y={height - 7}
          textAnchor="middle"
          fontFamily="IBMPlexSans"
          color={primary}
        >
          {Math.round(datum.label * 10) / 10}
        </text>
      </svg>
    );
  }
}

const StyledChart = styled.div`
  flex: 1;
  align-content: 'center';
  align-items: 'center';
  justify-content: 'center';
`;

export const EsgPieChart = ({
  scaleInner = 1,
  scaleOuter,
  activeItem,
  height,
  width = 300,
  barWidth = 26,
  children,
  colors,
  startData,
  data,
  activeItems = [],
  padAngle = 2,
  showLabels,
  labelPosition = 'startAngle',
  labelColor,
  ...rest
}) => {
  const [pieData, setPieData] = useState(startData ? startData : data);

  useEffect(() => {
    setPieData(data);
    // eslint-disable-next-line
  }, []);

  return (
    <StyledChart {...rest}>
      <VictoryPie
        containerComponent={<VictoryContainer disableContainerEvents />}
        innerRadius={({ datum }) => {
          return (
            width / 2 -
            barWidth * (1 - scaleInner) +
            (activeItem === datum._x ? 10 : 0)
          );
        }}
        radius={({ datum }) => {
          return (
            width / 2 -
            barWidth * (1 - scaleOuter) +
            (activeItem === datum._x ? 10 : 0)
          );
        }}
        data={pieData}
        padAngle={padAngle}
        colorScale={colors}
        animate={{
          animationWhitelist: [
            'endAngle',
            'data',
            'style',
            'colorScale',
            'innerRadius',
            'radius',
            'labelRadius',
          ],
          duration: 500,
        }}
        delay={1000}
        labelRadius={105}
        startAngle={180}
        endAngle={540}
        labelPlacement="perpendicular"
        labelPosition={labelPosition}
        labelComponent={
          <Point showLabels={showLabels} labelColor={labelColor} />
        }
      />
    </StyledChart>
  );
};
