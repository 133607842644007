import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  formatHexOpacity,
  getFlagsSelector,
  selectors,
  t,
  useResource,
} from '@formue-app/core';

import {
  SPACING_32,
  SPACING_24,
  SPACING_64,
  SPACING_8,
} from '../../constants/spacing';
import { accent, textLightest } from '../../constants/colors';
import { H2, Paragraph } from '../../components/texts';

import { BaseSection } from '../../components/common/BaseSection';
import { PortfolioChart } from '../../components/portfolio/charts/PortfolioChart';
import { PortfolioGainTotal } from '../../components/portfolio/overview/PortfolioGainTotal';
import { PortfolioMarketValueTotal } from '../../components/portfolio/overview/PortfolioMarketValueTotal';
import { PortfolioPeriodFilter } from '../../components/portfolio/filters/PortfolioPeriodFilter';
import { PortfolioStructure } from '../../components/portfolio/overview/PortfolioStructure';
import { HoldingsList } from '../../components/portfolio/holdings/HoldingsList';
import { EsgSummary } from '../../components/portfolio/overview/EsgSummary';
import { SummaryBox } from '../../components/common/SummaryBox';
import { GeographySummary } from '../../components/portfolio/overview/GeographySummary';
import { CompaniesSummary } from '../../components/portfolio/overview/CompaniesSummary';
import { PortfolioUpdatedInfo } from '../../components/portfolio/overview/PortfolioUpdatedInfo';

import { mobileCondition, notDesktopCondition } from '../../constants/media';
import { MultipleSwitch } from '../../components/formElements';
import { PortfolioConstructionSummary } from '../../components/portfolio/overview/PortfolioConstructionSummary';
import { ExtendedReportingSummary } from '../../components/portfolio/overview/ExtendedReportingSummary';
import { VisibleInCountry } from '../../components/common/VisibleInCountry';
import { StyledRow } from '../../components/lists';
import { SpriteIcon } from '../../components/common/SpriteIcon';
import { SustainabilitySummary } from '../../components/portfolio/overview/SustainabilitySummary';

const {
  entities: {
    portfolios: { portfolioAssetClassesWithSubitemsSelector },
    marketValues: { marketValuesSelector },
  },
} = selectors;

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${SPACING_24};
  gap: ${SPACING_64};
  height: 100px;

  @media ${notDesktopCondition} {
    gap: ${SPACING_24};
    height: auto;
  }

  @media ${mobileCondition} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledNoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING_24};
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  margin-left: auto;
  padding: 1px;

  @media ${mobileCondition} {
    margin-left: 0;
  }
`;

const StyledSummaryBox = styled(SummaryBox)`
  .content-wrapper {
    padding-top: 0;
  }
`;

const StyledH2 = styled(H2)`
  color: ${textLightest};
`;

const StyledParagraph = styled(Paragraph)`
  color: ${textLightest};
`;

const LightStyledRow = styled(StyledRow)`
  outline: 2px solid ${accent.sand1};
  border-collapse: collapse;
  border-spacing: 0 8px;
`;

export const PortfolioOverviewPage = () => {
  const assetClasses = useSelector(portfolioAssetClassesWithSubitemsSelector);
  const [dataExtractor, setDataExtractor] = useState('twr');

  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const marketValues = useSelector(marketValuesSelector);
  const flags = useSelector(getFlagsSelector);

  const hasPortfolioContent = loading || Boolean(marketValues.length);

  const colorSet =
    dataExtractor === 'mv'
      ? [
          accent.velvet4,
          accent.velvet3,
          formatHexOpacity(accent.velvet3, 0.3),
          accent.velvet3,
          accent.velvet2,
          accent.velvet3,
          '#3D296C',
        ]
      : [
          accent.ocean4,
          accent.ocean2,
          '#264990',
          accent.ocean3,
          accent.ocean2,
          accent.ocean3,
          '#223371',
        ];

  return (
    <>
      <BaseSection
        columns={12}
        style={{ padding: 0, backgroundColor: colorSet[0] }}
      >
        {hasPortfolioContent ? (
          <>
            <StyledWrapper>
              <PortfolioMarketValueTotal highlightColor={colorSet[4]} />
              <PortfolioGainTotal highlightColor={colorSet[4]} />
              <StyledMultipleSwitch
                color={colorSet[4]}
                backgroundColor={colorSet[6]}
                borderColor={colorSet[5]}
                activeBackgroundColor={colorSet[5]}
                onChange={(value) => {
                  setDataExtractor(value);
                }}
                options={[
                  { label: t('portfolio:return'), value: 'twr' },
                  {
                    label: t('portfolio:holdingsTable:marketValue'),
                    value: 'mv',
                  },
                ]}
              />
            </StyledWrapper>
            <PortfolioChart dataExtractor={dataExtractor} />
            <PortfolioPeriodFilter
              highlightColor={colorSet[2]}
              borderColor={colorSet[3]}
            />
          </>
        ) : (
          <StyledNoContentWrapper>
            <StyledH2>{t('errorMessages:noPortfolio:title')}</StyledH2>
            <StyledParagraph>
              {t('errorMessages:noPortfolio:text')}
            </StyledParagraph>
          </StyledNoContentWrapper>
        )}
      </BaseSection>

      {/* Hides an entry point behind the "extendedReporting" feature flag. */}
      {flags.features.extendedReporting && (
        <ExtendedReportingSummary columns={12} />
      )}

      <PortfolioConstructionSummary columns={3} />
      <SustainabilitySummary columns={3} />
      <GeographySummary columns={3} />
      <CompaniesSummary columns={3} />

      <PortfolioStructure columns={12} />

      <StyledSummaryBox
        icon={<SpriteIcon id="Capital" size={SPACING_24} />}
        title={t('portfolio:holdingsTable:title')}
        label={t('portfolio:seeAllSecurities')}
        url="/portfolio/holdings?showAll=true#holdingsList"
        columns={12}
        style={{ marginTop: SPACING_8 }}
      >
        <HoldingsList
          // Remove sub items using deconstruction to disable hover for items
          items={assetClasses.map(({ subItems, ...rest }) => rest)}
          clickCallback={null}
          style={{
            marginBottom: SPACING_32,
          }}
          rowComponent={LightStyledRow}
        />
      </StyledSummaryBox>
      <PortfolioUpdatedInfo />
    </>
  );
};
