import { stringify } from 'query-string';

import { config } from '../../config';

import {
  getDeepLink,
  getToken,
  generateCodeVerifier,
  generateCodeChallenge,
  generateRandomAuthStateString,
} from './utilities';

// Re-export a few utility functions so they are accessible to the other packages
export {
  getDeepLink,
  generateCodeVerifier,
  generateCodeChallenge,
  getToken,
  generateRandomAuthStateString,
};

/**
 * Exchange a username for an access token
 * Not a safe auth method, just for testing
 */
export const exchangeUsernameForToken = async ({ username, password }) => {
  const data = {
    grant_type: 'username',
    username,
    password,
  };

  return getToken(data);
};

/**
 * Exchange an authCode together with a code verifier for an access token
 */
export const exchangeAuthCodeForToken = async (
  code,
  codeVerifier,
  callbackUrl
) => {
  const redirectUri = getDeepLink(callbackUrl);
  const data = {
    grant_type: 'authorization_code',
    code,
    redirect_uri: redirectUri,
    client_id: config.api.client.user,
    code_verifier: codeVerifier,
  };

  return getToken(data);
};

/**
 * Exchange an Azure AD Id token for an access token
 */
export const exchangeIdTokenForToken = async ({ idToken }) => {
  const data = {
    grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
    subject_token: idToken,
    subject_issuer: 'azure-ad',
    subject_token_type: 'urn:ietf:params:oauth:token-type:id_token',
  };

  return getToken(data);
};

/**
 * Return authorization url
 */
export const getAuthorizationUrl = (
  method,
  callbackUrl,
  codeVerifier,
  options = {}
) => {
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const randomStateString = generateRandomAuthStateString();

  // Collect the link we want the OAuth2 service provider to redirect back
  // at the end of the authentication flow.
  const redirectUri = getDeepLink(callbackUrl);

  // Generate query params for the Auth2 authorization code flow standard
  // The method parameter is an addition to the standard params that tells
  // the backend which of the signicat services we wan't to use.
  const query = {
    response_type: 'code',
    method,
    client_id: config.api.client.user,
    state: randomStateString,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  };

  const { locale, platform } = options;

  if (locale) {
    query.ui_locales = locale;
  }

  if (platform) {
    query.login_hint = platform === 'ios' ? 'platform-IOS' : 'platform-ANDROID';
  }

  const stringifiedQuery = stringify(query);
  const host = config.api.host;
  const url = `${host}/auth/authorize?${stringifiedQuery}&redirect_uri=${redirectUri}`;
  return url;
};
