import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { H6 } from '../../../../texts';
import { SPACING_16 } from '../../../../../constants/spacing';

import { ReactComponent as CoveragePyramidImage } from '../../../../../assets/portfolio/coverage-pyramid.svg';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${SPACING_16} 0;
  padding-bottom: 5px;
`;

const StyledContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  margin: 41px 0;
`;

const Label = styled(H6)`
  white-space: nowrap;
`;

export const CoveragePyramid = () => {
  return (
    <StyledContainer>
      <StyledContent>
        <Label>
          {`${t(
            'esg:coverageReadMore:pyramid:alternativeGrowth'
          ).toUpperCase()} *`}
        </Label>
        <Label>
          {t('esg:coverageReadMore:pyramid:marketGrowth').toUpperCase()}
        </Label>
        <Label>
          {t('esg:coverageReadMore:pyramid:stability').toUpperCase()}
        </Label>
      </StyledContent>
      <CoveragePyramidImage width={334} height={207} />
    </StyledContainer>
  );
};
