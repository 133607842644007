import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';

import { getFlagsSelector, t } from '@formue-app/core';

import { actions as webauthnActions } from '../../store/webauthn';
import { actions as passkeysActions } from '../../store/ui/passkeys';

import { GridElement } from '../layout';
import { accent } from '../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_24,
  SPACING_32,
  SPACING_16,
} from '../../constants/spacing';
import { mobileCondition } from '../../constants/media';
import { H2, H2Small, List, ListItem, Paragraph } from '../texts';
import { Button } from '../buttons';

import PasskeysBackground from '../../assets/images/passkeys-background.svg';
import { Modal } from '../common/Modal';
import { SpriteIcon } from '../common/SpriteIcon';

const StyledGridElement = styled(GridElement)`
  background: ${accent.ocean230};
  background-image: url(${PasskeysBackground});
  background-position: right bottom;
  background-repeat: no-repeat;
  border-radius: ${BORDER_RADIUS_LARGE};

  ${(props) =>
    props.compact &&
    css`
      background-position: right top 20px;
      padding-top: calc(247px + ${SPACING_48});
    `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Content = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_32};
  padding: ${SPACING_48};

  @media ${mobileCondition} {
    padding: ${SPACING_24};
  }
`;

const Text = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
`;

export const PasskeysOnboardingPromotion = (props) => {
  const { columns = 12, compact } = props;
  const dispatch = useDispatch();
  const flags = useSelector(getFlagsSelector);
  const passkeysFlagEnabled = flags?.features?.passkeys;

  const [infoModal, setInfoModal] = useState(false);
  const [dismissModal, setDismissModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const passkeysAlreadyEnabled = useSelector(
    (state) => state.webauthn.passkeysEnabled
  );

  // We need to determine if we should show this onboarding promotion.
  // We only want to do so if user has not already registered, the browser
  // supports WebAuthn and they have the feature flag enabled
  const showPasskeysRegistrationCondition =
    !passkeysAlreadyEnabled && browserSupportsWebAuthn() && passkeysFlagEnabled;

  if (!showPasskeysRegistrationCondition) {
    return null;
  }

  const registerNewPasskey = async () => {
    dispatch(webauthnActions.startRegistration());
  };

  return (
    <>
      <StyledGridElement columns={columns} compact={compact}>
        <Wrapper>
          <Content>
            <Text>
              <H2Small>{t('home:passkeys:title')}</H2Small>
              <List style={{ margin: 0 }}>
                {t('home:passkeys:argumentsList', {
                  returnObjects: true,
                }).map((item, index) => (
                  <ListItem key={`list-item-${index}`}>{item}</ListItem>
                ))}
              </List>
            </Text>
            <ButtonWrapper>
              <Button buttonstyle="filled" onClick={() => setInfoModal(true)}>
                {t('home:passkeys:actionButton')}
              </Button>
              {!compact ? (
                <Button
                  buttonstyle="text"
                  onClick={() => setDismissModal(true)}
                >
                  {t('home:passkeys:maybeLater')}
                </Button>
              ) : null}
            </ButtonWrapper>
          </Content>
        </Wrapper>
      </StyledGridElement>
      <Modal
        isOpen={infoModal}
        onRequestClose={() => setInfoModal(false)}
        contentWidth={600}
        padding={SPACING_48}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: SPACING_16,
            marginBottom: SPACING_32,
          }}
        >
          <SpriteIcon id="Advisor" size={56} />
          <H2>{t('home:passkeys:infoModal:title')}</H2>
          <Paragraph style={{ whiteSpace: 'pre-line' }}>
            {t('home:passkeys:infoModal:body')}
          </Paragraph>
        </div>
        <Button onClick={() => registerNewPasskey()}>
          {t('home:passkeys:infoModal:ctaButton')}
        </Button>
      </Modal>
      <Modal
        isOpen={dismissModal}
        onRequestClose={() => setDismissModal(false)}
        contentWidth={600}
        padding={SPACING_48}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: SPACING_16,
            marginBottom: SPACING_32,
          }}
        >
          <Paragraph style={{ fontWeight: 700 }}>
            {t('home:passkeys:dismissModal:title')}
          </Paragraph>
          <Paragraph style={{ whiteSpace: 'pre-line' }}>
            {t('home:passkeys:dismissModal:body')}
          </Paragraph>
        </div>
        <Button
          onClick={() => {
            setDismissModal(false);
            dispatch(passkeysActions.dismissPasskeysOnboarding(new Date()));
          }}
        >
          {t('globals:hide')}
        </Button>
      </Modal>
      <Modal
        isOpen={successModal}
        onRequestClose={() => setSuccessModal(false)}
        contentWidth={600}
        padding={SPACING_48}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: SPACING_16,
            marginBottom: SPACING_32,
          }}
        >
          <SpriteIcon id="OK" size={56} />
          <H2 style={{ fontWeight: 700 }}>
            {t('home:passkeys:successModal:title')}
          </H2>
          <Paragraph style={{ whiteSpace: 'pre-line' }}>
            {t('home:passkeys:successModal:body')}
          </Paragraph>
          <List style={{ margin: 0 }}>
            {t('home:passkeys:successModal:list', {
              returnObjects: true,
            }).map((item, index) => (
              <ListItem key={`success-list-item-${index}`}>{item}</ListItem>
            ))}
          </List>
        </div>
        <Button
          onClick={() => {
            setSuccessModal(false);
          }}
        >
          {t('globals:hide')}
        </Button>
      </Modal>
    </>
  );
};
