import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [filterValuesAllIds, filterValuesById, allFilterValuesSelector] =
  resourceSelectors('filterValues');

export const taxClassesSelector = createSelector(
  allFilterValuesSelector,
  (filterValues) => {
    return filterValues
      .filter((filterValue) => filterValue.hasOwnProperty('tax'))
      .map((filterValue) => filterValue.tax);
  }
);

export const investmentAccountsSelector = createSelector(
  allFilterValuesSelector,
  (filterValues) => {
    return filterValues
      .filter((filterValue) => filterValue.hasOwnProperty('acc'))
      .map((filterValue) => filterValue.acc);
  }
);
