import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  getSocialResponsibilityIndicators,
  localizeFloat,
  selectors,
  t,
} from '@formue-app/core';
import { BaseSection } from '../../../common/BaseSection';
import { H2, Paragraph, ParagraphXSmall } from '../../../texts';
import { accent } from '../../../../constants/colors';
import {
  SPACING_32,
  SPACING_12,
  SPACING_8,
  SPACING_48,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../../constants/spacing';
import { Button } from '../../../buttons';
import { Modal } from '../../../common/Modal';
import { SustainabilityPieChart } from './SustainabilityPieChart';
import { SocialResponsibilityModalContent } from './SocialResponsibilityModalContent';

const i18nPrefix = 'sustainability:socialResponsibility';

const {
  entities: {
    portfolioLenses: {
      allPortfolioLensesSelector,
      portfolioLensesMetadataSelector,
    },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_32};
  display: flex;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${SPACING_12};
  padding-bottom: ${SPACING_32};
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
`;

const PieChartContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: -2px;
`;

export const SocialResponsibility = ({ temperature = 1, ...props }) => {
  const portfolioLenses = useSelector(allPortfolioLensesSelector);
  const portfolioLensesMetadata = useSelector(portfolioLensesMetadataSelector);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (!portfolioLenses) return null;

  const indicators = getSocialResponsibilityIndicators(
    portfolioLenses,
    portfolioLensesMetadata
  );

  return (
    <StyledBaseSection {...props}>
      <Wrapper>
        <SustainabilityPieChart
          data={Array(5)
            .fill()
            .map((item, index) => ({
              value: 1,
              color: index > indicators - 1 ? accent.sand2 : accent.velvet1,
            }))}
        >
          <PieChartContent>
            <H2>{indicators}</H2>
            <ParagraphXSmall style={{ top: -3 }}>of 5</ParagraphXSmall>
          </PieChartContent>
        </SustainabilityPieChart>
      </Wrapper>
      <H2>{t(`${i18nPrefix}:title`)}</H2>
      <Paragraph style={{ padding: `${SPACING_8} 0` }}>
        {t(`${i18nPrefix}:entryText`, { value: localizeFloat(indicators, 0) })}
      </Paragraph>
      <ButtonWrapper>
        <Button onClick={() => setModalIsOpen(true)} style={{ marginTop: 32 }}>
          {t('globals:readMore')}
        </Button>
      </ButtonWrapper>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={t('sustainability:socialResponsibility:title')}
        labelComponent={H2}
        padding={SPACING_48}
      >
        <SocialResponsibilityModalContent />
      </Modal>
    </StyledBaseSection>
  );
};
