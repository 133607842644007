import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isFunction } from 'lodash';

import {
  getAssetAllocationGroups,
  getAssetClassByAssetClassGroup,
  getAssetClassName,
  percentage,
  selectors,
  generateContributionChartAssetClassColor,
  t,
} from '@formue-app/core';

import {
  BORDER_RADIUS_EXTRA_LARGE,
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_12,
} from '../../constants/spacing';
import { accent, backgroundWhite } from '../../constants/colors';
import { H3, Paragraph, ParagraphSmall } from '../texts';
import { PieChart } from '../graphs/PieChart';
import { GridElement, MainGrid } from '../layout';

const Container = styled(MainGrid)`
  width: 100%;
  gap: ${SPACING_24};
  margin: ${SPACING_24} 0 ${SPACING_48} 0;
  padding: 0;
  max-width: 100%;
`;

const Column = styled(GridElement)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const ColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${SPACING_8};
  padding-right: ${SPACING_16};
  align-items: center;
`;

const ShareValue = styled(Paragraph)`
  font-weight: 500;
`;

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AssetClass = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  background: ${backgroundWhite};
  padding: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const AssetClassHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Product = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${BORDER_RADIUS_LARGE};
  background-color: ${(props) => props.color};
  margin-right: ${SPACING_8};
`;

const {
  entities: {
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsByStrategyIdSelector,
    },
    investmentStrategyProducts: {
      investmentStrategyProductsByStrategyIdSelector,
    },
  },
} = selectors;

export const StrategyProductsTable = (props) => {
  const {
    strategyId,
    itemStyles,
    headingComponent = H3,
    displayProducts,
    ...rest
  } = props;

  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(strategyId)
  );
  const products = useSelector(
    investmentStrategyProductsByStrategyIdSelector(strategyId)
  );

  const assetClassGroups = getAssetAllocationGroups(assetAllocations);

  const HeadingComponent = headingComponent;

  return (
    <Container {...rest}>
      {Object.keys(assetClassGroups).map((groupKey) => {
        const assetClassGroup = assetClassGroups[groupKey];
        const groupShare = assetClassGroup?.formueStrategyShare;
        return (
          <Column key={`column-item-${groupKey}`} columns={4}>
            <ColumnHeader>
              <ChartWrapper>
                <PieChart
                  style={{ marginRight: SPACING_12 }}
                  size={28}
                  innerRadius={7}
                  cornerRadius={1}
                  padAngle={8}
                  data={Object.keys(assetClassGroups).map((g) => ({
                    value: assetClassGroups[g].formueStrategyShare,
                    color:
                      g === groupKey
                        ? generateContributionChartAssetClassColor(groupKey, accent)
                        : accent.sand2,
                  }))}
                />
                <HeadingComponent>
                  {getAssetClassName(groupKey)}
                </HeadingComponent>
              </ChartWrapper>
              <ShareValue>{percentage(groupShare * 100)}</ShareValue>
            </ColumnHeader>
            {getAssetClassByAssetClassGroup(groupKey).map((classKey, index) => {
              const assetClassProducts = products.filter(
                (item) => item.assetClass === classKey
              );
              const assetAllocation = assetAllocations.find(
                (assetAllocation) => assetAllocation.assetClass === classKey
              );
              return (
                <AssetClass
                  style={
                    isFunction(itemStyles)
                      ? itemStyles(classKey, index)
                      : itemStyles
                  }
                  key={`asset-class-item-${classKey}`}
                >
                  <AssetClassHeader>
                    <Dot
                      color={generateContributionChartAssetClassColor(classKey, accent)}
                    />
                    <ShareValue>{getAssetClassName(classKey)}</ShareValue>
                    {!displayProducts && (
                      <ShareValue style={{ marginLeft: 'auto' }}>
                        {percentage(assetAllocation.formueStrategyShare * 100)}
                      </ShareValue>
                    )}
                  </AssetClassHeader>
                  {!displayProducts && (
                    <ParagraphSmall style={{ fontWeight: 300 }}>{`${t(
                      'portfolio:portfolioStrategy:boundaries'
                    )}: ${percentage(
                      assetAllocation.formueLowerLimit * 100
                    )} - ${percentage(
                      assetAllocation.formueUpperLimit * 100
                    )}`}</ParagraphSmall>
                  )}
                  {displayProducts &&
                    assetClassProducts.map((product) => (
                      <Product>
                        <Paragraph>{product.name}</Paragraph>
                        <ShareValue>
                          {percentage(product.strategyShare * 100)}
                        </ShareValue>
                      </Product>
                    ))}
                </AssetClass>
              );
            })}
          </Column>
        );
      })}
    </Container>
  );
};
