import React from 'react';
import { t, useFullName } from '@formue-app/core';

import { MenuPage } from './base/MenuPage';
import { Feed } from '../components/feed/Feed';
import { NotDesktopWrapper, DesktopWrapper } from '../constants/media';
import { H2 } from '../components/texts';
import { GridElement } from '../components/layout';
import { SPACING_24 } from '../constants/spacing';

import { TodoHomeContainer } from '../components/todo/TodoHomeContainer';
import { MarketValueSummary } from '../components/portfolio/MarketValueSummary';
import { EventCarousel } from '../components/events/EventCarousel';
import { ModalSaveFilters } from '../components/extendedReporting/filters/ModalSaveFilters';

export const HomePage = (props) => {
  const name = useFullName(t('dashboard:welcome'));

  let columns = 12;

  return (
    <MenuPage {...props} title={name} subpage={false}>
      <NotDesktopWrapper>
        <MarketValueSummary columns={columns} />
        <EventCarousel columns={columns} sliderHeight={52} />
      </NotDesktopWrapper>

      <DesktopWrapper>
        <MarketValueSummary columns={8} />
        <EventCarousel columns={4} />
      </DesktopWrapper>

      <TodoHomeContainer
        taxSEContainEnabled={true}
        taxNOContainEnabled={true}
        quizEnabled={true}
      />

      <ModalSaveFilters />
      <GridElement columns={columns} style={{ marginTop: SPACING_24 }}>
        <H2>{t('dashboard:news:newsfeed')}</H2>
      </GridElement>
      <Feed />
    </MenuPage>
  );
};
