import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
} from '../../../constants/spacing';

import { MainGrid } from '../../layout';
import { Modal } from '../../common/Modal';

import { CarbonFootprint } from '../../portfolio/lenses/esg/CarbonFootprint';
import { Score } from '../../portfolio/lenses/esg/Score';
import { Benchmark } from '../../portfolio/lenses/esg/Benchmark';
import { Trend } from '../../portfolio/lenses/esg/Trend';
import { CompanyPromise } from '../../portfolio/lenses/esg/CompanyPromise';
import { EsgFooterSection } from '../../portfolio/lenses/esg/EsgFooterSection';
import { EsgScoreReadMore } from '../../portfolio/lenses/esg/modals/EsgScoreReadMore';
import { EsgCoverageReadMore } from '../../portfolio/lenses/EsgCoverageReadMore';
import { CarbonFootprintReadMore } from '../../portfolio/lenses/esg/modals/CarbonFootprintReadMore';
import { H2 } from '../../texts';

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const {
  entities: {
    esgData: { esgDataSelector },
  },
} = selectors;

export const EsgDetails = (props) => {
  const esgData = useSelector(esgDataSelector);
  const [modal, setModal] = useState('');

  if (!esgData) {
    return null;
  }

  return (
    <Wrapper>
      <CarbonFootprint
        columns={6}
        onClick={() => {
          setModal('carbonFootprint');
        }}
      />
      <Score
        columns={6}
        onClick={() => {
          setModal('esgScore');
        }}
      />
      <Benchmark columns={6} />
      <Trend columns={6} />
      <CompanyPromise columns={12} />
      {/* <EsgNewsfeed columns={12} /> */}
      <EsgFooterSection
        onClick={() => {
          setModal('coverage');
        }}
      />
      <Modal
        isOpen={modal.length ? true : false}
        onRequestClose={() => setModal('')}
        contentLabel={t(`esg:${modal}ReadMore:title`)}
        labelComponent={H2}
        padding={SPACING_48}
      >
        {modal === 'esgScore' && <EsgScoreReadMore />}
        {modal === 'coverage' && <EsgCoverageReadMore />}
        {modal === 'carbonFootprint' && <CarbonFootprintReadMore />}
      </Modal>
    </Wrapper>
  );
};
