import {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export const useResize = (ref) => {
  const getDimensions = () => ({
    width: ref.current.offsetWidth,
    height: ref.current.offsetHeight,
  });

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (ref.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  return dimensions;
};

export const useBoundingRect = () => {
  /**
   * @desc Get outer dimensions of an element
   *
   * @return ref - to be added to the DOM element which dimensions should be measured
   * @return dimensions - width & height of the DOM element
   */
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const ref = useCallback((node) => {
    if (node !== null) {
      setDimensions(node.getBoundingClientRect());
    }
  }, []);

  return { ref, dimensions };
};

// This hook handles tracking the size/dimensions of the "referenced component"
// without the browsers "window resize" event.
// The hook is useful when the layout changes for non-responsive reasons.
// It's sort of an alternative to already existing "useResize()"
export const useSize = (target) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    target && setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

// Get width of an svg element
export const useSvgWidth = () => {
  /**
   * @desc Get outer width of an SVG element
   *
   * @return ref - to be added to the DOM element which dimensions should be measured
   * @return width - width of an SVG element
   */
  const ref = useRef();
  const [width, setWidth] = useState(undefined);

  const getViewBox = useCallback(() => {
    // If svg not mounted yet, exit
    if (!ref.current) return;
    // Get bbox of content in svg
    const { width } = ref.current.getBBox();
    setWidth(width);
  }, []);

  useEffect(() => {
    getViewBox();
  });

  return [ref, width];
};
