import React from 'react';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';
import { BaseSectionWithTitle } from '../../../common/BaseSectionWithTitle';
import { ParagraphSmall } from '../../../texts';
import { HorizontalBarChart } from '../../../graphs/HorizontalBarChart';
import { accent } from '../../../../constants/colors';
import { SPACING_24, SPACING_48 } from '../../../../constants/spacing';
import { SpriteIcon } from '../../../common/SpriteIcon';

const {
  entities: {
    esgData: { esgIndexSelector, esgDataSelector },
  },
  auth: { operatingCountrySelector },
} = selectors;

export const Benchmark = ({ ...props }) => {
  const esgIndex = useSelector(esgIndexSelector);
  const esgData = useSelector(esgDataSelector);
  const operatingCountry = useSelector(operatingCountrySelector);

  if (!esgIndex || !esgData) return null;

  return (
    <BaseSectionWithTitle
      label={t('esg:benchmark:title')}
      title={t('esg:benchmark:subtitle', {
        local:
          esgData.qualityScore > esgIndex.local?.esgQualityScore
            ? t('globals:higher')
            : t('globals:lower'),
        world:
          esgData.qualityScore > esgIndex.world?.esgQualityScore
            ? t('globals:higher')
            : t('globals:lower'),
      })}
      icon={<SpriteIcon id="Barchart" />}
      {...props}
    >
      <ParagraphSmall style={{ paddingTop: 12 }}>
        {t('esg:benchmark:expanded:text')}
      </ParagraphSmall>
      <HorizontalBarChart
        style={{ paddingTop: SPACING_24, paddingBottom: SPACING_48 }}
        animate={true}
        data={[
          {
            label: t('esg:localization:your'),
            value: Math.round(esgData.qualityScore * 10) / 10,
            color: accent.ocean1,
            barType: 'triangle',
            delay: 0,
          },
          {
            label: t('esg:localization:country', {
              context: operatingCountry,
            }),
            value: Math.round(esgIndex.local?.esgQualityScore * 10) / 10,
            color: accent.ocean2,
            barType: 'triangleInverted',
            delay: 100,
          },
          {
            label: t('esg:localization:world'),
            value: Math.round(esgIndex.world?.esgQualityScore * 10) / 10,
            color: accent.ocean3,
            barType: 'rounded',
            delay: 200,
          },
        ]}
      />
      <ParagraphSmall style={{ whiteSpace: 'pre-wrap', fontStyle: 'italic' }}>
        {t('esg:localization:index', {
          context: operatingCountry,
        })}
      </ParagraphSmall>
    </BaseSectionWithTitle>
  );
};
