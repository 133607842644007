import React from 'react';

import { backgroundMain } from '../../constants/colors';

export const DotIcon = ({ radius = 10, color = backgroundMain, style }) => {
  return (
    <div
      style={{
        ...style,
        width: radius,
        height: radius,
        borderRadius: radius / 2,
        backgroundColor: color,
        minWidth: radius,
        minHeight: radius,
      }}
    />
  );
};
