import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t } from '@formue-app/core';

import { accent } from '../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_24,
} from '../../constants/spacing';
import { DesktopWrapper, mobileCondition } from '../../constants/media';

import { GridElement } from '../layout';
import { H2Small } from '../texts';
import { ButtonSmall } from '../buttons';

import welcomeImageUrl from '../../assets/images/annual-review/annual-review-summary.jpg';

const StyledGridElement = styled(GridElement)`
  background: ${accent.raspberry250};
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Content = styled.div`
  flex: 2;
  padding: ${SPACING_48};

  @media ${mobileCondition} {
    padding: ${SPACING_24};
  }
`;

const Text = styled.div`
  width: 100%;
  max-width: 400px;
`;

const Image = styled.img`
  width: 31.9%;
  flex: 1;
  object-fit: cover;
`;

export const AnnualReviewSummary = (props) => {
  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);
  const isEmployee = useSelector((state) => state.auth.isEmployee);

  // Dont show summary unless its an employee and they are impersonating
  if (!isEmployee && !isImpersonating) {
    return null;
  }

  return (
    <StyledGridElement columns={12}>
      <Wrapper>
        <Content>
          <Text>
            <H2Small
              style={{
                color: accent.raspberry4,
                marginBottom: SPACING_24,
              }}
            >
              {t('presentation:annualReview:summary:title')}
            </H2Small>

            <ButtonSmall
              buttoncolor={accent.raspberry480}
              buttoncolorhover={accent.raspberry4}
              buttoncoloractive={accent.raspberry1}
              to="advisor/presentation/annual-review"
            >
              {t('presentation:annualReview:summary:button')}
            </ButtonSmall>
          </Text>
        </Content>
        <DesktopWrapper>
          <Image src={welcomeImageUrl} />
        </DesktopWrapper>
      </Wrapper>
    </StyledGridElement>
  );
};
