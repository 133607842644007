import { createSelector } from 'reselect';

import { operatingCountrySelector } from '../../auth/selectors';

const esgById = (state) => state.entities.esgData.byId;
const esgAllIds = (state) => state.entities.esgData.allIds;

export const esgSelector = createSelector(
  esgAllIds,
  esgById,
  (allIds, byId) => {
    return byId[allIds[0]];
  }
);

export const esgDataSelector = createSelector(esgSelector, (esg) => {
  if (!esg) return null;
  return esg.esgData;
});

export const esgIndexSelector = createSelector(
  esgSelector,
  operatingCountrySelector,
  (esg, operatingCountry) => {
    if (!esg || !esg.esgIndex) {
      return null;
    }

    let localName;
    switch (operatingCountry) {
      case 'se': {
        localName = 'Stockholmsbörsen Benchmark Index';
        break;
      }
      case 'no': {
        localName = 'Oslo Børs Fondsindeks';
        break;
      }
      case 'dk': {
        // TODO:dk Add esg index handling for Denmark
        return null;
      }
      default: {
        throw new Error(
          `ESG Index for country "${operatingCountry}" is not implemented`
        );
      }
    }
    const worldName = 'MSCI All Country World Index';

    const localIndex = esg.esgIndex.filter((item) => item.name === localName);
    const worldIndex = esg.esgIndex.filter((item) => item.name === worldName);
    return {
      local: localIndex[0],
      world: worldIndex[0],
    };
  }
);

export const esgMetricsSelector = createSelector(esgSelector, (esg) => {
  if (!esg) return null;
  return esg.esgMetrics;
});

export const esgMsciSelector = createSelector(esgSelector, (esg) => {
  if (!esg) return null;
  return esg.esgMsci;
});

export const esgRatingSelector = createSelector(esgSelector, (esg) => {
  if (!esg) return null;
  return esg.esgRating;
});
