import React from 'react';
import styled from 'styled-components';

import {
  paragraphSmallSize,
  paragraphSmallLineHeight,
} from '../../constants/text';

export const StyledParagraphSmall = styled.p`
  font-size: ${paragraphSmallSize};
  line-height: ${paragraphSmallLineHeight};
  font-weight: 400;
`;

export const ParagraphSmall = (props) => {
  const { children } = props;

  return <StyledParagraphSmall {...props}>{children}</StyledParagraphSmall>;
};
