import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import { Paragraph, H3 } from '../../../../texts';
import { primary, accent } from '../../../../../constants/colors';
import { SPACING_16, SPACING_2 } from '../../../../../constants/spacing';

const StyledHeading = styled(H3)`
  color: ${accent.ocean1};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${SPACING_2} 0;
`;

const StyledDivider = styled.div`
  width: 50px;
  height: 1px;
  background-color: ${primary};
  margin: 0 ${SPACING_16};
`;

export const EsgExplained = (props) => {
  return (
    <div {...props}>
      <StyledRow>
        <StyledHeading>E</StyledHeading>
        <StyledDivider />
        <Paragraph>{t('esg:esgScoreReadMore:environmental')}</Paragraph>
      </StyledRow>
      <StyledRow>
        <StyledHeading>S</StyledHeading>
        <StyledDivider />
        <Paragraph>{t('esg:esgScoreReadMore:social')}</Paragraph>
      </StyledRow>
      <StyledRow>
        <StyledHeading>G</StyledHeading>
        <StyledDivider />
        <Paragraph>{t('esg:esgScoreReadMore:governance')}</Paragraph>
      </StyledRow>
    </div>
  );
};
