import createFlags from 'flag';
import { createReduxBindings } from 'flag/redux';
import { isEmpty } from 'lodash';

/**
 * Define default flags here, these may be overwritten by dispatching the setFlagsAction
 * and passing flags in the payload. Which we can use to load flags config from the API
 * when needed. Features can also be computed properties, just pass in a function instead
 * of a boolean, number or string.
 *
 * https://github.com/garbles/flag
 */
const defaultFlags = {
  features: {},
  isLead: false,
  tester: flags => !isEmpty(flags.features) && !flags.isLead,
  showFlagSettings: flags => flags.tester,
};

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags({});
const {
  setFlagsAction,
  getFlagsSelector,
  createFlagsReducer,
  ConnectedFlagsProvider,
} = createReduxBindings(FlagsProvider);

const reducer = createFlagsReducer(defaultFlags);

export {
  reducer,
  Flag,
  useFlag,
  useFlags,
  setFlagsAction,
  getFlagsSelector,
  createFlagsReducer,
  ConnectedFlagsProvider,
};
