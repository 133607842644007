import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';

import { List } from '../../common/List';
import { GridElement, MainGrid } from '../../layout';
import { Paragraph } from '../../texts';

const StyledContainer = styled(MainGrid)`
  padding: 0;
  margin-top: ${SPACING_24};
`;

const Line = styled(GridElement)`
  margin: ${SPACING_8} 0;
  background-color: ${accent.sand370};
  height: 1px;
`;

export const ProductCostContent = (props) => {
  const items = [
    {
      title: t('portfolio:costReport:productCost:list:title1'),
      content: t('portfolio:costReport:productCost:list:body1'),
    },
    {
      title: t('portfolio:costReport:productCost:list:title2'),
      content: t('portfolio:costReport:productCost:list:body2'),
    },
  ];

  return (
    <StyledContainer>
      <GridElement columns={8}>
        <Paragraph>{t('portfolio:costReport:productCost:intro')}</Paragraph>
      </GridElement>
      <Line columns={12} />
      <GridElement columns={8}>
        <List style={{ margin: 0 }} items={items} />
      </GridElement>
    </StyledContainer>
  );
};
