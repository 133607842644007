import React from 'react';
import styled from 'styled-components';

import { EsgLevel } from './EsgLevel';
import { localizeFloat, getEsgLevelInterval, t } from '@formue-app/core';

import {
  accent,
  uiAffirmative,
  uiNegative,
} from '../../../../../constants/colors';
import { MainGrid } from '../../../../layout';
import { SpriteIcon } from '../../../../common/SpriteIcon';
import { SPACING_32 } from '../../../../../constants/spacing';
import { SpriteIconFunctional } from '../../../../common/SpriteIconFunctional';

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

export const EsgLevels = () => {
  const leaderInterval = getEsgLevelInterval('leader');
  const averageInterval = getEsgLevelInterval('average');
  const behindInterval = getEsgLevelInterval('behind');

  return (
    <StyledSubGrid>
      <EsgLevel
        color={accent.moss4}
        labelColor={accent.moss2}
        type="leader"
        value={`${localizeFloat(leaderInterval[0])} - ${localizeFloat(
          leaderInterval[1]
        )}`}
        title={t('esg:esgScore:types:leader')}
        text={t('esg:esgScoreReadMore:leader')}
        icon={
          <SpriteIconFunctional
            id="Pointer arrow"
            direction="Up"
            color={uiAffirmative}
            size={SPACING_32}
          />
        }
        columns={4}
      />
      <EsgLevel
        color={accent.ocean4}
        labelColor={accent.ocean2}
        type="average"
        value={`${localizeFloat(averageInterval[0])} - ${localizeFloat(
          averageInterval[1]
        )}`}
        title={t('esg:esgScore:types:average')}
        text={t('esg:esgScoreReadMore:average')}
        icon={<SpriteIcon id="Liquidity" inverted={true} size={SPACING_32} />}
        columns={4}
      />
      <EsgLevel
        color={accent.raspberry4}
        labelColor={accent.raspberry2}
        type="behind"
        value={`${localizeFloat(behindInterval[0])} - ${localizeFloat(
          behindInterval[1]
        )}`}
        title={t('esg:esgScore:types:behind')}
        text={t('esg:esgScoreReadMore:behind')}
        icon={
          <SpriteIconFunctional
            id="Pointer arrow"
            direction="Down"
            color={uiNegative}
            size={SPACING_32}
          />
        }
        columns={4}
      />
    </StyledSubGrid>
  );
};
