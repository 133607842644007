import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { t } from '@formue-app/core';
import { EsgCoveragePyramid } from './esg/modals/EsgCoveragePyramid';
import { H2, Paragraph } from '../../texts';
import { MainGrid } from '../../layout';
import { BaseSection } from '../../common/BaseSection';
import {
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledBaseSection = styled(BaseSection)`
  padding: 0;
`;

const Tip = styled(Paragraph)`
  font-style: italic;
  padding-bottom: ${SPACING_32};
`;

export const EsgCoverageReadMore = (props) => {
  return (
    <StyledSubGrid>
      <StyledBaseSection
        columns={7}
        style={{ padding: SPACING_24, paddingBottom: 0, paddingLeft: 0 }}
      >
        <H2 style={{ marginBottom: SPACING_16, marginTop: SPACING_24 }}>
          {t('esg:coverageReadMore:title')}
        </H2>
        <Tip>
          <Trans
            i18nKey="esg:coverageReadMore:tip"
            components={{
              strong: (
                <span
                  style={{
                    fontWeight: 700,
                  }}
                />
              ),
            }}
          />
        </Tip>
        <Paragraph style={{ fontWeight: 500, marginBottom: SPACING_8 }}>
          {t('esg:coverageReadMore:subTitle1')}
        </Paragraph>
        <Paragraph style={{ marginBottom: SPACING_24 }}>
          {t('esg:coverageReadMore:esgParagraph1')}
        </Paragraph>
        <Paragraph style={{ fontWeight: 500, marginBottom: SPACING_8 }}>
          {t('esg:coverageReadMore:subTitle2')}
        </Paragraph>
        <Paragraph style={{ marginBottom: SPACING_32 }}>
          {t('esg:coverageReadMore:esgParagraph2')}
        </Paragraph>
      </StyledBaseSection>
      <StyledBaseSection
        columns={5}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <EsgCoveragePyramid />
      </StyledBaseSection>
    </StyledSubGrid>
  );
};
