import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { t, reduceFundsStabilityData, selectors } from '@formue-app/core';
import { notDesktopCondition } from '../../../../constants/media';
import { BaseSection } from '../../../common/BaseSection';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  BORDER_RADIUS_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_2,
  SPACING_20,
  SPACING_24,
  SPACING_32,
  SPACING_4,
  SPACING_8,
} from '../../../../constants/spacing';
import { ModalSection } from './ModalSection';
import {
  H1,
  H2,
  H2Small,
  Paragraph,
  ParagraphLarge,
  ParagraphSmall,
  ParagraphXSmall,
} from '../../../texts';
import { BarChart } from '../../../graphs/BarChart';
import { accent, backgroundWhite } from '../../../../constants/colors';
import { percentage } from '@formue-app/core/src/services/localization';
import { Coverage } from './Coverage';

const i18nPrefix = 'sustainability:fundClassification';

const {
  entities: {
    portfolioLenses: { fundSustainabilityDataSelector },
  },
} = selectors;

const StyledWrapper = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${SPACING_32} 0 ${SPACING_16} 0;
  flex-flow: column nowrap;
  gap: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  @media ${notDesktopCondition} {
    flex-flow: column;
    padding: 0;
  }
`;

const SectionTitle = styled(H2Small)`
  padding-bottom: ${SPACING_8};
`;

const ChartWrapper = styled.div`
  display: flex;
  height: 350px;
  margin-bottom: 60px;
`;

const SectionSelector = styled.div`
  display: flex;
  gap: ${SPACING_8};
  position: absolute;
  top: ${SPACING_32};
  right: ${SPACING_32};
`;

const SectionSelectorItem = styled.div`
  display: flex;
  gap: ${SPACING_8};
  border: 1px solid ${accent.ocean420};
  padding: 7px ${SPACING_16};
  border-radius: 36px;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      border-color: ${accent.ocean2};
      background-color: ${accent.ocean230};
    `}
`;

const InnerSection = styled.div`
  padding: ${SPACING_16} ${SPACING_24};
  border-radius: 32px;
  background-color: ${accent.sand1};
  margin-top: ${SPACING_16};

  > H2 {
    margin-bottom: ${SPACING_8};
  }
`;

const Table = styled.div`
  padding-top: ${SPACING_20};
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING_8} ${SPACING_16};
  border-radius: ${BORDER_RADIUS_LARGE};
  &:nth-child(2n) {
    background-color: ${backgroundWhite};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: baseline;
  > p {
    font-size: 16px;
    margin-left: ${SPACING_4};
    font-weight: 600;
  }
`;

export const FundClassificationModalContent = (props) => {
  const fundSustainabilityData = useSelector(fundSustainabilityDataSelector);

  const [activeSection, setActiveSection] = useState('article9');

  if (!fundSustainabilityData) return null;

  const SECTIONS = ['article9', 'article8', 'article6', 'notClassified'];

  const {
    article9Ratio,
    article8Ratio,
    article6Ratio,
    notClassifiedRatio,
    articleRatioCoverage,
  } = fundSustainabilityData;

  const chartData = [
    { value: article9Ratio, label: t(`${i18nPrefix}:article9`) },
    { value: article8Ratio, label: t(`${i18nPrefix}:article8`) },
    { value: article6Ratio, label: t(`${i18nPrefix}:article6`) },
    {
      value: notClassifiedRatio,
      label: t(`${i18nPrefix}:notClassified`),
    },
  ];

  const contributingFunds =
    fundSustainabilityData[`${activeSection}ContributingFunds`];

  const totalNumberOfContributingFunds = [
    ...fundSustainabilityData.article6ContributingFunds,
    ...fundSustainabilityData.article8ContributingFunds,
    ...fundSustainabilityData.article9ContributingFunds,
  ].length;

  return (
    <StyledWrapper>
      <ModalSection highlighted maxWidth={1000}>
        <SectionTitle style={{ paddingBottom: SPACING_8 }}>
          {t(`${i18nPrefix}:sectionTitle`)}
        </SectionTitle>
        <Paragraph style={{ whiteSpace: 'pre-line' }}>
          {t(`${i18nPrefix}:sectionSubtitle`)}
        </Paragraph>
        <ChartWrapper>
          <BarChart
            data={chartData}
            valueKey="value"
            labelKey="label"
            zeroAxis={true}
            barSpacing={80}
            yAxis={false}
            colorScheme={[
              accent.forest1,
              accent.forest2,
              accent.sand4,
              accent.sand2,
            ]}
            valueFormatFunction={(value) => percentage(value * 100)}
            labelComponent={({ label, index }) => (
              <div>
                <Paragraph style={{ fontWeight: 500 }}>{label}</Paragraph>
                {index === 0 && (
                  <ParagraphSmall>
                    {t(`${i18nPrefix}:setAsMainGoal`)}
                  </ParagraphSmall>
                )}
                {index === 1 && (
                  <ParagraphSmall>
                    {t(`${i18nPrefix}:setAsPartialGoal`)}
                  </ParagraphSmall>
                )}
                {index === 2 && (
                  <ParagraphSmall>
                    {t(`${i18nPrefix}:noSetGoal`)}
                  </ParagraphSmall>
                )}
              </div>
            )}
          />
        </ChartWrapper>
      </ModalSection>

      <ModalSection
        highlighted
        maxWidth={1000}
        style={{ position: 'relative' }}
      >
        <SectionTitle style={{ paddingBottom: SPACING_16 }}>
          {t(`${i18nPrefix}:fundCategorization:title`)}
        </SectionTitle>
        <SectionSelector>
          {SECTIONS.map((key) => (
            <SectionSelectorItem
              selected={key === activeSection}
              onClick={() => {
                setActiveSection(key);
              }}
            >
              <ParagraphSmall style={{ fontWeight: 500 }}>
                {t(`${i18nPrefix}:${key}`)}
              </ParagraphSmall>
            </SectionSelectorItem>
          ))}
        </SectionSelector>
        <InnerSection>
          <TextWrapper>
            <H2>
              {percentage(
                fundSustainabilityData[`${activeSection}Ratio`] * 100,
                1,
                false,
                false
              )}
            </H2>
            <ParagraphLarge>
              ({totalNumberOfContributingFunds} st)
            </ParagraphLarge>
          </TextWrapper>
          <Paragraph>
            {t(`${i18nPrefix}:fundCategorization:${activeSection}`)}
          </Paragraph>
        </InnerSection>
        {contributingFunds.length ? (
          <Table>
            <TableRow>
              <ParagraphXSmall>{t('portfolio:funds:fund')}</ParagraphXSmall>
              <ParagraphXSmall>{t('portfolio:unit')}</ParagraphXSmall>
            </TableRow>
            {contributingFunds
              .sort((a, b) => b.weight - a.weight)
              .map((fund) => (
                <TableRow>
                  <ParagraphSmall style={{ fontWeight: 500 }}>
                    {fund.name}
                  </ParagraphSmall>
                  <ParagraphSmall>
                    {percentage(fund.weight * 100)}
                  </ParagraphSmall>
                </TableRow>
              ))}
          </Table>
        ) : null}
      </ModalSection>

      <ModalSection highlighted>
        <SectionTitle style={{ paddingBottom: SPACING_16 }}>
          {t(`${i18nPrefix}:howIsFundSustainabilityAssessed`)}
        </SectionTitle>
        <Paragraph style={{ whiteSpace: 'pre-line' }}>
          {t(`${i18nPrefix}:howIsFundSustainabilityAssessedText`)}
        </Paragraph>
      </ModalSection>
      <Coverage coverage={articleRatioCoverage} />
    </StyledWrapper>
  );
};
