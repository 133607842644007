import { formatMoney } from '@formue-app/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { easeInOut, longAnimationTime } from '../../constants/animation';

import { textLightest } from '../../constants/colors';
import { mobileCondition } from '../../constants/media';
import { BORDER_RADIUS_LARGE, SPACING_16 } from '../../constants/spacing';
import { H5LineHeight, H5Size } from '../../constants/text';
import { H3, H6 } from '../texts';

const WaterfallWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  padding-top: 40px;
  margin-right: -38px;

  @media ${mobileCondition} {
    margin-right: -23px;
  }
`;

const BarWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
`;

const BarContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;

  ${(props) =>
    !props.lastItem &&
    css`
      &:after {
        content: '';
        display: block;
        top: ${(props) => 100 - (props.prevHeight + props.height)}%;
        position: relative;
        height: 2px;
        background: #4c68a0;
        width: 30px;
        margin: 0 4px;
        align-self: flex-start;
        border-radius: 1px;
        transition: all ${longAnimationTime} ${easeInOut};

        @media ${mobileCondition} {
          width: 15px;
        }
      }
    `}
`;

const StyledBar = styled.div`
  width: calc(100% - 30px - 8px);
  height: ${(props) => Math.abs(props.height)}%;
  background: ${(props) => props.color};
  position: relative;
  bottom: ${(props) =>
    props.height < 0
      ? props.prevHeight - Math.abs(props.height)
      : props.prevHeight}%;
  border-radius: ${BORDER_RADIUS_LARGE};

  transition: all ${longAnimationTime} ${easeInOut};

  @media ${mobileCondition} {
    width: calc(100% - 15px - 8px);
  }
`;

const Title = styled(H3)`
  color: ${textLightest};
  position: absolute;
  top: calc(
    ${(props) =>
        props.height < 0
          ? 100 - (props.prevHeight - Math.abs(props.height) - props.height)
          : 100 - (props.prevHeight + props.height)}% - ${SPACING_16}
  );
  width: 100%;
  margin-left: calc(-38px / 2);
  transform: translateY(-100%);
  transition: all ${longAnimationTime} ${easeInOut};

  @media ${mobileCondition} {
    font-size: ${H5Size};
    line-height: ${H5LineHeight};
    margin-left: calc(-23px / 2);
  }
`;

const Label = styled(H6)`
  width: 100%;
  margin-left: calc(-38px / 2);
  color: ${textLightest};
  margin-top: ${SPACING_16};
  word-break: break-word;

  @media ${mobileCondition} {
    margin-left: calc(-23px / 2);
  }
`;

const Bar = (props) => {
  const { label, max, value = 0, color, index, data, lastItem } = props;

  let prevHeight = 0;

  for (let i = 0; i < index; i++) {
    prevHeight += (data[i].value / max) * 100 || 0;
  }

  let height = (value / max) * 100 || 0;

  return (
    <BarWrapper lastItem={lastItem}>
      <BarContainer height={height} prevHeight={prevHeight} lastItem={lastItem}>
        <Title height={height} prevHeight={prevHeight}>
          {formatMoney(value)}
        </Title>
        <StyledBar
          height={height}
          prevHeight={prevHeight}
          color={color}
          lastItem={lastItem}
        />
      </BarContainer>
      <Label>{label}</Label>
    </BarWrapper>
  );
};

export const WaterfallChart = (props) => {
  const { total, data } = props;

  const max = Math.max(
    ...[total.value, ...data.map((item) => item.value || 0)]
  );

  return (
    <WaterfallWrapper>
      {data.map((item, index) => (
        <Bar
          {...item}
          max={max}
          data={data}
          index={index}
          key={`bar-id-${index}`}
          firstItem={index === 0}
        />
      ))}
      <Bar {...total} max={max} lastItem={true} />
    </WaterfallWrapper>
  );
};
