import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import { H6 } from '../../../../texts';
import { ReactComponent as CoveragePyramidImage } from '../../../../../assets/portfolio/esg-coverage-pyramid.svg';
import { SPACING_24, SPACING_16 } from '../../../../../constants/spacing';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${SPACING_16} 0;
`;

const StyledContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
`;

export const EsgCoveragePyramid = () => {
  return (
    <StyledContainer>
      <StyledContent>
        <H6>
          {t('esg:coverageReadMore:pyramid:alternativeGrowth').toUpperCase()} *
        </H6>
        <H6>{t('esg:coverageReadMore:pyramid:marketGrowth').toUpperCase()}</H6>
        <H6>{t('esg:coverageReadMore:pyramid:stability').toUpperCase()}</H6>
      </StyledContent>
      <CoveragePyramidImage
        width={244}
        height={207}
        style={{ marginRight: SPACING_24 }}
      />
    </StyledContainer>
  );
};
