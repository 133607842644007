import { createActions } from 'redux-actions';

export const actions = createActions({
  PORTFOLIO: {
    INIT: (ssid) => ssid,
    INITIALIZED: () => {},
    LOAD: () => {},
    FLUSH: () => {},
    FILTER_PERIOD: (period) => period,
    FILTER_SSIDS: (ssids) => ssids,
    FILTER_EXTERNAL_REPORTING: (payload) => payload,
    FILTER_TAX_CLASS: (payload) => payload,
    FILTER_INVESTMENT_ACCOUNT: (payload) => payload,
    DEFAULT_FILTERS_LOAD: () => {},
    DEFAULT_FILTERS_LOADED: (payload) => payload,
    DEFAULT_FILTERS_SAVE: () => {},
    DEFAULT_FILTERS_DELETE: () => {},
    STORE_PORTFOLIO_TIMESTAMPS: (payload) => payload,
    ACTIVE_STRATEGY: (payload) => payload,
  },
}).portfolio;
