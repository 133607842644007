import React from 'react';
import styled from 'styled-components';

import {
  BORDER_RADIUS_SMALL,
  SPACING_8,
} from '../../../../../constants/spacing';
import { H5 } from '../../../../texts';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  border-radius: ${BORDER_RADIUS_SMALL};
  margin-top: ${SPACING_8};
`;

const StyledIcon = styled(H5)`
  border-radius: ${BORDER_RADIUS_SMALL};
  padding: 3px 5px;
  margin-right: 5px;
  line-height: 1;
`;

export const EsgTag = ({ esgValue, color = '#c7e3ff' }) => {
  return (
    <StyledContainer>
      <StyledIcon style={{ backgroundColor: color }}>ESG</StyledIcon>
      <H5>{esgValue}</H5>
    </StyledContainer>
  );
};
