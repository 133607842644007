import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, t, localizeFloat, useResource } from '@formue-app/core';

import { backgroundMain } from '../../constants/colors';
import { SPACING_48, SPACING_16, SPACING_8 } from '../../constants/spacing';
import { accent } from '../../constants/colors';
import { mobileCondition } from '../../constants/media';
import { numbersFontStack } from '../../fonts';

import { Modal } from '../../components/common/Modal';
import { GridElement } from '../../components/layout/GridElement';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { HeaderWithBackgroundImage } from '../../components/common/HeaderWithBackgroundImage';
import { ParagraphSmall, H1, H2 } from '../../components/texts';
import { Benchmark } from '../../components/portfolio/lenses/esg/Benchmark';
import { CarbonFootprint } from '../../components/portfolio/lenses/esg/CarbonFootprint';
import { Score } from '../../components/portfolio/lenses/esg/Score';
import { Trend } from '../../components/portfolio/lenses/esg/Trend';
import { CompanyPromise } from '../../components/portfolio/lenses/esg/CompanyPromise';
import { EsgNewsfeed } from '../../components/portfolio/lenses/esg/EsgNewsfeed';
import { EsgFooterSection } from '../../components/portfolio/lenses/esg/EsgFooterSection';
import { EsgScoreReadMore } from '../../components/portfolio/lenses/esg/modals/EsgScoreReadMore';
import { EsgCoverageReadMore } from '../../components/portfolio/lenses/EsgCoverageReadMore';
import { CarbonFootprintReadMore } from '../../components/portfolio/lenses/esg/modals/CarbonFootprintReadMore';
import { SpriteIcon } from '../../components/common/SpriteIcon';

import backgroundImage from '../../assets/portfolio/esg-header-background.jpg';

const {
  entities: {
    esgData: { esgDataSelector },
  },
} = selectors;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

const StyledHeaderWithBackgroundImage = styled(HeaderWithBackgroundImage)`
  @media ${mobileCondition} {
    border-radius: 0;
  }
`;

const StyledValueHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: ${SPACING_8};
  padding-top: ${SPACING_16};

  @media ${mobileCondition} {
    flex-wrap: wrap;
  }
`;

const StyledValue = styled(H1)`
  font-family: ${numbersFontStack};
  font-weight: 500;
  color: ${accent.moss2};
  font-size: 60px;
  line-height: 0.78;
  @media ${mobileCondition} {
    padding-top: ${SPACING_16};
  }
`;

const StyledValueLabel = styled(ParagraphSmall)`
  color: ${accent.moss2};
  white-space: pre-wrap;

  @media ${mobileCondition} {
    white-space: normal;
    margin-top: ${SPACING_8};
  }
`;

const StyledCarbonFootprint = styled(CarbonFootprint)`
  @media ${mobileCondition} {
    margin-top: -${SPACING_16};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const EsgPage = () => {
  const loading = useResource(['ESG_DATA/INDEX', 'ARTICLES/INDEX']);
  const esgData = useSelector(esgDataSelector);
  const [modal, setModal] = useState('');

  if (!esgData) return null;

  if (loading) {
    return (
      <GridElement columns={12}>
        <StyledCenteredActivityIndicator
          columns={12}
          size={30}
          background={backgroundMain}
        />
      </GridElement>
    );
  }

  return (
    <>
      <StyledHeaderWithBackgroundImage
        columns={12}
        title={t('esg:title')}
        subtitle={t('esg:carbonFootprint:introText')}
        subtitleColor={accent.moss2}
        backgroundImage={backgroundImage}
      >
        <StyledValueHolder>
          <SpriteIcon id="Preserve and Develop" inverted={true} size={43} />
          <StyledValue>
            {localizeFloat(esgData.weightedAverageCarbonIntensity)}
          </StyledValue>
          <StyledValueLabel>
            {t('esg:carbonFootprint:subtitle')}
          </StyledValueLabel>
        </StyledValueHolder>
      </StyledHeaderWithBackgroundImage>

      <StyledCarbonFootprint
        columns={6}
        onClick={() => {
          setModal('carbonFootprint');
        }}
      />
      <Score
        columns={6}
        onClick={() => {
          setModal('esgScore');
        }}
      />
      <Benchmark columns={6} />
      <Trend columns={6} />
      <CompanyPromise columns={12} />
      <EsgNewsfeed columns={12} />
      <EsgFooterSection
        onClick={() => {
          setModal('coverage');
        }}
      />
      <Modal
        isOpen={modal.length ? true : false}
        onRequestClose={() => setModal('')}
        padding={SPACING_48}
        labelComponent={H2}
      >
        {modal === 'esgScore' && <EsgScoreReadMore />}
        {modal === 'coverage' && <EsgCoverageReadMore />}
        {modal === 'carbonFootprint' && <CarbonFootprintReadMore />}
      </Modal>
    </>
  );
};
