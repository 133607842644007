import React from 'react';
import styled from 'styled-components';

import {
  selectors,
  formatHistoricalPerformanceChartData,
  t,
  percentage,
} from '@formue-app/core';

import { H4, ParagraphSmall } from '../texts';
import { BaseSection } from '../common/BaseSection';
import { SPACING_12, SPACING_16, SPACING_8 } from '../../constants/spacing';
import { useSelector } from 'react-redux';
import { InvestmentStrategyAreaChart } from './charts/InvestmentStrategyAreaChart';
import { accent } from '../../constants/colors';

const Container = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div`
  flex: 1;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
`;

const TitleWrapper = styled(ValueWrapper)`
  gap: ${SPACING_12};
  padding-bottom: ${SPACING_16};
  justify-content: space-between;
`;

const Separator = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${accent.sand150};
`;

const Row = styled.div`
  display: flex;
  flex: 1;
`;

const {
  entities: {
    investmentStrategyIndexChanges: {
      investmentStrategyIndexChangesByIdSelector,
      investmentStrategyIndexChangesMetadataByIdSelector,
    },
  },
} = selectors;

export const HistoricalPerformanceChart = (props) => {
  const { strategyId } = props;
  const investmentStrategyIndexChangesByIds = useSelector(
    investmentStrategyIndexChangesByIdSelector(strategyId)
  );
  const indexChangesMetadata = useSelector(
    investmentStrategyIndexChangesMetadataByIdSelector(strategyId)
  );

  const { positiveYears, highestYearly } =
    indexChangesMetadata.historicalPerformance;

  return (
    <Container columns={6}>
      <Wrapper>
        <TitleWrapper>
          <H4>
            {t('presentation:investmentStrategy:historicalPerformance:title')}
          </H4>
          <ValueWrapper style={{ gap: SPACING_12 }}>
            <ValueWrapper>
              <ParagraphSmall>
                {t('advisor:strategySimulator:preferences:positiveYears')}
              </ParagraphSmall>
              <ParagraphSmall style={{ fontWeight: 500 }}>
                {positiveYears.length}
              </ParagraphSmall>
            </ValueWrapper>
            <Separator />
            <ValueWrapper>
              <ParagraphSmall>
                {t('wealthPlan:investmentStrategy:highestYearly')}
              </ParagraphSmall>
              <ParagraphSmall style={{ fontWeight: 500 }}>
                {percentage(highestYearly * 100, 1, false, false)}
              </ParagraphSmall>
            </ValueWrapper>
          </ValueWrapper>
        </TitleWrapper>
        <Row>
          <InvestmentStrategyAreaChart
            valueKey="change"
            formatLineData={(data) =>
              formatHistoricalPerformanceChartData(data)
            }
            chartData={investmentStrategyIndexChangesByIds}
            domainPadding={{ x: [40, 0], y: [50, 30] }}
          />
        </Row>
      </Wrapper>
    </Container>
  );
};
