import { stringify } from 'query-string';
import { apiFetch } from './utils/api';

/**
 * @desc Chart data fetch
 *
 * @param {(number|number[])} id - ID of the user we're trying to fetch the data for. Array of id's
 * supported.
 *
 * @param {number} [period=12] -  THISMONTH, THISYEAR, TWELVEMONTHS, THREEYEARS, FIVEYEARS, TENYEARS or
 * SINCESTART
 *
 * @return json - data or error message
 */

export const esgData = {
  get: async (args) => {
    const query = stringify(args, { arrayFormat: 'comma' });

    const options = {
      method: 'GET',
    };

    const url = `/v1/qlik/esg-data?${query}`;

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
