import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import {
  selectors,
  localizeFloat,
  getFlagsSelector,
  numberWithSpaces,
} from '@formue-app/core';
import { H3 } from '../../../texts';
import { accent } from '../../../../constants/colors';

const {
  entities: {
    esgData: { esgDataSelector },
    portfolioLenses: { carbonFootprintDataSelector },
  },
} = selectors;

export const SustainabilityText = ({
  fontSize = 24,
  lineHeight = 32,
  style,
}) => {
  const esgData = useSelector(esgDataSelector);
  const carbonFootprintData = useSelector(carbonFootprintDataSelector);

  const flags = useSelector(getFlagsSelector);

  const { features } = flags;

  let value = esgData ? esgData.weightedAverageCarbonIntensity : '-';

  if (features?.portfolioLab && carbonFootprintData) {
    value = carbonFootprintData.carbonFootprint;
  }

  if (typeof value === 'number') {
    value = numberWithSpaces(localizeFloat(value?.toFixed(0), 0));
  }

  return (
    <div style={style}>
      <H3
        style={{
          color: accent.forest250,
          fontSize,
          lineHeight: `${lineHeight}px`,
        }}
      >
        <Trans
          i18nKey="sustainability:entryText"
          values={{
            value: value,
          }}
          components={{
            strong: <span style={{ color: accent.forest3, fontWeight: 500 }} />,
          }}
        />
      </H3>
    </div>
  );
};
