import React from 'react';
import styled from 'styled-components';

import { t, useValuesForCountry } from '@formue-app/core';
import { BaseSection } from '../../../common/BaseSection';
import { H2, Paragraph, H4 } from '../../../texts';
import { textLink } from '../../../../constants/colors';
import { ReactComponent as CompanyPromiseLogo } from '../../../../assets/portfolio/company-promise.svg';
import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
} from '../../../../constants/spacing';

const StyledContainer = styled(BaseSection)`
  text-align: center;
  background: transparent;
  padding: ${SPACING_48} 0;
`;

const StyledH4 = styled(H4)`
  color: ${textLink};
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

export const CompanyPromise = ({ ...props }) => {
  const esgLinks = {
    se: {
      esgLink: 'https://formue.se/hallbarhet',
    },
    no: {
      esgLink: 'https://formue.no/formue-baerekraft',
    },
    dk: {
      esgLink: 'https://formue.dk/baeredygtighedsrelaterede-oplysninger/',
    },
  };
  const { esgLink } = useValuesForCountry(esgLinks);

  return (
    <StyledContainer {...props}>
      <CompanyPromiseLogo />
      <H2 style={{ paddingTop: SPACING_24, paddingBottom: SPACING_24 }}>
        {t('esg:companyPromise:title')}
      </H2>
      <Paragraph>{t('esg:companyPromise:text')}</Paragraph>
      <StyledH4 style={{ marginTop: SPACING_16 }}>
        <StyledLink href={esgLink} target="_blank">
          {t('esg:companyPromise:linkText')}
        </StyledLink>
      </StyledH4>
    </StyledContainer>
  );
};
