import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { H2, ParagraphSmall } from '../../texts';
import { LensesNavigationItem } from './LensesNavigationItem';
import { SpriteIcon } from '../../common/SpriteIcon';

import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_8,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../constants/spacing';
import {
  desktopCondition,
  mobileCondition,
  notDesktopCondition,
  tabletCondition,
} from '../../../constants/media';
import { accent } from '../../../constants/colors';

const iconComponent = (type, active, size = 32) => {
  switch (type) {
    case 'sustainability':
      return <SpriteIcon id="Sustainability" inverted={active} size={size} />;
    case 'geography':
      return <SpriteIcon id="Position" inverted={active} size={size} />;
    case 'sectors':
      return (
        <SpriteIcon
          id="Company"
          inverted={active}
          color={accent.raspberry3}
          accentColor={accent.raspberry190}
          tertiaryColor={accent.raspberry480}
          size={size}
        />
      );
    case 'portfolio-construction':
      return <SpriteIcon id="Portfolio" inverted={active} size={size} />;

    default:
      return null;
  }
};

const StyledContainer = styled(BaseSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${SPACING_48} 40px ${SPACING_48} ${SPACING_48};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  @media ${notDesktopCondition} {
    flex-direction: column;
  }

  @media ${mobileCondition} {
    background: transparent;
    padding: 0;
  }
`;

const StyledTextTitle = styled(H2)`
  margin-bottom: 12px;
  @media ${tabletCondition} {
    font-size: 22px;
  }
  @media ${mobileCondition} {
    font-size: 24px;
    margin-bottom: ${SPACING_16};
  }
`;

const StyledTextWrapper = styled.div`
  @media ${desktopCondition} {
    max-width: 330px;
    margin-right: ${SPACING_24};
  }
  @media ${tabletCondition} {
    text-align: center;
    margin-bottom: 24px;
  }

  @media ${mobileCondition} {
    margin-bottom: ${SPACING_48};
  }
`;

const StyledNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${SPACING_8};

  @media ${notDesktopCondition} {
    flex-wrap: wrap;
  }

  @media ${mobileCondition} {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const LensesNavigation = (props) => {
  const { routes = [] } = props;

  const isMobile = useMediaQuery({
    query: mobileCondition,
  });
  return (
    <StyledContainer columns={12}>
      <StyledTextWrapper>
        <StyledTextTitle>{t('portfolio:lenses')}</StyledTextTitle>
        <ParagraphSmall>{t('portfolio:lensesText')}</ParagraphSmall>
      </StyledTextWrapper>
      <StyledNavigationWrapper>
        {routes.map((route, index) => {
          const type = route.path.split('/').pop();
          const icon = iconComponent(
            type,
            window.location.pathname === route.path,
            isMobile ? 24 : undefined
          );
          const disabled = !route.hasOwnProperty('component');

          return (
            <LensesNavigationItem
              {...route}
              key={`item-${index}`}
              icon={icon}
              disabled={disabled}
            />
          );
        })}
      </StyledNavigationWrapper>
    </StyledContainer>
  );
};
