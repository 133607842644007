import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  accent,
  backgroundMain,
  blackTranslucent,
  whiteTranslucent,
} from '../../constants/colors';
import { notDesktopCondition } from '../../constants/media';
import { GridElement } from '../layout';
import { SubMenuItem } from './SubMenuItem';

const StyledSubMenu = styled.ul`
  display: flex;
  border-bottom: 1px solid ${accent.sand3};
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 5px !important;
  overflow-x: auto;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: -1px;
    height: 100%;
    width: 50px;
    background: linear-gradient(
      90deg,
      ${whiteTranslucent(0)} 0%,
      ${backgroundMain} 100%
    );
  }

  li:last-child {
    padding-right: 50px;
    margin-right: 0;
  }

  &::-webkit-scrollbar {
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    /* handle color */
    background: ${blackTranslucent(0.1)};
  }
  &::-webkit-scrollbar-track {
    /* background color */
    background: ${accent.sand2};
  }

  @media ${notDesktopCondition} {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const SubMenu = (props) => {
  const params = useParams();
  const { routes = [] } = props;

  return (
    <GridElement columns={12} style={{ position: 'relative' }}>
      <StyledSubMenu>
        {routes.map((route, index) => {
          let replacesRoutes = route.path;

          for (let key of Object.keys(params)) {
            replacesRoutes = replacesRoutes.replace(`:${key}`, params[key]);
          }

          return (
            <SubMenuItem
              {...route}
              path={replacesRoutes}
              key={`sub-menu-item-${index}`}
              exact={index === 0}
            />
          );
        })}
      </StyledSubMenu>
    </GridElement>
  );
};
