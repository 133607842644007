import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors } from '@formue-app/core';

import { SPACING_48, SPACING_32 } from '../../../constants/spacing';

import { ReportTitle } from '../reportComponent/ReportTitle';
import {
  accent,
  textLightest,
  whiteTranslucent,
} from '../../../constants/colors';
import { PortfolioStrategyLabels } from '../../portfolio/strategy/PortfolioStrategyLabels';
import { StrategyList } from '../../portfolio/strategy/PortfolioStrategyList';

import { PortfolioStrategyCircle } from '../slideComponents/portfolioConstruction/PortfolioStrategyCircle';
import { DesktopWrapper, mobileCondition } from '../../../constants/media';

const {
  entities: {
    strategy: { allStrategiesSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

const StyledPortfolioStrategyLabels = styled(PortfolioStrategyLabels)`
  justify-content: flex-end;
  margin-bottom: ${SPACING_48};
`;

const StyledStrategyList = styled(StrategyList)`
  @media ${mobileCondition} {
    & .strategy-item {
      background: ${whiteTranslucent(0.04)};
    }
  }
`;

export const PortfolioStrategySection = (props) => {
  const { backgroundColor, title, isVisible, ...rest } = props;

  const [activeStrategy, setActiveStrategy] = useState(null);
  const [numInside, setNumInside] = useState(0);
  const [numOutside, setNumOutside] = useState(0);
  const [openItems, setOpenItems] = useState([]);

  const allStrategies = useSelector(allStrategiesSelector);
  const activeStrategyId = useSelector(activeStrategySelector);

  useEffect(() => {
    setActiveStrategy(
      allStrategies.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [allStrategies, activeStrategyId]);

  useEffect(() => {
    let inside = 0;
    let outside = 0;

    if (activeStrategy) {
      for (const strategy of activeStrategy.strategy) {
        const max = strategy.upperLimit;
        const min = strategy.lowerLimit;
        const strategyShare = strategy.strategyShare;
        const market = strategy.marketShare;

        // If the strategy and market share is at 0 we dont want to count it towards the total
        // number of inside/outside assetclasses
        if (strategyShare === 0 && market === 0) {
          continue;
        }

        const width = max - min;
        const currentPosition = market === 0 ? 0 : 50 - strategyShare + market;
        const limitStart = 50 - (strategyShare - min);
        const limitEnd = limitStart + width;

        if (currentPosition >= limitStart && currentPosition <= limitEnd) {
          inside++;
        } else {
          outside++;
        }
      }

      setNumInside(inside);
      setNumOutside(outside);
    }
  }, [activeStrategy]);

  return (
    <>
      <ReportTitle
        {...rest}
        title={title}
        values={{ inside: numInside, outside: numOutside }}
        children={
          <PortfolioStrategyCircle
            numInside={numInside}
            isActiveSlide={isVisible}
            style={{ marginTop: -10, marginBottom: SPACING_32 }}
          />
        }
      />

      <div style={{ width: '100%', maxWidth: 800, marginTop: 100 }}>
        <DesktopWrapper>
          <StyledPortfolioStrategyLabels
            textColor={textLightest}
            strategyColor={accent.velvet3}
            light={true}
          />
        </DesktopWrapper>
        {activeStrategy ? (
          <StyledStrategyList
            strategy={activeStrategy.strategy}
            runAnimation={isVisible}
            customAnimationDelay={0}
            showOverviewDetails={false}
            textColor={textLightest}
            barColor="rgba(245,243,240, 0.1)"
            barInsideColor={accent.velvet3}
            barOutsideColor="#6D4594"
            light={true}
            setOpenItems={setOpenItems}
            openItems={openItems}
          />
        ) : null}
      </div>
    </>
  );
};
