import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  useResource,
  selectors,
  actions,
  t,
  sumAssetClassesByAssetGroup,
  toLocaleDateString,
} from '@formue-app/core';

import { MenuPage } from '../base/MenuPage';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { ParagraphSmall } from '../../components/texts';
import { GridElement, MainGrid } from '../../components/layout';
import { accent } from '../../constants/colors';
import { SPACING_120, SPACING_24 } from '../../constants/spacing';
import { InvestmentStrategyStickyNavigation } from '../../components/investmentStrategies/InvestmentStrategyStickyNavigation';
import { InvestmentStrategyContent } from '../../components/investmentStrategies/InvestmentStrategyContent';
import { InvestmentStrategySigningButton } from '../../components/investmentStrategies/InvestmentStrategySigningButton';

const {
  ui: { readStatus: readActions, signingEvents: signingEventsActions },
} = actions;

const PageContent = styled(MainGrid)`
  position: relative;
  top: -40px;
  width: 100%;
  max-width: 100%;
  padding: 16px 0 0 0;
  grid-column: span 12;
`;
const Header = styled(GridElement)``;

const Dot = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  background-color: ${accent.ocean490};
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
  padding-bottom: ${SPACING_24};
`;

const StyledMainGrid = styled(MainGrid)`
  width: 100%;
  max-width: 100%;
  padding: 0;
  grid-column: span 12;
`;

const {
  entities: {
    investmentStrategies: {
      investmentStrategiesById,
      investmentStrategiesSelector,
    },
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsMetadataByIdSelector,
      investmentStrategyAssetAllocationsByStrategyIdSelector,
      investmentStrategyAssetAllocationsSelector,
    },
    investmentStrategyIndexChanges: {
      investmentStrategyIndexChangesMetadataByIdSelector,
    },
    investmentStrategyProducts: {
      investmentStrategyProductsByStrategyIdSelector,
    },
    investmentStrategyCosts: {
      investmentStrategyCostsByStrategyIdSelector,
      investmentStrategyCostMetadataByIdSelector,
    },
    signingEvents: { openSelector: openSigningEventsSelector },
    investmentStrategyImplementationPlans: {
      investmentStrategyImplementationPlansByIdSelector,
    },
  },
} = selectors;

export const InvestmentStrategyPage = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const allInvestmentStrategies = useSelector(investmentStrategiesSelector);
  const investmentStrategies = useSelector(investmentStrategiesById);
  const assetAllocationMetadata = useSelector(
    investmentStrategyAssetAllocationsMetadataByIdSelector(id)
  );
  const allAssetAllocations = useSelector(
    investmentStrategyAssetAllocationsSelector
  );
  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(id)
  );
  const indexChangesMetadata = useSelector(
    investmentStrategyIndexChangesMetadataByIdSelector(id)
  );
  const products = useSelector(
    investmentStrategyProductsByStrategyIdSelector(id)
  );
  const costs = useSelector(investmentStrategyCostsByStrategyIdSelector(id));

  const signingEvents = useSelector(openSigningEventsSelector);

  const implementationPlan = useSelector(
    investmentStrategyImplementationPlansByIdSelector(id)
  );

  const metadata = useSelector(investmentStrategyCostMetadataByIdSelector(id));

  const advisoryFeeRelevant = metadata?.advisoryFeeRelevant;
  const structuringFeeRelevant = metadata?.structuringFeeRelevant;

  const investmentStrategyAssetAllocations = assetAllocations.filter(
    (assetClass) => assetClass.investmentStrategy.id === id
  );
  const assetGroups = sumAssetClassesByAssetGroup(
    investmentStrategyAssetAllocations
  );
  const loading = useResource([
    'SIGNING_EVENTS/INDEX',
    'INVESTMENT_STRATEGIES/INDEX',
    {
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_PRODUCT_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_INDEX_CHANGES/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_PORTFOLIO_CHARACTERISTICS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_COSTS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_IMPLEMENTATION_PLANS/INDEX',
      args: { strategyId: id },
    },
  ]);

  const investmentStrategy = investmentStrategies?.[id];

  const isParentStrategy = investmentStrategy
    ? investmentStrategy.children.length
    : null;

  const WrapperComponent = isParentStrategy
    ? StyledMainGrid
    : InvestmentStrategyStickyNavigation;

  // Check if this strategy has an open signing event on it, if so we want to display
  // the signature button the user
  let signatureButtonClickHandler;
  const relatedSigningEventId = investmentStrategy?.signingEvent?.id;
  if (relatedSigningEventId) {
    // Look through the list of _open_ signing events and see if we find a match
    // if we do, we want to extract the signature url.
    const signingEvent = signingEvents.find(
      (e) => e.id === relatedSigningEventId
    );

    // We only want to let the customer sign the strategy if the "status" of the sigining event
    // is explicitly set to "open"
    if (signingEvent?.status === 'open') {
      const signatureUrl = signingEvent?.document?.signatureUrl;

      // This logic is the same as we have in `signingTaskColumns.js`, we should perhaps
      // don't duplicate it, but use a "creator" method or something so we can re-use it?
      signatureButtonClickHandler = () => {
        dispatch(
          readActions.saveReadItem({
            type: 'signingEvents',
            relatedSigningEventId,
          })
        );
        dispatch(signingEventsActions.addToInProgress(relatedSigningEventId));
        // Open signature url in a new window
        window.open(signatureUrl, '_blank');

        // In the background we want to redirect the user to the todo screen so they
        // can sign any other documents they might have ready.
        history.push('/todo');
      };
    }
  }

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [costsAccepted, setCostsAccepted] = useState(true);

  return (
    <MenuPage
      {...props}
      subpage={true}
      title={investmentStrategy ? investmentStrategy.title : null}
    >
      <PageContent>
        {loading || !investmentStrategy ? (
          <CenteredActivityIndicator
            columns={12}
            style={{ marginTop: SPACING_120 }}
          />
        ) : (
          <>
            <Header columns={12}>
              <Row>
                <ParagraphSmall>
                  {investmentStrategy.accountName}
                  <Dot size={4} style={{ margin: '3px 7px' }} />
                </ParagraphSmall>
                <ParagraphSmall>
                  {`${t(
                    `wealthPlan:investmentStrategy:strategyCreated`
                  )}: ${toLocaleDateString(investmentStrategy.lastModifiedAt)}`}
                </ParagraphSmall>

                {investmentStrategy.type && (
                  <ParagraphSmall>
                    <Dot size={4} style={{ margin: '3px 7px' }} />
                    {investmentStrategy.type}
                  </ParagraphSmall>
                )}
              </Row>
            </Header>

            <WrapperComponent
              items={[
                { title: t('portfolio:strategy') },
                { title: t('portfolioConstruction:title') },
                ...(implementationPlan.rounds
                  ? [
                      {
                        title: t(
                          'advisor:strategySimulator:implementation:title'
                        ),
                      },
                    ]
                  : []),
                ...(costs.length
                  ? [
                      {
                        title: t(
                          'wealthPlan:investmentStrategy:costEstimate:title'
                        ),
                      },
                    ]
                  : []),
                ...(investmentStrategy?.managementType === 'portfolioManagement'
                  ? [
                      {
                        title: t(
                          'wealthPlan:investmentStrategy:portfolioManagement:title'
                        ),
                      },
                    ]
                  : []),
                {
                  title: t('wealthPlan:investmentStrategy:summary:title'),
                },
              ]}
            >
              <InvestmentStrategyContent
                id={id}
                investmentStrategy={investmentStrategy}
                advisoryFeeRelevant={advisoryFeeRelevant}
                structuringFeeRelevant={structuringFeeRelevant}
                assetAllocationMetadata={assetAllocationMetadata}
                investmentStrategyAssetAllocations={
                  investmentStrategyAssetAllocations
                }
                indexChangesMetadata={indexChangesMetadata}
                products={products}
                costs={costs}
                assetGroups={assetGroups}
                isParentStrategy={isParentStrategy}
                childStrategies={allInvestmentStrategies.filter((s) =>
                  investmentStrategy.children.some((e) => e.id === s.id)
                )}
                assetAllocations={allAssetAllocations}
                implementationPlan={implementationPlan}
                termsAcceptedCallback={setTermsAccepted}
                costsAcceptedCallback={setCostsAccepted}
                eligibleForSigning={Boolean(signatureButtonClickHandler)}
              />
            </WrapperComponent>
          </>
        )}

        {signatureButtonClickHandler ? (
          <InvestmentStrategySigningButton
            onClick={signatureButtonClickHandler}
            disabled={
              (investmentStrategy?.managementType === 'portfolioManagement' &&
                !termsAccepted) ||
              !costsAccepted
            }
          />
        ) : null}
      </PageContent>
    </MenuPage>
  );
};
