import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { primary } from '../../constants/colors';
import { SPACING_32 } from '../../constants/spacing';

import { StyledH4 } from '../texts';

const StyledListItem = styled.li`
  margin-right: ${SPACING_32};
`;

const StyledNavLink = styled(NavLink)`
  display: inline-flex;
  min-height: calc(100% + 5px);
  padding: 0;
  padding-bottom: 12px;
  text-decoration: none;
  justify-content: center;
  align-items: flex-end;

  &.active {
    border-bottom: 3px solid ${primary};
    padding-bottom: 8px;
  }
`;

const StyledText = styled(StyledH4)`
  text-align: center;
  display: block;
  white-space: nowrap;
`;

export const SubMenuItem = (props) => {
  const { title, path, exact, showInMenu = true } = props;

  if (!showInMenu) return null;

  return (
    <StyledListItem>
      <StyledNavLink exact={exact} to={path} activeClassName="active">
        <StyledText>{title}</StyledText>
      </StyledNavLink>
    </StyledListItem>
  );
};
