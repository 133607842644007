import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { H4 } from '../texts';
import { GridElement } from '../layout/GridElement';

import { SPACING_16, SPACING_8 } from '../../constants/spacing';

import { SpriteIconFunctional } from './SpriteIconFunctional';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  height: 100%;
  gap: ${SPACING_8};
`;

export const BackButton = (props) => {
  const { backOverrideUrl } = props;
  let history = useHistory();
  const { t } = useTranslation();

  return (
    <GridElement {...props}>
      <ContentWrapper
        onClick={() =>
          !backOverrideUrl ? history.goBack() : history.push(backOverrideUrl)
        }
      >
        <SpriteIconFunctional
          id="Simple arrow"
          direction="Left"
          size={SPACING_16}
        />
        <H4>{t('globals:return')}</H4>
      </ContentWrapper>
    </GridElement>
  );
};
