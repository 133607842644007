import { flattenDeep } from 'lodash';

import * as api from './api';
import * as entities from './entities';
import * as ui from './ui';
import * as flags from './flags';
import * as auth from './auth';

export { flags };

export const reducers = {
  api: api.reducer,
  entities: entities.reducer,
  flags: flags.reducer,
  ui: ui.reducers,
};

export const sagas = flattenDeep([...entities.sagas, ...ui.sagas]);

export const actions = {
  entities: entities.actions,
  ui: ui.actions,
};

export const selectors = {
  ui: ui.selectors,
  api: api.selectors,
  entities: entities.selectors,
  auth: auth.selectors,
};
