import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, selectors, useResource } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { MainGrid } from '../../layout';
import { Paragraph, ParagraphSmall } from '../../texts';
import { Modal } from '../../common/Modal';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

import { LensesFooter } from '../../portfolio/lenses/LensesFooter';
import { CoverageReadMore } from '../../portfolio/lenses/CoverageReadMore';
import { SectorsList } from '../../portfolio/lenses/sectors/SectorsList';

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const {
  entities: {
    securities: { securitiesMetadataSelector },
  },
} = selectors;

export const SectorsDetails = (props) => {
  const { highlightColor } = props;

  const loading = useResource(['ESG_DATA/INDEX']);
  const metadata = useSelector(securitiesMetadataSelector);
  const { coverageSector = 0 } = metadata;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Wrapper>
      {!loading ? (
        <>
          <SectorsList />
          <LensesFooter
            coverage={coverageSector}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <Paragraph style={{ fontWeight: 500 }}>
              {t('geography:classificationMethod')}
            </Paragraph>
            <ParagraphSmall>
              {t('geography:countryAllocationText')}
            </ParagraphSmall>
          </LensesFooter>
        </>
      ) : (
        <CenteredActivityIndicator
          columns={12}
          size={60}
          loadingColor={accent[highlightColor]}
        />
      )}
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentLabel={t(`esg:coverageReadMore:title`)}
        padding={SPACING_48}
      >
        <CoverageReadMore />
      </Modal>
    </Wrapper>
  );
};
