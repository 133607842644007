import {
  takeLatest,
  takeLeading,
  takeEvery,
  call,
  put,
  select,
  delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  config,
  storeAccessToken,
  deleteAccessToken,
  getFlagsFromAccessToken,
  getIsLeadFromAccessToken,
  setFlagsAction,
  exchangeAuthCodeForToken,
  exchangeUsernameForToken,
  exchangeIdTokenForToken,
  actions as coreActions,
  apiImpersonate,
} from '@formue-app/core';

import { actions } from './actions';
import { actions as appActions } from '../app/actions';

const {
  ui: { user: uiUserActions, impersonation: uiImpersonationActions },
  entities: { users: usersActions },
} = coreActions;

function* startAuthentication() {
  yield takeLeading(actions.startAuthentication, function* ({ payload }) {
    const { exchange, credentials, pathname } = payload;

    let accessToken;
    switch (exchange) {
      case 'username': {
        accessToken = yield call(exchangeUsernameForToken, credentials);
        break;
      }
      case 'code': {
        const { code, codeVerifier } = credentials;
        const { authCallbackUrl: redirectUrl } = config.app;
        // In case of bankid we need to redirect the user to the authorization endpoint
        accessToken = yield call(
          exchangeAuthCodeForToken,
          code,
          codeVerifier,
          redirectUrl
        );
        break;
      }
      case 'idToken': {
        accessToken = yield call(exchangeIdTokenForToken, credentials);
        break;
      }
      default: {
        throw new Error('No authentication exchange specified');
      }
    }

    yield put(actions.setAccessToken(accessToken));
    yield put(actions.finishAuthentication(pathname));
  });
}

function* finishAuthentication() {
  yield takeLeading(actions.finishAuthentication, function* ({ payload }) {
    const pathname = payload;
    const isEmployee = yield select((state) => state.auth.isEmployee);
    if (pathname) {
      yield put(push(pathname));
    } else if (isEmployee) {
      yield put(push('/advisor/clients'));
    } else {
      yield put(push('/'));
    }
    yield put(appActions.init());
  });
}

/**
 * Saves the access token to local storage
 */
function* saveAccessToken() {
  yield takeLatest(actions.setAccessToken, function* ({ payload }) {
    yield storeAccessToken(payload);

    let flags = yield call(getFlagsFromAccessToken, payload);
    yield put(setFlagsAction({ features: flags }));

    // Check access token, and set isLead on user and in flags
    const isLead = yield call(getIsLeadFromAccessToken, payload);
    yield put(setFlagsAction({ isLead: isLead }));
  });
}

/**
 * Deletes the access token from local storage
 */
function* removeAccessToken() {
  yield takeLatest(actions.removeAccessToken, function* () {
    yield deleteAccessToken();
  });
}

function* logout() {
  yield takeLatest(actions.logout, function* ({ payload }) {
    const { url = '/login', state } = payload;

    yield put(actions.removeAccessToken());
    // Also reset impersonating when logging out as an advisor
    yield put(actions.resetImpersonation());

    // Allow logout action to redirect to any arbitrary url
    if (url.startsWith('https://')) {
      window.location.assign(url);
    } else {
      yield put(push(url, state));
      // Adding a delay here so that we can be sure that logic that should
      // happen when we remove the access token get's finished before we
      // flush data. In practice this means that we enough time to navigate
      // away from any page that uses the useResource hook before we flush.
      // Without this, we would try to refetch the missing data which would
      // lead to an automatic re-authentication if you used advisor SSO login
      yield delay(500);
      yield put({ type: 'APP/FLUSH' });
    }
  });
}

/**
 * Loads the user that is currently authenticated from the users API
 */
function* loadCurrentUser() {
  yield takeLeading(actions.loadCurrentUser, function* () {
    const impersonationId = yield select((state) => state.auth.impersonationId);
    const userId = yield select((state) => state.auth.userId);
    if (impersonationId) {
      yield put(usersActions.showRequestBegin(impersonationId));
    }
    yield put(
      usersActions.showRequestBegin({ id: userId, impersonate: false })
    );
  });
}

function* impersonate() {
  yield takeEvery(actions.impersonateUser, function* ({ payload }) {
    const ssid = payload;
    // Store impersonationId in session storage so that we can use it incase
    // the advisors refreshes the app, that way we can bootstrap it with the
    // correct user
    window.sessionStorage.setItem('impersonationId', ssid);

    // Update UI
    yield put(uiUserActions.setIsImpersonating(true));
    yield put(uiUserActions.setImpersonationId(ssid));
    yield put(uiImpersonationActions.saveRecentlyImpersonatedClientId(ssid));
    const token = yield select((state) => state.auth.accessToken);

    // If impersonationId is equal to employeeClientId update UI to reflect that. E.g allow
    // for document download even if one is impersonating
    const employeeClientUserId = yield select(
      (state) => state.auth.employeeClientUser?.id
    );
    if (ssid === employeeClientUserId) {
      yield put(uiUserActions.setIsEmployeeClientUser(true));

      // Load the flags from employeeClientUser to give the same permissions as the employee
      // client user when logged in as "one self".
      let flags = yield call(getFlagsFromAccessToken, token, true);
      yield put(setFlagsAction({ features: flags }));
    } else {
      // Make sure we reset the flags to the authenticated user if we are not impersonating
      // our own employee client user
      let flags = yield call(getFlagsFromAccessToken, token, false);
      yield put(setFlagsAction({ features: flags }));
    }

    // Configure the api client to impersonate
    apiImpersonate(ssid);

    // Reload the app
    yield put({ type: 'APP/LOAD', payload: ssid });
  });
}

function* resetImpersonation() {
  yield takeEvery(actions.resetImpersonation, function* () {
    window.sessionStorage.removeItem('impersonationId');
    yield put(uiUserActions.setIsImpersonating(false));
    yield put(uiUserActions.setImpersonationId(null));

    apiImpersonate(undefined);

    // Flush all content so that we are sure we don't show another users data after
    // we have changed who we impersonate. We don't use action creator functions here
    // since the APP action creator does not live in the "@formue-app/core" package.
    // this allows us to dispatch events that the different apps that utilize
    // "@formue-app/core" to react to this event differently (or not at all).
    yield put({ type: 'APP/FLUSH' });

    // Load currentuser as we need it to start a new impersonation from the clients page
    yield put(actions.loadCurrentUser());
  });
}

export const sagas = [
  startAuthentication,
  finishAuthentication,
  saveAccessToken,
  removeAccessToken,
  loadCurrentUser,
  impersonate,
  resetImpersonation,
  logout,
];
