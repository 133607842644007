import React from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash';

import { Paragraph } from '../texts';
import { accent } from '../../constants/colors';
import { SPACING_16 } from '../../constants/spacing';
import { mobileCondition } from '../../constants/media';

import { RadioButton } from './RadioButton';

import { Toggle } from './Toggle';

const Item = styled.div`
  ${(props) => props.border && ` border-bottom: 1px solid ${accent.sand3}`};
  padding: ${(props) => props.padding}px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  @media ${mobileCondition} {
    padding: ${SPACING_16} 0;
  }
`;

const Title = styled(Paragraph)`
  font-weight: 500;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${SPACING_16};
`;

export const SelectDropdownItem = (props) => {
  const {
    option,
    icon,
    selectMultiple,
    setSelected,
    selected,
    border = true,
    padding = 24,
    small,
    ...rest
  } = props;
  const { label, value } = option;

  const handleClick = () => {
    if (selectMultiple) {
      if (selected.some((item) => item.value === value)) {
        // Remove from selected if it already was selected (basically a "toggle functionality")
        const filtered = selected.filter(
          (selectedItem) => selectedItem.value !== value
        );
        setSelected(filtered);
      } else {
        setSelected([...selected, option]);
      }
    } else {
      setSelected([option]);
    }
  };

  return (
    <Item
      className="option"
      border={border}
      padding={padding}
      onClick={handleClick}
      {...rest}
    >
      <IconWrapper>
        {icon && icon}
        {isFunction(label) ? (
          label()
        ) : (
          <Title style={label && { fontWeight: 400 }}>{label}</Title>
        )}
      </IconWrapper>
      {selectMultiple ? (
        <Toggle
          id={`select-dropdown-toggle-${value}`}
          name={`select-dropdown-toggle-${value}`}
          value={selected.some((item) => item.value === value)}
          onChange={() => {}}
          small={small}
        />
      ) : (
        <RadioButton
          id={`select-dropdown-radio-${option.value}`}
          name={`select-dropdown-radio-${option.value}`}
          checked={selected.some((item) => item.value === option.value)}
        />
      )}
    </Item>
  );
};
