import jwtDecode from 'jwt-decode';

import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';

const defaultState = {
  loading: false,
  exchange: null,
  accessToken: null,
  isAuthenticated: null,
  isEmployee: null,
  employeeClientUser: null,
  userId: null,
  userType: null,
  locale: null,
  iat: null,
  exp: null,
  operatingCountry: null,
  features: [],
  impersonationId: null,
};

export const reducer = handleActions(
  {
    [actions.startAuthentication]: (state, { payload }) => ({
      ...state,
      exchange: payload,
      loading: true,
    }),
    [actions.finishAuthentication]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.setAccessToken]: (state, { payload: accessToken }) => {
      const {
        id: userId,
        userType,
        isEmployee,
        employeeClientUser,
        locale,
        iat,
        exp,
        operatingCountry,
      } = jwtDecode(accessToken);

      return {
        ...state,
        isAuthenticated: true,
        accessToken,
        userId,
        userType,
        isEmployee,
        employeeClientUser,
        locale,
        iat,
        exp,
        operatingCountry,
      };
    },
    [actions.removeAccessToken]: (state) => ({
      ...state,
      isAuthenticated: false,
      accessToken: null,
      userId: null,
      userType: null,
      isEmployee: null,
      locale: null,
      iat: null,
      exp: null,
    }),
    [actions.impersonateUser]: (state, { payload }) => ({
      ...state,
      impersonationId: payload,
    }),
    [actions.resetImpersonation]: (state, { payload }) => ({
      ...state,
      impersonationId: null,
    }),
    [actions.logout]: () => defaultState,
  },
  defaultState
);
