import React from 'react';
import styled from 'styled-components';

import {
  paragraphXSmallSize,
  paragraphXSmallLineHeight,
} from '../../constants/text';

export const StyledParagraphXSmall = styled.p`
  font-size: ${paragraphXSmallSize};
  line-height: ${paragraphXSmallLineHeight};
  font-weight: 400;
`;

export const ParagraphXSmall = (props) => {
  const { children } = props;

  return <StyledParagraphXSmall {...props}>{children}</StyledParagraphXSmall>;
};
