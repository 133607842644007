import React from 'react';
import styled from 'styled-components';

import { printCondition } from '../../constants/media';

const Print = styled.div`
  @media ${printCondition} {
    nav,
    .toggle {
      display: none;
    }

    @page {
      margin: 0.5cm;
    }

    a[href^="http://"]:after,
    a[href^="https://"]:after
    {
      content: ' (' attr(href) ')';
      font-size: 90%;
    }
  }
`;

export const PrintDefault = (props) => {
  const { children } = props;

  return <Print>{children}</Print>;
};
