import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MainGrid } from '../../components/layout/MainGrid';
import { BaseSection } from '../../components/common/BaseSection';
import { ExtendedReportingBasePage } from './ExtendedReportingBasePage';
import { QvGridObject } from '../../components/qlik/QvGridObject';
import { QvObject } from '../../components/qlik/QvObject';
import { SPACING_48 } from '../../constants/spacing';
import { ExtendedReportingLoading } from '../../components/extendedReporting/ExtendedReportingLoading';

const StyledMainGrid = styled(MainGrid)`
  padding-bottom: ${SPACING_48};
  max-width: 100%;
`;

/**
 * Maps qvObjects array from Advanced reporting page config to React components
 */
const mapQvObjectsToComponents = (app, qvObjects, qvPromiseCallback) =>
  qvObjects.map((qvObject) =>
    mapQvObjectToComponent(app, qvObject, qvPromiseCallback)
  );

/**
 *
 * @param {*} app
 * @param {*} qvObject
 * @param {*} qvPromiseCallback - A function to call to add the promise from app.getObject to the collection
 * @returns A component with either a QvGridObject, or a BaseSection with several QvObjects inside.
 */
const mapQvObjectToComponent = (app, qvObject, qvPromiseCallback) => {
  const { id, columns, qvObjects: children } = qvObject;
  // If this object doesn't have an id, it's a "parent object". Parent objects should be wrapped inside
  // a container so that we can group smaller qlik objects
  if (!id) {
    return (
      <BaseSection columns={columns}>
        {children.map((child, index) => (
          <QvObject
            app={app}
            objectId={child.id}
            style={child.style}
            key={`qvObject-${child.id}`}
            promiseCallback={qvPromiseCallback}
          />
        ))}
      </BaseSection>
    );
  }
  return (
    <QvGridObject
      columns={qvObject.columns}
      app={app}
      objectId={qvObject.id}
      heightClass={qvObject.heightClass}
      options={qvObject.options}
      key={`qvGridObject-${qvObject.id}`}
      promiseCallback={qvPromiseCallback}
    />
  );
};

export const ExtendedReportingDynamicPage = (props) => {
  const { title, qvObjects, app } = props;
  const [loading, setLoading] = useState(true);
  const [qvObjectPromises, setQvObjectPromises] = useState([]);

  // This needs to be passed into the component that actually does "app.getObject()"
  // so that we can collect the promise Qlik returns from the getObject api.
  const qvPromiseCallback = (promise) => {
    setQvObjectPromises((existingPromises) => [...existingPromises, promise]);
  };

  useEffect(() => {
    // If we have collected all the promises for all the qlik objects
    // we want to embed, we wait until all the promises are resolved
    // before we change the loading state
    if (qvObjects.length === qvObjectPromises.length) {
      Promise.all(qvObjectPromises).then(() => {
        setLoading(false);
      });
    }

    /*
    // For some reason we get 2x the number of qvObjectPromises back
    // when we are changing pages, the intitial render works fine
    // but not when navigating between pages. This fixes it, but
    // is only required in development, since it's caused by React's
    // double render mode in strict mode.
    if (qvObjectPromises.length > qvObjects.length) {
      setLoading(false);
    }
    */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qvObjectPromises]);

  return (
    <ExtendedReportingBasePage title={title}>
      <ExtendedReportingLoading loading={loading} />
      <StyledMainGrid>
        {mapQvObjectsToComponents(app, qvObjects, qvPromiseCallback)}
      </StyledMainGrid>
    </ExtendedReportingBasePage>
  );
};
