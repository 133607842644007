import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import {
  selectors,
  actions,
  t,
  config,
  useResource,
  getFlagsSelector,
} from '@formue-app/core';

import { actions as authActions } from '../../store/auth';

import {
  notDesktopCondition,
  tabletCondition,
  mobileCondition,
} from '../../constants/media';
import { textLink } from '../../constants/colors';
import { SPACING_48, SPACING_32, SPACING_16 } from '../../constants/spacing';
import { H4Size, H4LineHeight } from '../../constants/text';

import { H2 } from '../../components/texts';
import { MenuPage } from '../base/MenuPage';
import { ProfileInfo } from '../../components/profile/ProfileInfo';
import { LanguageSelector } from '../../components/profile/LanguageSelector';
import { AccountSelector } from '../../components/profile/AccountSelector';
import { AuthenticatorDeviceList } from '../../components/profile/AuthenticatorDeviceList';
import { PrivacyPolicy } from '../../components/profile/PrivacyPolicy';
import { LegalDisclaimer } from '../../components/profile/LegalDisclaimer';
import { StyledGridElement } from '../../components/layout/GridElement';
import { SelfDeclarationOverview } from '../../components/profile/SelfDeclarationOverview';
import { PasskeysOnboardingPromotion } from '../../components/passkeys/PasskeysOnboardingPromotion';

const { currentUserSelector } = selectors.ui.user;
const { allAccountsSelector } = selectors.entities.accounts;
const { allAuthenticatorDevicesSelector } =
  selectors.entities.authenticatorDevices;

const { accounts: accountActions } = actions.entities;

const Space = styled.div`
  padding-top: ${SPACING_48};

  @media ${tabletCondition} {
    padding-top: ${SPACING_32};
  }

  @media ${mobileCondition} {
    padding-top: ${SPACING_16};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: ${H4Size};
  line-height: ${H4LineHeight};
  font-weight: 500;
  color: ${textLink};
`;

export const ProfileOverviewPage = (props) => {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({
    query: notDesktopCondition,
  });

  const flags = useSelector(getFlagsSelector);
  const passkeysFlagEnabled = flags?.features?.passkeys;

  useResource(['AUTHENTICATOR_DEVICES/INDEX']);

  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);
  const isEmployee = useSelector((state) => state.auth.isEmployee);

  const user = useSelector(currentUserSelector);
  const accounts = useSelector(allAccountsSelector) || [];
  const authenticatorDevices =
    useSelector(allAuthenticatorDevicesSelector) || [];

  // Load accounts when loading this page
  useEffect(() => {
    dispatch(accountActions.indexRequestBegin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return null;
  }

  let columnCount = isTabletOrMobile ? 12 : 6;

  const onClick = (e) => {
    e.preventDefault();

    dispatch(
      authActions.logout({
        url: config.domain,
      })
    );
  };

  return (
    <MenuPage {...props}>
      <StyledGridElement columns={columnCount}>
        <ProfileInfo user={user} />
        <Space />
        <LanguageSelector />
        <Space />
        <PrivacyPolicy />
        <Space />
        <LegalDisclaimer />
        <Space />
        {!isEmployee || (isEmployee && !isImpersonating) ? (
          <StyledLink onClick={onClick}>{t('menu:logOut')}</StyledLink>
        ) : null}
      </StyledGridElement>

      <StyledGridElement columns={columnCount}>
        <SelfDeclarationOverview />
        <Space />
        {passkeysFlagEnabled ? (
          <>
            <H2 style={{ marginBottom: 20 }}>Passkeys</H2>
            {!authenticatorDevices.length ? (
              <PasskeysOnboardingPromotion
                columns={columnCount}
                compact={true}
              />
            ) : (
              <AuthenticatorDeviceList devices={authenticatorDevices} />
            )}
            <Space />
          </>
        ) : null}
        <AccountSelector accounts={accounts} />
        <Space />
      </StyledGridElement>
    </MenuPage>
  );
};
