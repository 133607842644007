import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  localizeFloat,
  numberWithSpaces,
  selectors,
  t,
} from '@formue-app/core';
import { notDesktopCondition } from '../../../../constants/media';
import { BaseSection } from '../../../common/BaseSection';
import { CarbonFootprintValue } from './CarbonFootprintValue';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
} from '../../../../constants/spacing';
import { ContributionBySector } from './ContributionBySector';
import { ModalSection } from './ModalSection';
import { H2Small, H3, H4, Paragraph, ParagraphSmall } from '../../../texts';
import { SustainabilityAdvisor } from './SustainabilityAdvisor';
import { accent } from '../../../../constants/colors';
import { Coverage } from './Coverage';
import { ComparisonWithOthers } from './ComparisonWithOthers';

const i18nPrefix = 'sustainability:accordions';

const {
  entities: {
    portfolioLenses: {
      carbonFootprintDataSelector,
      portfolioLensesMetadataSelector,
    },
  },
} = selectors;

const StyledWrapper = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${SPACING_16} 0;
  flex-flow: column nowrap;
  gap: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  @media ${notDesktopCondition} {
    flex-flow: column;
    padding: 0;
  }
`;

const SectionTitle = styled(H2Small)`
  padding-bottom: ${SPACING_16};
`;

const StyledParagraph = styled(Paragraph)`
  padding-bottom: ${SPACING_16};
`;

const RowWrapper = styled.div`
  display: flex;
  gap: ${SPACING_12};
  flex: 1;
  width: 100%;
`;

export const CarbonFootprintModalContent = ({ coverage }) => {
  const carbonFootprintData = useSelector(carbonFootprintDataSelector);
  const portfolioLensesMetadata = useSelector(portfolioLensesMetadataSelector);

  const global = portfolioLensesMetadata.benchmark?.carbonFootprint?.global;
  const domestic = portfolioLensesMetadata.benchmark?.carbonFootprint?.domestic;

  if (!carbonFootprintData) return null;

  return (
    <StyledWrapper>
      <CarbonFootprintValue breakText />
      <ModalSection
        maxWidth={1200}
        style={{ border: 'none', backgroundColor: accent.neutral150 }}
      >
        <SectionTitle>{t(`${i18nPrefix}:mainContributors:title`)}</SectionTitle>
        <H4 style={{ marginBottom: SPACING_16 }}>
          {t(`${i18nPrefix}:mainContributors:bySector`)}
        </H4>
        <ContributionBySector />
      </ModalSection>
      <RowWrapper>
        <ModalSection>
          <SectionTitle>{t(`${i18nPrefix}:impact:title`)}</SectionTitle>
          <Paragraph style={{ whiteSpace: 'pre-line' }}>
            {t(`${i18nPrefix}:impact:carbonFootprint`)}
          </Paragraph>
          <SustainabilityAdvisor />
        </ModalSection>
        {global && domestic ? (
          <ModalSection>
            <SectionTitle>
              {t(`sustainability:accordions:comparisonWithOthers:title`)}
            </SectionTitle>
            <ComparisonWithOthers
              global={global}
              domestic={domestic}
              value={carbonFootprintData.carbonFootprint}
              formatValue={(value) =>
                numberWithSpaces(localizeFloat(value.toFixed(0), 0))
              }
              chartColor={accent.ocean230}
              maxWidth={400}
            />
          </ModalSection>
        ) : null}
      </RowWrapper>
      <ModalSection>
        <SectionTitle>
          {t(`${i18nPrefix}:calculation:carbonFootprintTitle`)}
        </SectionTitle>
        <StyledParagraph style={{ whiteSpace: 'pre-line' }}>
          {t(`${i18nPrefix}:calculation:carbonFootprintParagraph1`)}
        </StyledParagraph>
        <ul
          style={{ margin: 0, paddingLeft: SPACING_16, paddingTop: SPACING_16 }}
        >
          {t(`${i18nPrefix}:calculation:carbonFootprintItems`, {
            returnObjects: true,
          }).map((item, index) => (
            <li>
              <StyledParagraph>
                <Trans
                  i18nKey={item}
                  components={{
                    strong: (
                      <span
                        style={{
                          fontWeight: 500,
                          paddingRight: 5,
                        }}
                      />
                    ),
                  }}
                />
              </StyledParagraph>
            </li>
          ))}
        </ul>

        <StyledParagraph style={{ whiteSpace: 'pre-line' }}>
          {t(`${i18nPrefix}:calculation:carbonFootprintParagraph2`)}
        </StyledParagraph>
        <ParagraphSmall style={{ whiteSpace: 'pre-line' }}>
          {t(`${i18nPrefix}:calculation:carbonFootprintParagraph3`)}
        </ParagraphSmall>
      </ModalSection>
      <Coverage coverage={coverage} />
    </StyledWrapper>
  );
};
