import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  SPACING_32,
  SPACING_24,
  SPACING_64,
  SPACING_16,
} from '../../constants/spacing';
import { H1 } from '../texts';
import { accent, whiteTranslucent } from '../../constants/colors';
import { easeInOut, extraLongAnimationTime } from '../../constants/animation';
import { ActivityIndicator } from '../common/ActivityIndicator';
import { InstrumentsLoading } from './InstrumentsLoading';
import { SectorsLoading } from './SectorsLoading';
import { GeographyLoading } from './GeographyLoading';

const OuterWrapper = styled.div`
  padding: ${SPACING_16} ${SPACING_24};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${accent.ocean4};

  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${whiteTranslucent(0.9)};
  z-index: 99;
`;

const InnerWrapper = styled.div`
  position: fixed;
  top: 150px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Title = styled(H1)`
  padding-top: ${SPACING_32};
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  position: relative;
  opacity: 0;
  top: 130px;
  transition: opacity ${easeInOut} ${extraLongAnimationTime},
    top ${easeInOut} 1s;

  ${(props) =>
    props.fadeIn &&
    `
    top: 0;
    opacity: 1;
  `}
`;

const Content = styled.div`
  padding-top: ${SPACING_64};
  transition: opacity ${easeInOut} ${extraLongAnimationTime},
    top ${easeInOut} 1s;
  opacity: 0;
  top: 30px;

  ${(props) =>
    props.fadeIn &&
    `
  opacity: 1;
  top: 0;
`}
`;

const ScreensWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const AnimationWrapper = styled.div`
  position: absolute;
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  top: 200px;
`;

const screens = [
  {
    title: 'Connecting data for thousands of instruments',
    Component: InstrumentsLoading,
  },
  {
    title: 'Allocating instruments across the sectors',
    Component: SectorsLoading,
  },
  {
    title: 'Allocating instruments across countries and continets',
    Component: GeographyLoading,
  },
];

export const ExtendedReportingLoading = ({ loading }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 4000);

    return () => clearInterval(interval);
  }, [counter]);

  if (!loading) return null;

  return (
    <OuterWrapper>
      <InnerWrapper>
        <Container>
          <ScreensWrapper>
            <StyledActivityIndicator />
            {screens.map(({ title, Component }, index) => {
              const fadeIn = index === counter % 3;
              return (
                <AnimationWrapper fadeIn={fadeIn} key={`${title}-${index}`}>
                  <Title fadeIn={fadeIn}>{title}</Title>
                  <Content fadeIn={fadeIn}>
                    <Component reset={fadeIn} />
                  </Content>
                </AnimationWrapper>
              );
            })}
          </ScreensWrapper>
        </Container>
      </InnerWrapper>
    </OuterWrapper>
  );
};
