import React, { useState } from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { GridElement } from '../../../../components/layout';
import { CollapsibleListItem } from '../../../../components/common/CollapsibleListItem';
import { H2, H3, H4, Paragraph } from '../../../../components/texts';
import { TableList } from '../../../../components/lists';
import { List, ListItem } from '../../../../components/common/List';

import { Toggle } from '../../../../components/formElements';
import { desktopCondition, tabletCondition } from '../../../../constants/media';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { InfoCard } from '../../../../components/common/InfoCard';

const StyledInfoCard = styled(InfoCard)`
  margin-top: 10px;

  @media ${desktopCondition} {
    margin-right: 40px;
  }

  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const StyledIntroSection = styled(GridElement)`
  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const ContentWrapper = styled.div`
  max-width: 75%;
  padding: ${SPACING_24};
  padding-top: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 55px;
  margin-bottom: ${SPACING_16};
`;

export const GeneralPage = (props) => {
  const { year } = props;
  const [openAll, setOpenAll] = useState(false);

  const faqData = [
    {
      title: 'Tidspunkter for publisering av rapporter og oppgaver',
      detailsStyle: { padding: 0 },
      details: (
        <TableList
          sortable={false}
          columns={[
            {
              key: 'title',
              label: 'Rapport/Publiseringstidspunkt',
              render: ({ title, publishDate }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <H4>{title}</H4>
                  <Paragraph>{publishDate}</Paragraph>
                </div>
              ),
            },
            {
              key: 'type',
              label: 'Type',
            },
            { key: 'source', label: 'Kilde/sted' },
          ]}
          rows={[
            {
              title: 'Innskudd, utlån og rente m.v. (Formueskonto)',
              publishDate: 'Januar',
              type: 'Storebrand Nettbank',
              source: 'Storebrand Nettbank',
            },
            {
              title: 'Innskudd, utlån og rente m.v. 2020 (Kundekonto)',
              publishDate: 'Januar',
              type: 'Formue',
              source: 'Kundenett > Dokumenter',
            },
            {
              title: 'Deltakeroppgaver fra deltakerliknende selskaper (IS, DA)',
              publishDate: 'Januar - mai',
              type: 'Formue Forretningsførsel',
              source: 'Kundenett > Dokumenter',
            },
            {
              title:
                'VPS-årsoppgave for VPS-registrerte verdipapirfond, AS og obligasjoner',
              publishDate: 'Februar 2021',
              type: 'Verdipapirsentralen (VPS)',
              source: 'VPS',
            },
            {
              title:
                'Deltakeroppgaver fra deltakerliknende selskaper (IS, DA, KS)',
              publishDate: 'Februar - mai',
              type: 'Eksterne forretningsførere',
              source: 'Eksterne forretningsførere',
            },
            {
              title: 'Skatterapport Nominee 2020 (Årsoppgave)',
              publishDate: '15.02.2021',
              type: 'Formue',
              source: 'Kundenett > Dokumenter',
            },
            {
              title:
                'Fradragsberettigede honorarer og skattepliktige inntekter',
              publishDate: '19.03.2021',
              type: 'Formue',
              source: 'Kundenett > Skatt',
            },
          ]}
          keyExtractor={(item, index) => `faq-${index}`}
        />
      ),
    },
    {
      title: 'Frister for å sende inn skattemeldingen for 2020',
      details: (
        <ContentWrapper>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            Frist for å levere skattemelding er:
          </Paragraph>
          <List>
            <ListItem>30. april 2021 for personlig skattytere</ListItem>
            <ListItem>31. mai 2021 for næringsdrivende</ListItem>
          </List>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            Husk at du enkelt kan søke om utsettelse på Altinn sine sider. Det
            innvilges alltid én måneds utsettelse. Vi minner om at det for
            deltakere i selskapsformene DA, IS eller KS må benyttes
            skattemelding for næringsdrivende, selv om man har mottatt
            skattemelding for lønnstakere. Slik skattemelding laster du ned i{' '}
            <a href="https://www.altinn.no" target="_blank" rel="noreferrer">
              www.altinn.no
            </a>
            . Gå til “nytt skjema” og søk etter RF-1030.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Hva trenger jeg å fylle ut selv i skattemeldingen?',
      details: (
        <ContentWrapper>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            <b>Fradragsberettigede honorarer og skattepliktige inntekter</b>
            <br />I år, som i fjor, finner du informasjon om fradragsberettigede
            honorarer digitalt her. Rapporten kan skrives ut og inneholder
            bekreftede fradragsberettigede honorarer og skattepliktige inntekter
            med informasjon om hvor i skattemeldingen du skal føre disse.
          </Paragraph>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            <b>
              Deltagere i selskap med Delt Ansvar (DA), Indre Selskap (IS) eller
              Kommandittselskap (KS)
            </b>
            <br />
            Deltakere i ett eller flere selskap med Delt Ansvar (DA), Indre
            Selskap (IS) eller Kommandittselskap (KS) må benytte skattemelding
            for næringsdrivende, selv om man har mottatt skattemelding for
            lønnstakere.
          </Paragraph>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            Deltageroppgaver publiseres mellom januar og mai.
          </Paragraph>
          <Paragraph>
            <a
              href="https://formue.my.salesforce.com/sfc/p/0Y000000pG6P/a/07000000svDe/37euxMqryjW9BC2S3KNOPyvNWgdzfPrfWlZSWRvox3c"
              target="_blank"
              rel="noreferrer"
            >
              Guide til hvordan du fyller ut ligningspapirer for deltagere
            </a>
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Hva er allerede fylt ut for meg i skattemeldingen?',
      details: (
        <ContentWrapper>
          <Paragraph>
            I skattemeldingen for 2020 er det viktig at du kontrollerer:
          </Paragraph>
          <List>
            <ListItem>Alle forhåndsutfylte opplysninger </ListItem>
            <ListItem>Om noen opplysninger mangler</ListItem>
          </List>
          <H3>Formue</H3>
          <Paragraph>
            Vi rapporterer følgende opplysninger til skattemyndighetene:
          </Paragraph>
          <List>
            <ListItem>
              Aksjesparekonto, Verdipapirfond og Notes hos Formue finner du i
              dokumentet
              <br />
              <b>Skatterapport Nominee 2020</b> i dokumentarkivet
            </ListItem>
            <ListItem>
              Oversikt over bankinnskudd og renter hos Formue finner du i
              dokumentet
              <br />
              <b>Innskudd, utlån og rente m.v. 2020</b> i dokumentarkivet
            </ListItem>
          </List>
          <H3>Formueskonto </H3>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            Formueskontoen rapporteres av Storebrand. Saldo- og renteoppgave
            finner du ved å logge deg inn på 
            <a
              href="https://nettbank.storebrand.no"
              target="_blank"
              rel="noreferrer"
            >
              https://nettbank.storebrand.no
            </a>
            .
          </Paragraph>
          <H3>VPS-konto</H3>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            Hvis du har verdipapirkonto hos VPS finner du årsoppgave for
            VPS-kontoen ved å logge på VPS Investortjenester via 
            <a href="https://www.vps.no/" target="_blank" rel="noreferrer">
              https://www.vps.no
            </a>
            .
          </Paragraph>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            Pålogging kan skje med BankID/BankID på mobil, alternativt med
            brukernavn/passord fra VPS. Dersom du har glemt ditt
            påloggingspassord kan dette bestilles på samme side. Vi gjør
            oppmerksom på at det kun er privatpersoner som kan bestille nytt
            passord elektronisk og logge på med BankID/BankID på mobil. For
            bedriftskunder må tilsendt passord benyttes sammen med org.nr.
          </Paragraph>
          <Paragraph>
            For bestilling av nytt passord, send e-post til 
            <a href="mailto:kundenett@formue.no">kundenett@formue.no</a>.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Tilgang til kundenett for regnskapsførere eller andre',
      details: (
        <ContentWrapper>
          <Paragraph>
            Dersom du ønsker å gi tilgang til regnskapsfører eller andre
            personer kan du kontakte oss på: 
            <a href="mailto:kundenett@formue.no">kundenett@formue.no</a> eller
            ringe oss på telefon <a href="tlf:91634092">91634092</a>.
          </Paragraph>
          <Paragraph>
            Vi må få oppgitt navn, fødsels- og personnummer, telefonnummer og
            adresse på den som skal ha lesetilgang. Vi vil deretter legge ut et
            skjema på kundenettet som signaturberettiget må signere.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title:
        'Hvor finner jeg informasjon om den skattemessige behandlingen av de ulike verdipapirene mine hos Formue?',
      details: (
        <ContentWrapper>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            Det er utarbeidet en egen oversikt med den skattemessige
            behandlingen.
          </Paragraph>
          <Paragraph>
            <a
              href="https://formue.my.salesforce.com/sfc/p/0Y000000pG6P/a/1v0000007heT/MqgL0hHr5jUSoOMjlmORNnPNnzsFoi8_VTxPTEtvyY0"
              target="_blank"
              rel="noreferrer"
            >
              Last ned dokumentet her
            </a>
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Hvor finner jeg likningsverdier for aksjeselskaper for 2020?',
      details: (
        <ContentWrapper>
          <Paragraph style={{ marginBottom: SPACING_16 }}>
            Alle aksjeselskap tilrettelagt av Formue AS med forretningsførsel
            hos Formue Forretningsførsel AS rapporteres gjennom VPS og godkjente
            likningsverdier finnes i Årsoppgaven fra VPS for 2020. Dette må
            sammenholdes med opplysninger i RF-1088.
          </Paragraph>
          <Paragraph>
            Aksjeselskap og andre juridiske innretninger (ikke privatpersoner)
            som skal benytte verdi 31.12.2020, kan henvende seg til{' '}
            <a href="mailto:forretningsforsel@formue.no">
              forretningsforsel@formue.no
            </a>{' '}
            og få tilsendt verdien direkte.
          </Paragraph>
        </ContentWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledInfoCard
        title="Fradragsberettigede honorarer og skattepliktige inntekter 2020"
        body="For å se dine fradragsberettigede honorarer og skattepliktige
          inntekter har vi laget en oversikt over dette på linken nedenfor."
        navLabel="Se oversikt"
        to={`/portfolio/tax/declaration/${year}`}
      />
      <StyledIntroSection columns={5} style={{ marginTop: SPACING_8 }}>
        <H3 style={{ marginBottom: SPACING_8 }}>Viktig for privatpersoner</H3>
        <Paragraph style={{ marginBottom: 35 }}>
          Vi minner om at lønnsmottakere og pensjonister mottar en ny type
          skattemelding der det ikke lenger benyttes poster, men emner.
        </Paragraph>
        <H3 style={{ marginBottom: SPACING_8 }}>
          Overføring til Aksjesparekonto
        </H3>
        <Paragraph>
          Har du gjennomført overføring fra Aksjesparekonto hos annen leverandør
          til Aksjesparekonto hos Formue i 2020? Vi ber deg kontrollere
          overførselen på skattemeldingen din. Skulle du oppdage noe som ikke
          stemmer ber vi deg kontakte avgivende leverandør for å rette feilen.
        </Paragraph>
      </StyledIntroSection>
      <GridElement columns={12}>
        <TitleWrapper>
          <H2>Ofte stilte spørsmål</H2>
          <Toggle
            label="Se alle"
            value={openAll}
            onChange={() => setOpenAll(!openAll)}
          />
        </TitleWrapper>
        {faqData.map((q, index) => (
          <CollapsibleListItem
            details={q.details}
            detailsStyle={q.detailsStyle}
            startExpanded={openAll}
            style={{ marginTop: SPACING_8, padding: SPACING_24 }}
            key={`faq-${camelcase(q.title)}-${index}`}
          >
            <H3 style={{ marginRight: 'auto' }}>{q.title}</H3>
          </CollapsibleListItem>
        ))}
      </GridElement>
    </>
  );
};
