import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  selectors,
  localizeFloat,
  t,
  getEsgLevelString,
  pointsToNextLevel,
  percentage,
} from '@formue-app/core';
import { EsgTabItem } from './EsgTabItem';
import {
  BORDER_RADIUS_LARGE,
  SPACING_8,
} from '../../../../../constants/spacing';
import { accent } from '../../../../../constants/colors';
import {
  mobileCondition,
  notDesktopCondition,
  tabletCondition,
} from '../../../../../constants/media';
import { SpriteIconFunctional } from '../../../../common/SpriteIconFunctional';

const StyledContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
`;

const StyledArrowsHolder = styled.div`
  position: absolute;
  top: 50%;
  left: -30px;
  right: -30px;
  display: flex;
  @media ${notDesktopCondition} {
    display: none;
  }
`;

const StyledButton = styled.button`
  cursor: pointer;
  background: transparent;
  outline: 0;
  border: 0;
`;

const StyledDots = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${tabletCondition} {
    top: -10px;
  }
  @media ${mobileCondition} {
    top: -40px;
  }
`;

const StyledDotWrapper = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  padding: 0;
  margin: 0 ${SPACING_8};
  width: 16px;
  height: 16px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${BORDER_RADIUS_LARGE};
  background-color: ${accent.sand370};
`;

const {
  entities: {
    esgData: { esgDataSelector, esgRatingSelector },
  },
} = selectors;

export const EsgTabs = ({ activeItemCallback = () => {}, height }) => {
  const [activeItem, setActiveItem] = useState(0);

  const esgData = useSelector(esgDataSelector);
  const esgRating = useSelector(esgRatingSelector);
  const { value, nextLevel } = pointsToNextLevel(esgData.qualityScore);

  const entries = [
    {
      title: t(
        `esg:esgScore:sustainabilityRating:${getEsgLevelString(
          esgData.qualityScore
        )}`
      ),
      heading: t('esg:esgScore:sustainabilityRatingTitle'),
      esgValue: t('esg:esgScore:esgTagValue', {
        esgValue: localizeFloat(esgData.qualityScore),
      }),
    },
    {
      title: t('esg:esgScore:pointsToNextLevel:title', {
        points: localizeFloat(value),
      }),
      subtitle: nextLevel
        ? t('esg:esgScore:pointsToNextLevel:subtitle', { nextLevel })
        : t('esg:esgScore:pointsToNextLevel:pointsToMaximum'),
    },
    {
      title: `${percentage(esgRating.leader * 100)} ${t(
        'esg:esgScore:types:leader'
      )}`,
      esgValue: `${localizeFloat(7.1)} - ${localizeFloat(10.0)}`,
      heading: t('esg:esgScore:portfolioStructure'),
    },
    {
      title: `${percentage(esgRating.average * 100)} ${t(
        'esg:esgScore:types:average'
      )}`,
      esgValue: `${localizeFloat(2.9)} - ${localizeFloat(7.1)}`,
      heading: t('esg:esgScore:portfolioStructure'),
    },
    {
      title: `${percentage(esgRating.laggard * 100)} ${t(
        'esg:esgScore:types:behind'
      )}`,
      esgValue: `${localizeFloat(0.0)} - ${localizeFloat(2.9)}`,
      heading: t('esg:esgScore:portfolioStructure'),
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      <StyledContainer style={{ height: height }}>
        {entries.map((item, index) => {
          return (
            <EsgTabItem
              {...item}
              active={activeItem === index}
              index={index}
              key={index}
            />
          );
        })}
      </StyledContainer>
      <StyledArrowsHolder>
        {activeItem !== 0 && (
          <StyledButton
            onClick={() => {
              if (activeItem > 0) setActiveItem(activeItem - 1);
              activeItemCallback(activeItem - 1);
            }}
          >
            <SpriteIconFunctional
              id="Simple arrow"
              size={14}
              direction="Left"
              color={accent.sand1}
            />
          </StyledButton>
        )}
        {activeItem < entries.length - 1 && (
          <StyledButton
            onClick={() => {
              if (activeItem < entries.length - 1)
                setActiveItem(activeItem + 1);
              activeItemCallback(activeItem + 1);
            }}
            style={{ marginLeft: 'auto' }}
          >
            <SpriteIconFunctional
              id="Simple arrow"
              size={14}
              direction="Right"
              color={accent.sand1}
            />
          </StyledButton>
        )}
      </StyledArrowsHolder>
      <StyledDots>
        {entries.map((item, index) => {
          return (
            <StyledDotWrapper
              onClick={() => {
                setActiveItem(index);
                activeItemCallback(index);
              }}
              key={index}
            >
              <StyledDot
                style={{
                  backgroundColor: index === activeItem && accent.ocean4,
                }}
              />
            </StyledDotWrapper>
          );
        })}
      </StyledDots>
    </div>
  );
};
