import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import camelcase from 'camelcase';
import { useParams } from 'react-router';

import { useResource, useErrorState, t, useEntities } from '@formue-app/core';

import {
  SPACING_20,
  SPACING_24,
  SPACING_32,
  SPACING_4,
} from '../constants/spacing';
import { accent } from '../constants/colors';
import { easeInOut, longAnimationTime } from '../constants/animation';
import { reports } from '../components/presentation/reports';
import { ReportHeader } from '../components/presentation/reportComponent/ReportHeader';
import { ReportLoading } from '../components/presentation/messages/ReportLoading';
import { BaseReportSection } from '../components/presentation';
import { Modal } from '../components/common/Modal';
import { ContactForm } from '../components/presentation/reportComponent/ContactForm';
import { ContactModalContent } from '../components/presentation/reportComponent/ContactModalContent';

import { H2, ParagraphXSmall } from '../components/texts';
import { SpriteIcon } from '../components/common/SpriteIcon';
import { FiltersSectionContent } from '../components/presentation/reportComponent/FiltersSectionContent';
import { FilterPill } from '../components/presentation/reportComponent/FilterPill';

const SlidesWrapper = styled.div`
  width: 100%;
  height: 100vh;
  /* scroll-snap-type: y mandatory;
  scroll-behavior: smooth; */
  overflow-y: scroll;
  overflow-x: hidden;

  transition: background ${easeInOut} ${longAnimationTime} 0.1s;
`;

const FeedbackWrapper = styled.div`
  position: fixed;
  right: ${SPACING_24};
  bottom: ${SPACING_24};
  padding: 8px 12px;
  transition: bottom ${easeInOut} ${longAnimationTime};
  width: 80px;
  height: 80px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: pre-line;

  svg {
    margin-bottom: ${SPACING_4};
  }

  ${(props) =>
    props.hide &&
    css`
      bottom: -80px;
    `};
`;

export const ReportPage = (props) => {
  const { id } = useParams();

  // We filter out the disabled slides at the start
  const sectionOrigin = reports[camelcase(id)].filter((item) => item.enabled);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [hasBeenLoaded, setHasBeenLoaded] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const wrapperElement = useRef(null); // document.getElementById('slides-wrapper');

  const loading = useResource([
    'BALANCE_ENTRIES/INDEX',
    'FAMILY_MEMBERS/INDEX',
    'MILESTONES/INDEX',
    'ORGANIZATIONS/INDEX',
    'GOALS/INDEX',
    'STRATEGIES/INDEX',
    'TASKS/INDEX',
    'ACCOUNT_TEAM_MEMBERS/INDEX',
    'OFFICES/INDEX',
  ]);

  const error = useErrorState([
    'BALANCE_ENTRIES/INDEX',
    'FAMILY_MEMBERS/INDEX',
    'MILESTONES/INDEX',
    'ORGANIZATIONS/INDEX',
    'GOALS/INDEX',
    'STRATEGIES/INDEX',
    'TASKS/INDEX',
    'ACCOUNT_TEAM_MEMBERS/INDEX',
    'OFFICES/INDEX',
  ]);

  useEntities(['ACCOUNTS/INDEX']);

  useEffect(() => {
    if (!loading) {
      setHasBeenLoaded(true);
    }
  }, [loading]);

  const showStrategyFilter =
    sectionOrigin[activeSectionIndex].customProps?.showStrategyFilter;
  const showPortfolioFilter =
    sectionOrigin[activeSectionIndex].customProps?.showPortfolioFilter;
  const hideFeedbackModal =
    sectionOrigin[activeSectionIndex].customProps?.hideFeedbackModal;

  return (
    <>
      <ReportHeader
        wrapperElement={wrapperElement.current}
        showStrategyFilter={showStrategyFilter}
        showPortfolioFilter={showPortfolioFilter}
        setFilterModalOpen={setFilterModalOpen}
      />
      <SlidesWrapper
        style={{
          backgroundColor:
            accent[sectionOrigin[activeSectionIndex].backgroundColor],
        }}
        id="slides-wrapper"
        ref={wrapperElement}
      >
        {!hasBeenLoaded || error ? (
          <ReportLoading
            wrapperElement={wrapperElement}
            loading={loading}
            error={error}
          />
        ) : (
          sectionOrigin.map((slide, index) => (
            <BaseReportSection
              slide={slide}
              index={index}
              setActiveSectionIndex={setActiveSectionIndex}
              activeSectionIndex={activeSectionIndex}
              key={`section-${index}-${slide.id}`}
              wrapperElement={wrapperElement}
              setFilterModalOpen={setFilterModalOpen}
            />
          ))
        )}

        <Modal
          isOpen={feedbackModalOpen}
          onRequestClose={() => setFeedbackModalOpen(false)}
          backgroundColor={accent.sand1}
          contentWidth={450}
        >
          <ContactModalContent />
          <ContactForm style={{ padding: 0, background: 'none' }} />
        </Modal>

        <FeedbackWrapper
          hide={hideFeedbackModal}
          onClick={() => setFeedbackModalOpen(true)}
        >
          <SpriteIcon id="Comment" size={SPACING_20} />
          <ParagraphXSmall>
            {t('report:annualReview:contact:form:title')}
          </ParagraphXSmall>
        </FeedbackWrapper>
      </SlidesWrapper>

      <FilterPill
        showStrategyFilter={showStrategyFilter}
        showPortfolioFilter={showPortfolioFilter}
        setFilterModalOpen={setFilterModalOpen}
      />

      <Modal
        isOpen={filterModalOpen}
        onRequestClose={() => setFilterModalOpen(false)}
        labelComponent={H2}
        contentLabel={t('report:annualReview:filters:modalTitle')}
        padding={SPACING_32}
        contentWidth={450}
        iconSize={20}
      >
        <FiltersSectionContent isModalContent={true} />
      </Modal>
    </>
  );
};
