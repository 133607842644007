import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  getSustainabilityTrend,
  localizeFloat,
  percentage,
  selectors,
  t,
} from '@formue-app/core';
import { BaseSection } from '../../../common/BaseSection';
import { H2, Paragraph } from '../../../texts';
import { accent } from '../../../../constants/colors';
import {
  SPACING_32,
  SPACING_12,
  SPACING_8,
  SPACING_48,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../../constants/spacing';
import { Button } from '../../../buttons';
import { Modal } from '../../../common/Modal';
import { SustainabilityPieChart } from './SustainabilityPieChart';
import { ScienceBasedTargetsModalContent } from './ScienceBasedTargetsModalContent';
import { TrendBadge } from './TrendBadge';

const i18nPrefix = 'sustainability:scienceBasedTargetsScreen';

const {
  entities: {
    esgData: { esgDataSelector, esgIndexSelector, esgMetricsSelector },
    portfolioLenses: { scienceBasedShareDataSelector },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_32};
  display: flex;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${SPACING_12};
  padding-bottom: ${SPACING_32};
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
`;

export const ScienceBasedTargets = (props) => {
  const scienceBasedShareData = useSelector(scienceBasedShareDataSelector);
  const trend = getSustainabilityTrend(
    'scienceBasedShare.target',
    'THISYEAR',
    false
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (!scienceBasedShareData) return null;

  return (
    <StyledBaseSection {...props}>
      <Wrapper>
        <SustainabilityPieChart
          data={[
            { value: scienceBasedShareData.target, color: accent.forest1 },
            { value: 1 - scienceBasedShareData.target, color: accent.sand2 },
          ]}
        >
          <H2>
            {percentage(scienceBasedShareData.target * 100, 0, false, false)}
          </H2>
        </SustainabilityPieChart>
      </Wrapper>
      <H2>{t(`${i18nPrefix}:title`)}</H2>
      <Paragraph style={{ padding: `${SPACING_8} 0` }}>
        {t(`${i18nPrefix}:subtitle`)}
      </Paragraph>
      <TrendBadge
        title={[
          <strong style={{ fontWeight: 500 }}>
            {percentage(trend * 100, 2)}
          </strong>,
          ` ${t('filters:period:THISYEAR').toLowerCase()}`,
        ]}
        direction={trend > 0 ? 'Up' : 'Down'}
        positiveTrend={trend > 0}
      />
      <ButtonWrapper>
        <Button onClick={() => setModalIsOpen(true)} style={{ marginTop: 32 }}>
          {t('globals:readMore')}
        </Button>
      </ButtonWrapper>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={t('sustainability:scienceBasedTargetsScreen:title')}
        labelComponent={H2}
        padding={SPACING_48}
      >
        <ScienceBasedTargetsModalContent />
      </Modal>
    </StyledBaseSection>
  );
};
