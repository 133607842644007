import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { printCondition } from '../../constants/media';

const StyledPrintPageBreaker = styled.div`
  @media ${printCondition} {
    page-break-before: always;
  }
`;

export const PrintPageBreaker = () => {
  const print = useMediaQuery({
    query: printCondition,
  });

  return print && <StyledPrintPageBreaker />;
};
