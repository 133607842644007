import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import camelcase from 'camelcase';

import {
  percentage,
  t,
  selectors,
  localizeCountryName,
  formatBigMoney,
  formatMoney,
} from '@formue-app/core';

import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_120,
  SPACING_88,
  SPACING_48,
} from '../../../constants/spacing';

import { PortfolioCharacteristicsItem } from '../investmentStrategyComponents/PortfolioCharacteristicsItem';
import { H1, H2, Paragraph, ParagraphXSmall } from '../../texts';
import { PortfolioCharacteristicsItemPieChart } from '../investmentStrategyComponents/PortfolioCharacteristicsItemPieChart';
import { StretchedHorizontalBarChart } from '../../graphs/StretchedHorizontalBarChart';
import { SpriteIcon } from '../../common/SpriteIcon';
import { useSelector } from 'react-redux';
import { titleFontStack } from '../../../fonts';
import { useLocation } from 'react-router-dom';
import { GridElement, MainGrid } from '../../layout';
import { SlideHeader } from '../investmentStrategyExportComponents/SlideHeader';
import { accent } from '../../../constants/colors';

const Wrapper = styled(MainGrid)`
  flex: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: ${SPACING_120};
  margin-bottom: ${SPACING_88};
  max-width: 1232px;
`;

const Container = styled(GridElement)`
  display: flex;
  flex: 1;
  gap: ${SPACING_24};
  flex-wrap: wrap;
  margin-top: ${SPACING_48};
`;

const StyledPortfolioCharacteristicsItem = styled(PortfolioCharacteristicsItem)`
  border: 1px solid ${accent.sand2};
`;

const TotalCarbonFootprint = styled.div`
  display: flex;
  gap: ${SPACING_8};
  align-items: baseline;
  padding-bottom: 20px;
`;

const {
  entities: {
    investmentStrategyPortfolioCharacteristics: {
      investmentStrategyPortfolioCharacteristicsByStrategyIdSelector,
    },
    investmentStrategies: { investmentStrategyByIdSelector },
  },
  auth: { operatingCountrySelector },
} = selectors;

export const PortfolioCharacteristics = (props) => {
  const { isActiveSlide, title } = props;
  const { search } = useLocation();
  const strategyId = new URLSearchParams(search).get('strategyId');

  const operatingCountry = useSelector(operatingCountrySelector);
  const portfolioCharacteristics = useSelector(
    investmentStrategyPortfolioCharacteristicsByStrategyIdSelector(strategyId)
  );

  const strategy = useSelector(investmentStrategyByIdSelector(strategyId));

  const formuePortfolio =
    strategy && strategy.externalPortfolio
      ? strategy.totalPortfolio - strategy.externalPortfolio
      : strategy?.totalPortfolio;

  return (
    <Wrapper>
      <SlideHeader
        title={title}
        items={[
          {
            title: t(
              'advisor:strategySimulator:assetAllocation:totalPortfolio'
            ),
            value: strategy
              ? formatBigMoney(strategy.totalPortfolio, false)
              : '–',
          },
          // {
          //   title: t(
          //     'advisor:strategySimulator:assetAllocation:formuePortfolio'
          //   ),
          //   value: formatBigMoney(10000000),
          // },
          // {
          //   title: t(
          //     'advisor:strategySimulator:assetAllocation:externalPortfolio'
          //   ),
          //   value: formatBigMoney(10000000),
          // },
        ]}
      />
      {portfolioCharacteristics ? (
        <Container columns={12}>
          <StyledPortfolioCharacteristicsItem
            title={t(
              'advisor:strategySimulator:preferences:portfolioLiquidity'
            )}
            animate={isActiveSlide}
            icon={<SpriteIcon id="Investment Preferences" size={21} />}
          >
            <PortfolioCharacteristicsItemPieChart
              data={portfolioCharacteristics.liquidity.map((item) => ({
                ...item,
                group: t(
                  `advisor:strategySimulator:preferences:${camelcase(
                    item.group
                  )}`
                ),
              }))}
            />
          </StyledPortfolioCharacteristicsItem>
          <StyledPortfolioCharacteristicsItem
            title={t('advisor:strategySimulator:preferences:indexExlution')}
            animate={isActiveSlide}
            icon={<SpriteIcon id="Asset Management" size={21} />}
          >
            <PortfolioCharacteristicsItemPieChart
              data={portfolioCharacteristics.management.map((item) => ({
                ...item,
                group: t(
                  `advisor:strategySimulator:preferences:${camelcase(
                    item.group
                  )}`
                ),
              }))}
            />
          </StyledPortfolioCharacteristicsItem>
          <StyledPortfolioCharacteristicsItem
            title={t('advisor:strategySimulator:preferences:totalExpenseRatio')}
            animate={isActiveSlide}
            icon={<SpriteIcon id="Shares" size={21} />}
          >
            <H1 style={{ textAlign: 'center' }}>
              {percentage(portfolioCharacteristics.expenseRatio * 100, 2)}
            </H1>
            <Paragraph>
              {formatMoney(
                formuePortfolio * portfolioCharacteristics.expenseRatio,
                'KR'
              )}
            </Paragraph>
          </StyledPortfolioCharacteristicsItem>
          <StyledPortfolioCharacteristicsItem
            title={t('advisor:strategySimulator:preferences:carbonFootprint')}
            animate={isActiveSlide}
            icon={<SpriteIcon id="Sustainability" size={21} />}
            titleSpacing={SPACING_16}
          >
            <TotalCarbonFootprint>
              <ParagraphXSmall>
                <Trans
                  i18nKey="advisor:strategySimulator:preferences:tonco2"
                  values={{
                    carbonFootprint: parseInt(
                      portfolioCharacteristics.sustainability.carbonFootprint
                    ),
                  }}
                  components={{
                    strong: (
                      <H2
                        as="span"
                        style={{
                          fontFamily: titleFontStack,
                          fontWeight: 700,
                          display: 'inline',
                        }}
                      />
                    ),
                  }}
                />
              </ParagraphXSmall>
            </TotalCarbonFootprint>
            <StretchedHorizontalBarChart
              data={[
                {
                  label: t(
                    'advisor:strategySimulator:preferences:thisPortfolio'
                  ),
                  value: parseInt(
                    portfolioCharacteristics.sustainability.carbonFootprint
                  ),
                },
                {
                  label: t(
                    'advisor:strategySimulator:preferences:localization:country',
                    {
                      context: operatingCountry,
                    }
                  ),
                  value: parseInt(
                    portfolioCharacteristics.sustainability.domesticBenchmark
                  ),
                },
                {
                  label: t('advisor:strategySimulator:preferences:world'),
                  value: parseInt(
                    portfolioCharacteristics.sustainability.globalBenchmark
                  ),
                },
              ]}
              color={accent.forest490}
            />
            <ParagraphXSmall>
              {t('advisor:strategySimulator:preferences:coverage', {
                coverage: percentage(
                  portfolioCharacteristics.sustainability.coverage * 100
                ),
              })}
            </ParagraphXSmall>
          </StyledPortfolioCharacteristicsItem>
          <StyledPortfolioCharacteristicsItem
            title={t('advisor:strategySimulator:preferences:geography')}
            animate={isActiveSlide}
            icon={<SpriteIcon id="Position" size={21} />}
            titleSpacing={SPACING_16}
          >
            <StretchedHorizontalBarChart
              data={portfolioCharacteristics.geography.allocations
                .sort((a, b) => b.allocation - a.allocation)
                .slice(0, 5)
                .filter((item) => item.countryCode)
                .map((item) => ({
                  label: localizeCountryName(item.countryCode.toUpperCase()),
                  value: item.allocation,
                }))}
              formatValue={(value) => percentage(value * 100, 1)}
            />
            <ParagraphXSmall>
              {t('advisor:strategySimulator:preferences:coverage', {
                coverage: percentage(
                  portfolioCharacteristics.geography.coverage * 100
                ),
              })}
            </ParagraphXSmall>
          </StyledPortfolioCharacteristicsItem>
          <StyledPortfolioCharacteristicsItem
            title={t('advisor:strategySimulator:preferences:sectors')}
            animate={isActiveSlide}
            icon={<SpriteIcon id="Company" size={21} />}
            titleSpacing={SPACING_16}
          >
            <StretchedHorizontalBarChart
              data={portfolioCharacteristics.sector.allocations
                .sort((a, b) => b.allocation - a.allocation)
                .slice(0, 5)
                .filter((item) => item.sector)
                .map((item) => ({
                  label: t(`sectors:${camelcase(item.sector)}:title`),
                  value: item.allocation,
                }))}
              color={accent.raspberry490}
              formatValue={(value) => percentage(value * 100, 1)}
            />

            <ParagraphXSmall>
              {t('advisor:strategySimulator:preferences:coverage', {
                coverage: percentage(
                  portfolioCharacteristics.sector.coverage * 100
                ),
              })}
            </ParagraphXSmall>
          </StyledPortfolioCharacteristicsItem>
        </Container>
      ) : null}
    </Wrapper>
  );
};
