import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

import { H3, H4, H5 } from '../../../../texts';
import { EsgTag } from './EsgTag';
import { desktopCondition } from '../../../../../constants/media';
import { SPACING_8, SPACING_2 } from '../../../../../constants/spacing';

const StyledSlide = styled(animated.div)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  max-width: 160px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled(H3)`
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${SPACING_2} 0;
  @media ${desktopCondition} {
    font-size: 32px;
  }
`;

export const EsgTabItem = ({
  item,
  index,
  active,
  title,
  subtitle,
  heading,
  esgValue,
}) => {
  const slideStyles = useSpring(
    active
      ? {
          from: { opacity: 0 },
          to: { opacity: 1 },
          delay: 500,
          config: { duration: 400 },
        }
      : { opacity: 0 }
  );

  return (
    <StyledSlide style={slideStyles}>
      <StyledContent>
        {heading && <H4>{heading}</H4>}
        {title && <StyledTitle>{title}</StyledTitle>}
        {subtitle && <H5 style={{ paddingTop: SPACING_8 }}>{subtitle}</H5>}
        {esgValue && <EsgTag esgValue={esgValue} />}
      </StyledContent>
    </StyledSlide>
  );
};
