import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors } from '@formue-app/core';

import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';

import { MainGrid } from '../../layout';
import { PortfolioStrategyContent } from '../../portfolio/strategy/PortfolioStrategyContent';

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const {
  entities: {
    investmentProfile: { investmentProfileHasContent },
  },
} = selectors;

export const PortfolioStrategyDetails = (props) => {
  const { isActiveSlide } = props;

  const hasContent = useSelector(investmentProfileHasContent);

  if (!isActiveSlide) {
    return null;
  }

  return (
    <Wrapper>
      {hasContent && <PortfolioStrategyContent animate={false} />}
    </Wrapper>
  );
};
