import React from 'react';
import styled from 'styled-components';
import { truncate } from 'lodash';
import { decodeHTML } from 'entities';
import camelcase from 'camelcase';

import { t, humanFriendlyDate } from '@formue-app/core';

import { H4, H2Small } from '../texts';
import { Paragraph } from '../texts/Paragraph';
import { shortAnimationTime, easeInOut } from '../../constants/animation';
import { SPACING_24, SPACING_16 } from '../../constants/spacing';
import { mobileCondition } from '../../constants/media';
import { backgroundWhite, hoverBoxShadowLvl1 } from '../../constants/colors';
import { BORDER_RADIUS_LARGE, SPACING_8 } from '../../constants/spacing';
import { StyledGridElement } from '../layout/GridElement';
import { paragraphSmallSize } from '../../constants/text';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';

const ImageWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 200px;
  border-radius: ${BORDER_RADIUS_LARGE} ${BORDER_RADIUS_LARGE} 0px 0px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: scale ${shortAnimationTime} ${easeInOut};
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  height: 100%;
  display: block;

  &:hover {
    img {
      scale: 1.05;
    }

    h4 svg {
      left: 3px;
    }
  }
`;

const Heading = styled(H2Small)`
  margin-bottom: ${SPACING_8};
`;

const Ingress = styled(Paragraph)`
  padding-bottom: 40px;
`;

const StyledH4 = styled(H4)`
  position: absolute;
  bottom: ${SPACING_24};

  svg {
    vertical-align: initial;
    height: 13px;
    width: 13px;
    margin-left: 4px;
    top: 2px;
    left: 0;
    position: relative;
    transition: left ${shortAnimationTime} ${easeInOut};
    vertical-align: baseline;
  }
`;

const StyledCategory = styled(Paragraph)`
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
  margin-bottom: ${SPACING_8};
  align-items: center;
  word-break: break-word;
  font-size: ${paragraphSmallSize};

  span {
    white-space: nowrap;
  }
`;

const StyledPublishedDate = styled.span`
  white-space: nowrap;
`;

const StyledSeparator = styled.span`
  position: relative;
`;

const StyledArticle = styled(StyledGridElement)`
  background: ${backgroundWhite};
  border-radius: ${BORDER_RADIUS_LARGE};
  position: relative;
  cursor: pointer;
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  &:hover {
    box-shadow: ${hoverBoxShadowLvl1};
  }
`;

const StyledText = styled.div`
  padding: ${SPACING_24};

  @media ${mobileCondition} {
    padding: ${SPACING_16};
  }
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const getCategoriesAsString = (categories, translate) => {
  if (!categories) return '';
  const decodedCategories = categories.map((category) => {
    if (!translate) return decodeHTML(category);
    return t(`eventCalendar:feedTypes:${camelcase(decodeHTML(category))}`);
  });
  return decodedCategories.join(' / ');
};

export const BaseFeedItem = (props) => {
  const {
    columns,
    className,
    title,
    categories,
    translateCategories,
    image,
    imageFallback,
    url,
    description,
    onClick,
    date,
    icon,
  } = props;

  const category = getCategoriesAsString(categories, translateCategories);
  const humanDate = humanFriendlyDate(date);

  return (
    <StyledArticle
      columns={columns}
      className={className}
      as="article"
      onClick={onClick}
    >
      <StyledLink href={!onClick && url ? url : null} target="_blank">
        <ImageWrapper>
          <img
            src={image ? image : imageFallback}
            alt={title}
            onError={(e) => {
              e.target.onerror = null;
              if (imageFallback) e.target.src = imageFallback.default;
            }}
          />
        </ImageWrapper>

        <StyledText>
          <StyledCategory>
            {icon && <IconWrapper>{icon}</IconWrapper>}
            {category}
            <StyledSeparator>|</StyledSeparator>
            <StyledPublishedDate>{humanDate}</StyledPublishedDate>
          </StyledCategory>
          <Heading>{decodeHTML(title)}</Heading>
          <Ingress>
            {description
              ? truncate(decodeHTML(description), {
                  length: 140,
                  separator: /,? +/,
                })
              : null}
          </Ingress>
          <StyledH4>
            {t('dashboard:news:readMore')}
            <SpriteIconFunctional id="Simple arrow" direction="Right" />
          </StyledH4>
        </StyledText>
      </StyledLink>
    </StyledArticle>
  );
};
