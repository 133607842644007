import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { formatEsgChartsData, selectors, t } from '@formue-app/core';
import { BaseSectionWithTitle } from '../../../common/BaseSectionWithTitle';
import { H2, Paragraph, H4 } from '../../../texts';
import { accent, textLink, primary } from '../../../../constants/colors';
import { EsgPieChart } from './EsgPieChart';
import { EsgTabs } from './tabs/EsgTabs';
import { SPACING_16, SPACING_8 } from '../../../../constants/spacing';
import { notDesktopCondition } from '../../../../constants/media';

const StyledBaseSectionWithTitle = styled(BaseSectionWithTitle)`
  @media ${notDesktopCondition} {
    background: transparent;
  }
`;

const StyledH4 = styled(H4)`
  color: ${textLink};
  margin-top: ${SPACING_16};
`;

const StyledLink = styled.button`
  text-decoration: none;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const {
  entities: {
    esgData: { esgDataSelector },
  },
} = selectors;

export const Score = ({ onClick, ...props }) => {
  const esgData = useSelector(esgDataSelector);
  const [activeDatasetIndex, setActiveDatasetIndex] = useState(0);

  let chartsData;
  try {
    chartsData = formatEsgChartsData(esgData.qualityScore, primary, accent);
  } catch (error) {
    return null;
  }
  const activeChartsData = chartsData[activeDatasetIndex];

  return (
    <StyledBaseSectionWithTitle {...props} style={{ textAlign: 'center' }}>
      <H2 style={{ marginBottom: SPACING_8 }}>
        {t('esg:esgScore:aboutSustainability:title')}
      </H2>
      <Paragraph>{t('esg:esgScore:aboutSustainability:subtitle')}</Paragraph>
      <StyledLink
        onClick={() => {
          onClick();
        }}
      >
        <StyledH4>{t('esg:esgScore:aboutSustainability:readMore')}</StyledH4>
      </StyledLink>
      <div style={{ position: 'relative', top: -10 }}>
        <div style={{ height: 375, alignItems: 'center' }}>
          <EsgPieChart
            {...activeChartsData.esg}
            style={{ position: 'absolute', top: 0, width: '100%', height: 400 }}
            padAngle={0}
            labelPosition="endAngle"
          />
          <EsgPieChart
            {...activeChartsData.scale}
            style={{ position: 'absolute', top: 0, width: '100%', height: 400 }}
            labelColor="#C7C7C7"
          />
        </div>
        <div
          style={{
            position: 'absolute',
            zIndex: 2,
            left: 0,
            top: 0,
            right: 0,
          }}
        >
          <EsgTabs
            height={390}
            activeItemCallback={(index) =>
              setTimeout(() => {
                setActiveDatasetIndex(index);
              }, 600)
            }
          />
        </div>
      </div>
    </StyledBaseSectionWithTitle>
  );
};
