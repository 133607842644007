export const combineCashflowReportsData = (data, includeExternal) => {
  const combinedData = [...data];

  if (includeExternal) {
    combinedData.forEach((cashflow) => {
      cashflow.amount += cashflow.externalAmount;
    });
  }

  return combinedData;
};
