import React from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';

import { layoutMaxWitdh } from './grid';

export const tabletWidth = 768;
export const desktopWidth = 1024;
export const desktopWidthMax = layoutMaxWitdh;

export const printCondition = 'only print';
export const notPrintCondition = 'not print';
export const mobileCondition = `(max-width: ${tabletWidth - 1}px)`;
export const notDesktopCondition = `(max-width: ${desktopWidth}px)`;
export const notMobileCondition = `(min-width: ${tabletWidth}px)`;
export const tabletCondition = `(min-width: ${tabletWidth}px) and (max-width: ${desktopWidth}px)`;
export const desktopCondition = `(min-width: ${desktopWidth + 1}px)`;
export const desktopMaxCondition = `(max-width: ${desktopWidthMax}px)`;

export const NotMobileWrapper = (props) => (
  <MediaQuery query={notMobileCondition}>{props.children}</MediaQuery>
);

export const DesktopWrapper = (props) => (
  <MediaQuery query={desktopCondition}>{props.children}</MediaQuery>
);

export const TabletWrapper = (props) => (
  <MediaQuery query={tabletCondition}>{props.children}</MediaQuery>
);

export const NotDesktopWrapper = (props) => (
  <MediaQuery query={notDesktopCondition}>{props.children}</MediaQuery>
);

export const MobileWrapper = (props) => (
  <MediaQuery query={mobileCondition}>{props.children}</MediaQuery>
);

// For some reason using the <MediaQuery> component for print does not work when using
// window.print to trigger print preview. So we have to resort to using good old html and css.
const PrintWrapperStyles = styled.div`
  display: none;

  @media ${printCondition} {
    display: block !important;
  }
`;

export const PrintWrapper = ({ children }) => (
  <PrintWrapperStyles>{children}</PrintWrapperStyles>
);
