import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectors,
  t,
  ratingMomentumLevel,
  generateSpeedometerTrendChartColors,
} from '@formue-app/core';

import { SPACING_48 } from '../../../../constants/spacing';

import { ParagraphSmall, Paragraph } from '../../../texts';
import { SpeedometerChart } from '../../../graphs/SpeedometerChart';
import { BaseSectionWithTitle } from '../../../common/BaseSectionWithTitle';

import { SpriteIcon } from '../../../common/SpriteIcon';
import { accent } from '../../../../constants/colors';

const {
  entities: {
    esgData: { esgDataSelector },
  },
} = selectors;

export const Trend = ({ ...props }) => {
  const esgData = useSelector(esgDataSelector);
  const value = ratingMomentumLevel(esgData.ratingMomentum);
  let title;
  if (value < 2) {
    title = t('esg:trend:lessSustainability');
  } else if (value === 2) {
    title = t('esg:trend:sameSustainability');
  } else {
    title = t('esg:trend:moreSustainability');
  }

  return (
    <BaseSectionWithTitle
      label="Trend"
      title={title}
      icon={
        <SpriteIcon
          id="Risk"
          color={accent.forest3}
          accentColor={accent.forest190}
        />
      }
      {...props}
    >
      <ParagraphSmall style={{ paddingTop: 12 }}>
        {t('esg:trend:expanded:text')}
      </ParagraphSmall>
      <SpeedometerChart
        value={value}
        width={145}
        height={100}
        colorScale={generateSpeedometerTrendChartColors(value)}
        style={{ marginTop: SPACING_48, marginBottom: SPACING_48 }}
      />
      <Paragraph
        style={{
          maxWidth: '275px',
        }}
      >
        {t(`esg:trend:expanded:${value}`)}
      </Paragraph>
    </BaseSectionWithTitle>
  );
};
