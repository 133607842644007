import React from 'react';

import { t } from '@formue-app/core';
import { EsgExplained } from './EsgExplained';
import { EsgLevels } from './EsgLevels';
import { HorizontalDivider } from '../../../../common/HorizontalDivider';
import { Paragraph, H3 } from '../../../../texts';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_2,
} from '../../../../../constants/spacing';

export const EsgScoreReadMore = (props) => {
  return (
    <>
      <Paragraph style={{ marginTop: SPACING_24, marginBottom: SPACING_8 }}>
        {t('esg:esgScoreReadMore:paragraph1')}
      </Paragraph>
      <EsgExplained
        style={{ marginTop: SPACING_2, marginBottom: SPACING_16 }}
      />
      <Paragraph>{t('esg:esgScoreReadMore:paragraph2')}</Paragraph>
      <HorizontalDivider
        style={{ marginTop: SPACING_24, marginBottom: SPACING_24 }}
      />
      <H3 style={{ paddingBottom: SPACING_8 }}>
        {t('esg:esgScoreReadMore:subtitle')}
      </H3>
      <Paragraph style={{ paddingBottom: SPACING_8 }}>
        {t('esg:esgScoreReadMore:paragraph3')}
      </Paragraph>
      <EsgLevels />
    </>
  );
};
