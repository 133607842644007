import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { t, selectors, localizeFloat, percentage } from '@formue-app/core';
import { notDesktopCondition } from '../../../../constants/media';
import { BaseSection } from '../../../common/BaseSection';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_8,
} from '../../../../constants/spacing';
import { ModalSection } from './ModalSection';
import { H1, H2Small, Paragraph, ParagraphLarge } from '../../../texts';
import { Thermometer } from './Thermometer';
import { SustainabilityLineChart } from './SustainabilityLineChart';
import { ContributionToParisAgreement } from './ContributionToParisAgreement';
import { ContributionToParisAgreementChart } from './ContributionToParisAgreementChart';
import { SustainabilityAdvisor } from './SustainabilityAdvisor';
import { Coverage } from './Coverage';
import { ComparisonWithOthers } from './ComparisonWithOthers';
import { accent } from '../../../../constants/colors';

const i18nPrefix = 'sustainability';

const {
  entities: {
    portfolioLenses: {
      temperatureRiseDataSelector,
      temperatureRiseTimelineSelector,
      portfolioLensesMetadataSelector,
    },
  },
} = selectors;

const StyledWrapper = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${SPACING_16} 0;
  flex-flow: column nowrap;
  gap: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  @media ${notDesktopCondition} {
    flex-flow: column;
    padding: 0;
  }
`;

const SectionTitle = styled(H2Small)`
  padding-bottom: ${SPACING_8};
`;

const RowWrapper = styled.div`
  display: flex;
  gap: ${SPACING_12};
  flex: 1;
  width: 100%;
`;

const StyledList = styled.ol`
  margin: 0;
  padding-left: ${SPACING_16};
  padding-top: ${SPACING_16};
  list-style: auto !important;
  > li {
    margin-bottom: ${SPACING_12};
    &:last-child: {
      margin-bottom: 0;
    }
  }
`;

export const TemperatureRiseModalContent = (props) => {
  const temperatureRiseData = useSelector(temperatureRiseDataSelector);
  const temperatureRiseTimeline = useSelector(temperatureRiseTimelineSelector);
  const portfolioLensesMetadata = useSelector(portfolioLensesMetadataSelector);

  if (!temperatureRiseData) return null;

  const global = portfolioLensesMetadata.benchmark?.temperatureRise?.global;
  const domestic = portfolioLensesMetadata.benchmark?.temperatureRise?.domestic;

  return (
    <StyledWrapper>
      <div>
        <H1 style={{ paddingBottom: 8 }}>{`+${localizeFloat(
          temperatureRiseData.impliedRise,
          1,
          true
        )}°C`}</H1>
        <ParagraphLarge style={{ paddingBottom: 16 }}>
          {t(`${i18nPrefix}:temperatureRiseScreen:subtitle`)}
        </ParagraphLarge>
      </div>
      <RowWrapper>
        <ModalSection highlighted>
          <SustainabilityLineChart
            style={{ marginTop: SPACING_32 }}
            value={localizeFloat(temperatureRiseData.impliedRise, 1, true)}
            data={temperatureRiseTimeline}
            trendSelector="temperature.impliedRise"
            positiveTrend={(trend) => trend < 0}
            trendInPercentage={false}
            formatTrendValue={(value) => `${localizeFloat(value, 2)}°C`}
          />
        </ModalSection>
        <ModalSection highlighted>
          <SectionTitle>{t(`${i18nPrefix}:thermometer:title`)}</SectionTitle>
          <Paragraph style={{ whiteSpace: 'pre-line' }}>
            {t(`${i18nPrefix}:temperatureRiseScreen:subtitle`)}
          </Paragraph>
          <Thermometer
            pointer={temperatureRiseData.impliedRise}
            style={{ marginTop: SPACING_32 }}
          />
        </ModalSection>
      </RowWrapper>
      <ModalSection maxWidth={1200}>
        <SectionTitle style={{ paddingBottom: SPACING_24 }}>
          {t(`${i18nPrefix}:thermometer:parisAgreement`)}
        </SectionTitle>

        <RowWrapper>
          <ContributionToParisAgreement style={{ flex: 1 }} />
          <ContributionToParisAgreementChart style={{ width: 300 }} />
        </RowWrapper>
      </ModalSection>
      <RowWrapper>
        <ModalSection>
          <SectionTitle style={{ paddingBottom: SPACING_16 }}>
            {t(`${i18nPrefix}:accordions:impact:title`)}
          </SectionTitle>
          <Paragraph style={{ whiteSpace: 'pre-line' }}>
            {t(`${i18nPrefix}:accordions:impact:temperatureRise`)}
          </Paragraph>
          <SustainabilityAdvisor />
        </ModalSection>
        {global && domestic ? (
          <ModalSection>
            <SectionTitle>
              {t(`${i18nPrefix}:accordions:comparisonWithOthers:title`)}
            </SectionTitle>
            <ComparisonWithOthers
              global={global}
              domestic={domestic}
              value={temperatureRiseData.impliedRise}
              formatValue={(value) => `${localizeFloat(value, 1, true)}°C`}
              chartColor={accent.ocean230}
              maxWidth={400}
            />
          </ModalSection>
        ) : null}
      </RowWrapper>
      <ModalSection>
        <SectionTitle style={{ paddingBottom: SPACING_16 }}>
          {t(`${i18nPrefix}:accordions:calculation:temperatureRiseTitle`)}
        </SectionTitle>
        <Paragraph style={{ whiteSpace: 'pre-line' }}>
          {t(`${i18nPrefix}:accordions:calculation:temperatureRise`)}
        </Paragraph>
        <StyledList>
          {t(`${i18nPrefix}:accordions:calculation:temperatureRiseItems`, {
            returnObjects: true,
          }).map((item, index) => (
            <li>
              <Paragraph>{item}</Paragraph>
            </li>
          ))}
        </StyledList>
      </ModalSection>
      <Coverage coverage={temperatureRiseData.companiesCoverage} />
    </StyledWrapper>
  );
};
