import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [meetingsAllIds, meetingsById, allMeetingsSelector] =
  resourceSelectors('meetings');

export const upcomingMeetingsSelector = createSelector(
  allMeetingsSelector,
  (allIdMeetingss) => {
    return allIdMeetingss.filter((item) => new Date(item.startAt) > new Date());
  }
);

export const pastMeetingsSelector = createSelector(
  allMeetingsSelector,
  (allIdMeetingss) => {
    return allIdMeetingss.filter((item) => new Date(item.startAt) < new Date());
  }
);
