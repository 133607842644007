import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { t } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { HorizontalDivider } from '../../common/HorizontalDivider';
import { VisibleInCountry } from '../../common/VisibleInCountry';
import { H4, Paragraph, ParagraphSmall } from '../../texts';
import { Input, Range, Toggle } from '../../formElements';

import { bodyFontStack } from '../../../fonts';
import { paragraphSize } from '../../../constants/text';
import { accent, uiActive } from '../../../constants/colors';
import { SPACING_16, SPACING_8 } from '../../../constants/spacing';

import { ReactComponent as ResetIcon } from '../../../assets/icons/advisor-corner/reset-icon.svg';
import { ActivityIndicator } from '../../common/ActivityIndicator';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const Row = styled.div``;

const RowHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SliderGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const StyledInput = styled(Input)`
  border: 1px solid ${accent.ocean220};
  width: 100%;
`;

const PercentWrapper = styled.div`
  position: relative;

  &:after {
    display: block;
    content: '%';
    position: absolute;
    top: 6px;
    right: 10px;
    font-family: ${bodyFontStack};
    font-size: ${paragraphSize};
  }
`;

const PercentInput = styled(StyledInput)`
  min-width: 55px;
  width: 65px;
  text-align: right;
  padding: 5px 8px;
  padding-right: 25px;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const StyledRange = styled(Range)`
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;

      &::-webkit-slider-thumb,
      &::-moz-range-thumb {
        cursor: default;
      }
    `}
`;

const StyledToggle = styled(Toggle)`
  justify-content: space-between;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const ResetButton = styled(ParagraphSmall)`
  color: ${uiActive};
  font-weight: 500;
  cursor: pointer;
  margin-top: 3px;

  svg {
    margin-right: ${SPACING_8};
    margin-top: -2px;
    vertical-align: middle;
  }

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  margin: auto;
`;

export const StrategyPreferencesSettings = (props) => {
  const { disabled, strategy } = props;

  const [indexExlution, setIndexExlution] = useState(0);
  const [sustainableInvestments, setSustainableInvestments] = useState(0);
  const [MTAOfHedgefond, setMTAOfHedgefond] = useState(0);
  const [coreOfRealEstate, setCoreOfRealEstate] = useState(0);
  const [highYieldOfBonds, setHighYieldOfBonds] = useState(0);
  const [currencyHedgingEquities, setCurrencyHedgingEquities] = useState(false);

  useEffect(() => {
    if (strategy) {
      setIndexExlution(strategy?.preferences?.index * 100);
      setSustainableInvestments(strategy?.preferences?.sustainability * 100);
      setMTAOfHedgefond(strategy?.preferences?.mtaShare * 100);
      setCoreOfRealEstate(strategy?.preferences?.coreShare * 100);
      setHighYieldOfBonds(strategy?.preferences?.highYieldShare * 100);
      setCurrencyHedgingEquities(strategy?.preferences?.currencyHedging);
    }
  }, [strategy]);

  return (
    <>
      <StyledBaseSection columns={4}>
        {!strategy ? (
          <StyledActivityIndicator />
        ) : (
          <>
            <SliderGroup>
              <H4>
                {t('advisor:strategySimulator:preferences:clientPreferences')}
              </H4>
              <Row>
                <RowHeader>
                  <Paragraph>
                    {t('advisor:strategySimulator:preferences:indexExlution')}
                  </Paragraph>
                  <PercentWrapper>
                    <PercentInput value={indexExlution} disabled={disabled} />
                  </PercentWrapper>
                </RowHeader>
                <StyledRange
                  value={indexExlution}
                  onChange={(val) => setIndexExlution(val)}
                  disabled={disabled}
                />
              </Row>
              <Row>
                <RowHeader>
                  <Paragraph>
                    {t(
                      'advisor:strategySimulator:preferences:sustainableInvestments'
                    )}
                  </Paragraph>
                  <PercentWrapper>
                    <PercentInput
                      value={sustainableInvestments}
                      disabled={disabled}
                    />
                  </PercentWrapper>
                </RowHeader>
                <StyledRange
                  value={sustainableInvestments}
                  onChange={(val) => setSustainableInvestments(val)}
                  disabled={disabled}
                  max={30}
                />
              </Row>
            </SliderGroup>

            <HorizontalDivider />

            <SliderGroup>
              <H4>
                {t(
                  'advisor:strategySimulator:preferences:spesificInvestmentPreferences'
                )}
              </H4>
              <Row>
                <RowHeader>
                  <Paragraph>
                    {t('advisor:strategySimulator:preferences:hedgeFund')}
                  </Paragraph>
                  <PercentWrapper>
                    <PercentInput value={MTAOfHedgefond} disabled={disabled} />
                  </PercentWrapper>
                </RowHeader>
                <StyledRange
                  value={MTAOfHedgefond}
                  onChange={(val) => setMTAOfHedgefond(val)}
                  disabled={disabled}
                  max={60}
                  // step={10}
                />
              </Row>
              <VisibleInCountry country={['no', 'dk']}>
                <Row>
                  <RowHeader>
                    <Paragraph>
                      {t('advisor:strategySimulator:preferences:realEstate')}
                    </Paragraph>
                    <PercentWrapper>
                      <PercentInput
                        value={coreOfRealEstate}
                        disabled={disabled}
                      />
                    </PercentWrapper>
                  </RowHeader>
                  <StyledRange
                    value={coreOfRealEstate}
                    onChange={(val) => setCoreOfRealEstate(val)}
                    disabled={disabled}
                  />
                </Row>
              </VisibleInCountry>
              <Row>
                <RowHeader>
                  <Paragraph>
                    {t('advisor:strategySimulator:preferences:highYield')}
                  </Paragraph>
                  <PercentWrapper>
                    <PercentInput
                      value={highYieldOfBonds}
                      disabled={disabled}
                    />
                  </PercentWrapper>
                </RowHeader>
                <StyledRange
                  value={highYieldOfBonds}
                  onChange={(val) => setHighYieldOfBonds(val)}
                  disabled={disabled}
                  max={40}
                />
              </Row>
              <VisibleInCountry country={['no', 'dk']}>
                <Row>
                  <StyledToggle
                    label={t(
                      'advisor:strategySimulator:preferences:curencyHedge'
                    )}
                    value={currencyHedgingEquities}
                    onChange={() =>
                      setCurrencyHedgingEquities(!currencyHedgingEquities)
                    }
                    disabled={disabled}
                    small={true}
                  />
                </Row>
              </VisibleInCountry>
            </SliderGroup>

            <HorizontalDivider />
            <ResetButton onClick={() => {}} disabled={disabled}>
              <ResetIcon />
              {t('advisor:strategySimulator:preferences:reset')}
            </ResetButton>
          </>
        )}
      </StyledBaseSection>
    </>
  );
};
