import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import {
  t,
  percentage,
  getAssetClassName,
  getAssetClassGroupByAssetClass,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  SPACING_24,
  SPACING_16,
  BORDER_RADIUS_SMALL,
  SPACING_8,
} from '../../../constants/spacing';
import { easeInOut, longAnimationTime } from '../../../constants/animation';
import {
  DesktopWrapper,
  mobileCondition,
  MobileWrapper,
  notDesktopCondition,
} from '../../../constants/media';
import { H4, ParagraphSmall } from '../../texts';

import limitBorderUrl from '../../../assets/icons/portfolio/strategy-limit-border.svg';
import limitBorderLightUrl from '../../../assets/icons/portfolio/strategy-limit-border-light.svg';

import { ReactComponent as PyramidS } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-stability-icon-small.svg';
import { ReactComponent as PyramidMV } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-market-growth-icon-small.svg';
import { ReactComponent as PyramidAV } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-alternative-growth-icon-small.svg';
import {
  BoundariesLabel,
  CurrentDistributionLabel,
  StrategyLabel,
} from './PortfolioStrategyLabels';

const ItemOverview = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: ${SPACING_24};
  width: 100%;
  position: relative;
  padding: ${SPACING_16} 0;
  z-index: 1;

  @media ${notDesktopCondition} {
    align-items: center;
  }

  @media ${mobileCondition} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
`;

const Title = styled(H4)`
  width: 100%;
  margin-left: ${SPACING_8};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 420px;
  justify-content: space-between;

  ${(props) =>
    !props.showOverviewDetails &&
    css`
      width: 200px;
    `}

  @media ${notDesktopCondition} {
    width: 200px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    height: 19px;
  }
`;

const StyledBar = styled.div`
  height: 16px;
  width: calc(100% - 420px);
  background-color: ${(props) =>
    props.barColor ? props.barColor : accent.sand1};
  margin-bottom: 6px;

  ${(props) =>
    !props.showOverviewDetails &&
    css`
      width: calc(100% - 200px);
    `}

  @media ${notDesktopCondition} {
    width: calc(100% - 200px);
    margin-bottom: 0px;
  }

  @media ${mobileCondition} {
    height: 10px;
    width: 100%;
  }
`;

const limit = (props) => keyframes`
  from {
    width: 0%;
    left: 0%;
  }

  to {
    width: ${Math.min(props.width, 100 - props.limitStart)}%;
    left: ${props.limitStart}%;
  }
`;

const Limit = styled.div`
  height: 100%;
  background: none;
  position: relative;
  width: ${(props) => Math.min(props.width, 100 - props.limitStart)}%;
  left: ${(props) => props.limitStart}%;
  z-index: 2;
  border-width: 2px;
  border-style: solid;
  border-image-source: url(${(props) =>
    props.light ? limitBorderLightUrl : limitBorderUrl});
  border-image-slice: 8;
  border-image-width: 8px;
  border-image-repeat: round;

  ${(props) =>
    props.animate &&
    css`
      width: 0%;
      left: 0%;
      animation: ${(props) => limit(props)} ${longAnimationTime} ${easeInOut}
        forwards;
      animation-delay: ${(props) => props.customAnimationDelay}s;
    `}

  &:after {
    content: '';
    height: 24px;
    width: 2px;
    background: ${(props) => (props.light ? accent.velvet250 : 'black')};
    border-radius: 1px;
    top: -5px;
    left: ${(props) => props.strategyPosition}%;
    position: relative;
    display: block;
    translate: -1px;

    @media ${mobileCondition} {
      height: 18px;
    }
  }
`;

const slide = (props) => keyframes`
  0% {
    width: 0%;
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    width: ${props.market}%;
  }
`;

const Current = styled.div`
  width: ${(props) => props.market}%;
  height: 16px;
  top: -16px;
  background-color: ${(props) => props.barColor};
  position: relative;
  border-radius: ${BORDER_RADIUS_SMALL};
  min-width: 3px;

  ${(props) =>
    props.animate &&
    css`
      width: 0%;
      animation: ${(props) => slide(props)} ${longAnimationTime} ${easeInOut}
        forwards;
      animation-delay: ${(props) => props.customAnimationDelay}s;
    `};

  @media ${mobileCondition} {
    height: 10px;
    top: -10px;
  }
`;

const ItemDetails = styled.div`
  display: flex;
  position: relative;
  padding: ${SPACING_16} 0;
  width: 100%;
  border-top: 1px solid ${accent.sand3};

  & > * {
    width: 25%;
    text-align: right;
  }
`;

const ItemDetailsMobile = styled.div`
  width: 100%;
  margin-top: ${SPACING_24};

  & > div {
    margin-bottom: ${SPACING_8};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Bar = (props) => {
  const {
    max,
    min,
    strategy,
    market,
    index = 0,
    customAnimationDelay = 0,
    runAnimation = true,
    barInsideColor = accent.velvet3,
    barOutsideColor = accent.velvet2,
    ...rest
  } = props;

  const limitWidth = max - min;
  const isInside = market >= min && market <= max;

  const barColor = isInside ? barInsideColor : barOutsideColor;

  return (
    <StyledBar {...rest}>
      <Limit
        animate={runAnimation}
        customAnimationDelay={customAnimationDelay + 0.2 * index}
        strategyPosition={((strategy - min) / limitWidth) * 100}
        width={limitWidth}
        limitStart={min}
        {...rest}
      />
      <Current
        animate={runAnimation}
        customAnimationDelay={customAnimationDelay + 0.3 + 0.2 * index}
        market={market}
        index={index}
        barColor={barColor}
      />
    </StyledBar>
  );
};

export const Overview = (props) => {
  const {
    assetClass,
    upperLimit,
    lowerLimit,
    strategyShare,
    marketShare,
    index,
    showOverviewDetails = true,
    textColor,
    maxValue,
    openDetails,
    ...rest
  } = props;

  const assetClassGroupIcons = (assetClassGroup) => {
    switch (assetClassGroup) {
      case 'S':
        return <PyramidS />;
      case 'MV':
        return <PyramidMV />;
      case 'AV':
        return <PyramidAV />;
      default:
        return null;
    }
  };

  // We have to multiply by 100 to get full percentage values to be used in css
  const max = (upperLimit * 100) / maxValue;
  const min = (lowerLimit * 100) / maxValue;
  const strategy = (strategyShare * 100) / maxValue;
  const market = (marketShare * 100) / maxValue;

  return (
    <ItemOverview {...rest}>
      <TitleWrapper showOverviewDetails={showOverviewDetails}>
        <IconWrapper>
          {assetClassGroupIcons(getAssetClassGroupByAssetClass(assetClass))}
          <Title style={{ color: textColor }}>
            {getAssetClassName(assetClass)}
          </Title>
        </IconWrapper>
        {showOverviewDetails ? (
          <DesktopWrapper>
            <ParagraphSmall>
              {`${t(
                'portfolio:portfolioStrategy:currentDistribution'
              )} ${percentage(marketShare * 100, 1)}`}
            </ParagraphSmall>
            <ParagraphSmall>
              {`${t('portfolio:portfolioStrategy:strategy')} ${percentage(
                strategyShare * 100,
                1
              )}`}
            </ParagraphSmall>
            <ParagraphSmall>
              {`${t('portfolio:portfolioStrategy:boundaries')} ${Math.round(
                lowerLimit * 100,
                1
              )}-${percentage(upperLimit * 100, 1)}`}
            </ParagraphSmall>
          </DesktopWrapper>
        ) : null}
      </TitleWrapper>
      <Bar
        max={max}
        min={min}
        strategy={strategy}
        market={market}
        index={index}
        showOverviewDetails={showOverviewDetails}
        {...rest}
      />

      <MobileWrapper>
        {openDetails ? <MobileDetails {...props} /> : null}
      </MobileWrapper>
    </ItemOverview>
  );
};

const Details = (props) => {
  const {
    assetClass,
    upperLimit,
    lowerLimit,
    strategyShare,
    marketShare,
    ...rest
  } = props;

  return (
    <ItemDetails {...rest}>
      <Title style={{ textAlign: 'left' }}>
        {getAssetClassName(assetClass)}
      </Title>
      <ParagraphSmall>{percentage(marketShare * 100)}</ParagraphSmall>
      <ParagraphSmall>{percentage(strategyShare * 100)}</ParagraphSmall>
      <ParagraphSmall style={{ paddingRight: SPACING_24 }}>{`${Math.round(
        lowerLimit * 100
      )}-${percentage(upperLimit * 100)}`}</ParagraphSmall>
    </ItemDetails>
  );
};

const MobileDetails = (props) => {
  const {
    assetClass,
    upperLimit,
    lowerLimit,
    strategyShare,
    marketShare,
    textColor,
    light,
    ...rest
  } = props;

  return (
    <ItemDetailsMobile {...rest}>
      <CurrentDistributionLabel
        value={percentage(marketShare * 100)}
        textColor={textColor}
        light={light}
      />
      <StrategyLabel
        value={percentage(strategyShare * 100)}
        textColor={textColor}
        light={light}
      />
      <BoundariesLabel
        value={`${Math.round(lowerLimit * 100)}-${percentage(
          upperLimit * 100
        )}`}
        textColor={textColor}
        light={light}
      />
    </ItemDetailsMobile>
  );
};

export const PortfolioStrategyItem = (props) => {
  const { dataExtractor } = props;

  switch (dataExtractor) {
    case 'overview':
      return <Overview {...props} />;
    case 'details':
      return <Details {...props} />;
    default: {
      return null;
    }
  }
};
