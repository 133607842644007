import React from 'react';
import styled from 'styled-components';

import { accent } from '../../../../../constants/colors';
import { H4Size, paragraphSmallSize } from '../../../../../constants/text';
import {
  BORDER_RADIUS_SMALL,
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_2,
} from '../../../../../constants/spacing';

import { H2, H3, Paragraph, ParagraphSmall } from '../../../../texts';
import { BaseSection } from '../../../../common/BaseSection';

const StyledContainer = styled(BaseSection)`
  padding: 0;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: ${SPACING_16} 0;
`;

const StyledTitle = styled(Paragraph)`
  font-size: ${H4Size};
  line-height: ${SPACING_24};
  padding-bottom: ${SPACING_2};
`;

const StyledThumbnail = styled.div`
  display: flex;
  height: 85px;
  width: 85px;
  margin-right: ${SPACING_16};
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(H2)`
  display: inline-flex;
  font-size: ${paragraphSmallSize};
  line-height: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_SMALL};
  padding: 4px 6px;
  margin-right: ${SPACING_8};
  line-height: 1;
`;

const StyledRow = styled.div`
  flex-direction: row;
  align-items: center;
  padding-bottom: ${SPACING_16};
`;

export const EsgLevel = ({
  title,
  color = accent.ocean2,
  labelColor = accent.ocean4,
  value,
  text,
  icon,
  ...rest
}) => {
  return (
    <StyledContainer {...rest}>
      <StyledHeader>
        <StyledThumbnail style={{ backgroundColor: color }}>
          {icon}
        </StyledThumbnail>
        <div style={{ flex: 1 }}>
          <StyledTitle>{title}</StyledTitle>
          <StyledRow>
            <StyledIcon style={{ backgroundColor: labelColor }}>ESG</StyledIcon>
            <H3 style={{ fontSize: 12, display: 'inline-flex' }}>{value}</H3>
          </StyledRow>
        </div>
      </StyledHeader>
      <ParagraphSmall>{text}</ParagraphSmall>
    </StyledContainer>
  );
};
