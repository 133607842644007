import { call, put, delay, takeLatest, select } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { esgData } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { waitFor } from '../../../services';
import { activeFiltersSelector } from '../../ui/portfolio/selectors';

// Create actions and a reducer for the expertAdvice resource
export const [actions, reducer, resourceSagas] = resourceReducer('esg-data');

// Create the sagas for this entity
function* readEsgData() {
  yield takeLatest(actions.indexRequestBegin, function* ({ payload }) {
    yield call(waitFor, (state) => state.ui.portfolio.initialized);
    const activeFilters = yield select(activeFiltersSelector);
   const args = {
        ssids: activeFilters.ssids,
        taxClass: activeFilters.taxClass,
        showInternalPortfolio: activeFilters.showInternalPortfolio,
        showExternalPortfolio: activeFilters.showExternalPortfolio,
        account: activeFilters.account,
      };
    try {
      const result = yield call(esgData.get, args);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readEsgData, ...resourceSagas];
