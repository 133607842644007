import React from 'react';

import { accent } from '../../constants/colors';
import { H3 } from '../texts';

export const NumberBox = ({
  color = accent.ocean2,
  backgroundColor = accent.ocean1,
  number = 0,
  size = 60,
  style,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        backgroundColor,
        width: size,
        height: size,
        ...style,
      }}
    >
      <H3 style={{ color }}>{number}</H3>
    </div>
  );
};
