import { useDispatch, useSelector } from 'react-redux';

import { selectors, actions } from '../../../store';
import { operatingCountrySelector } from '../../../store/auth/selectors';

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

const { investmentAccountsSelector } = selectors.entities.filterValues;

export const usePortfolioInvestmentAccountsFilter = () => {
  const dispatch = useDispatch();
  // Make sure list is unique
  const investmentAccounts = [
    ...new Set(useSelector(investmentAccountsSelector)),
  ];
  const excludedInvestmentAccounts = useSelector(
    (state) => state.ui.portfolio.filters.excludedInvestmentAccounts
  );
  const operatingCountry = useSelector(operatingCountrySelector);

  // Don't show investment account filter in Norway
  if (operatingCountry == 'no') {
    return [];
  }

  if (!investmentAccounts.length) return [];

  // Helper function to determine if a toggle switch can be disabled or not.
  // if all except one is excluded already, one should not be allowed to disable
  // the last one before another is enabled. The data source requires at least
  // one account to make any sense.
  const canChange = (account) => {
    const allButOneExcluded =
      excludedInvestmentAccounts.length + 1 === investmentAccounts.length;
    if (!allButOneExcluded) {
      return true;
    }

    // if account is in excluded, it can be toggled, if not that would
    // cause a problem since we have then excluded _all_ ssids, so that
    // should not be allowed
    if (excludedInvestmentAccounts.includes(account)) {
      return true;
    }

    return false;
  };

  const options = investmentAccounts.map((investmentAccount) => ({
    name: investmentAccount,
    disabled: !canChange(investmentAccount),
    selected: !excludedInvestmentAccounts.includes(investmentAccount),
    onClick: () =>
      dispatch(uiPortfolioActions.filterInvestmentAccount(investmentAccount)),
  }));

  return options;
};
