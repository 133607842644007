import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { localizeFloat, selectors, t } from '@formue-app/core';
import { BaseSection } from '../../../common/BaseSection';
import { H2, Paragraph, ParagraphXSmall } from '../../../texts';
import { accent } from '../../../../constants/colors';
import {
  SPACING_32,
  SPACING_12,
  SPACING_8,
  SPACING_48,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../../constants/spacing';
import { Button } from '../../../buttons';
import { Modal } from '../../../common/Modal';
import { SustainabilityPieChart } from './SustainabilityPieChart';
import { percentage } from '@formue-app/core/src/services/localization';
import { FundClassificationModalContent } from './FundClassificationModalContent';

const i18nPrefix = 'sustainability:fundClassification';

const {
  entities: {
    portfolioLenses: { fundSustainabilityDataSelector },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_32};
  display: flex;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${SPACING_12};
  padding-bottom: ${SPACING_32};
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
`;

const PieChartContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const FundClassification = (props) => {
  const fundSustainabilityData = useSelector(fundSustainabilityDataSelector);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (!fundSustainabilityData) return null;

  const { article9Ratio, article8Ratio, article6Ratio, notClassifiedRatio } =
    fundSustainabilityData;

  return (
    <StyledBaseSection {...props}>
      <Wrapper>
        <SustainabilityPieChart
          data={[
            { value: article9Ratio, color: accent.forest1 },
            { value: article8Ratio, color: accent.forest2 },
            { value: article6Ratio + notClassifiedRatio, color: accent.sand2 },
          ]}
        >
          <PieChartContent>
            <H2>
              {percentage(
                (article9Ratio + article8Ratio) * 100,
                1,
                false,
                false
              )}
            </H2>
          </PieChartContent>
        </SustainabilityPieChart>
      </Wrapper>
      <H2>{t(`${i18nPrefix}:title`)}</H2>
      <Paragraph style={{ padding: `${SPACING_8} 0` }}>
        {t(`${i18nPrefix}:subtitle`)}
      </Paragraph>
      <ButtonWrapper>
        <Button onClick={() => setModalIsOpen(true)} style={{ marginTop: 32 }}>
          {t('globals:readMore')}
        </Button>
      </ButtonWrapper>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={t('sustainability:fundClassification:title')}
        labelComponent={H2}
        padding={SPACING_48}
      >
        <FundClassificationModalContent />
      </Modal>
    </StyledBaseSection>
  );
};
