import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { maxBy } from 'lodash';

import {
  formatHistoricalDrawdownChartData,
  selectors,
  useResource,
  percentage,
} from '@formue-app/core';
import { lightenColor } from '@formue-app/core/src/services/utilities/colors';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_24,
  SPACING_64,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { ChartLegend } from '../slideComponents/portfolio/ChartLegend';
import { SpaghettiChart } from '../../charts/SpaghettiChart';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';

const {
  entities: {
    investmentStrategyIndexChanges: {
      investmentStrategyIndexChangesByIdsSelector,
    },
  },
} = selectors;

const Base = styled.div`
  height: 100%;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  display: flex;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const InnerChartWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 0 10px;
`;

const StyledChartLegend = styled(ChartLegend)`
  padding: 0 ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: ${SPACING_24};
  z-index: 1;
`;

export const HistoricalDrawdownSlide = (props) => {
  const [selected, setSelected] = useState();
  const strategies = useEnabledStrategies();

  const investmentStrategyIndexChangesByIds = useSelector(
    investmentStrategyIndexChangesByIdsSelector(
      strategies.map((strategy) => strategy.id)
    )
  );

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_INDEX_CHANGES/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  const chartData = strategies.reduce((acc, current) => {
    return {
      ...acc,
      [current.title]: formatHistoricalDrawdownChartData(
        investmentStrategyIndexChangesByIds.filter(
          (strategyIndex) => strategyIndex.investmentStrategy.id === current.id
        )
      ),
    };
  }, {});

  // This will determine which line in Spagetti chart has max number of items.
  // We need this in order to set the charts X-Axis in a proper way.
  const maxChartItemsLineId = maxBy(
    Object.keys(chartData),
    (chartItems) => chartItems.length
  );
  const maxChartItemsLine = chartData[maxChartItemsLineId];

  if (!maxChartItemsLine) return null;

  // Calculate the max and min values for Y axis in the chart. We need this so that
  // the chart will render properly even with very flat index curves, e.g just 0 values.
  // Without this the y-axis wouldn't make any sense and the chart will render wierd.
  // Since we are talking about drawdown, y should nenver be more than 0
  const maxDomain = { y: 0 };
  // Min domain needs to be at least 5%, but if we have lower values than that in the data-set
  // we don't want to clip that, so find the lowest value used for the y axis in the chart
  const lowestYAxisValue = Math.min(
    ...Object.values(chartData)
      .flatMap((strategyData) => strategyData)
      .map((datum) => datum.change)
  );
  // Add 2.5% to the lowest YAxisValue to make some padding below
  const minDomain = { y: Math.min(-0.025, lowestYAxisValue - 0.025) };

  return (
    <Base>
      <ChartWrapper>
        <StyledChartLegend
          isSelectable
          selectedCallback={(selectedItem) => {
            setSelected(selectedItem);
          }}
          selectMultiple={true}
          textColor={accent.ocean490}
          selectedTextColor={accent.ocean490}
          selectedBackgroundColor="#E0EBF5"
          selectedBorderColor={accent.ocean2}
          items={strategies.map((strategy, index) => ({
            ...strategy,
            label: strategy.title,
            key: strategy.id,
            color: strategy.color,
          }))}
        />

        {loading ? (
          <CenteredActivityIndicator />
        ) : (
          <InnerChartWrapper style={{ paddingTop: SPACING_64 }}>
            <SpaghettiChart
              interpolation="monotoneX"
              dataObject={chartData}
              valueKey="change"
              xAxisTicks={maxChartItemsLine.map((value) => value.x)}
              xAxisTicksFormat={(value) => value.getFullYear()}
              yAxisTicksFormat={(value) => percentage(value * 100, 2)}
              selected={selected}
              isSelected={(selectedKeys, entryKey) =>
                selectedKeys.includes(
                  strategies.find((item) => item.title === entryKey).id
                )
              }
              colorizeFunction={(entryKey, index) => {
                const strategy = strategies.find(
                  (item) => item.title === entryKey
                );

                return strategy.color;
              }}
              loading={loading}
              labelFormat={(entryKey) => entryKey}
              tickCount={11}
              areaChart={true}
              xAxisStickToBottom={true}
              areaChartOpacity={0.08}
              domainPadding={{ x: [20, 0], y: [30, 10] }}
              padding={{ bottom: 5, top: 5 }}
              showLineLabels={false}
              defaultLineColor="transparent"
              ticksColor={accent.ocean170}
              ticksBackground={accent.sand1}
              gridColor={lightenColor(accent.ocean440, 0.4)}
              animate={false}
              reverseDataRendering={true}
              tooltipEnabled
              maxDomain={maxDomain}
              minDomain={minDomain}
            />
          </InnerChartWrapper>
        )}
      </ChartWrapper>
    </Base>
  );
};
