import React from 'react';
import { animated } from 'react-spring';

export const RoundedBar = ({
  width,
  style,
  color = '#333',
  height = 20,
  allEdgesRounded,
}) => {
  return (
    <animated.div
      style={{
        backgroundColor: color,
        borderTopRightRadius: height / 2,
        borderBottomRightRadius: height / 2,
        borderTopLeftRadius: allEdgesRounded ? height / 2 : 0,
        borderBottomLeftRadius: allEdgesRounded ? height / 2 : 0,
        width,
        height,
        ...style,
      }}
    />
  );
};
