import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { LensesNavigation } from '../../components/portfolio/lenses/LensesNavigation';
import { RouteWithSubRoutes } from '../../components/router';

export const LensesPage = (props) => {
  const { routes, location } = props;

  // If we are one a "LensesPage" we don't really have anything to show so do an
  // automatic redirect to the first sub route.
  return (
    <>
      {location.pathname === '/portfolio/lenses' && (
        <Redirect to={routes[0].path} />
      )}
      <LensesNavigation routes={routes} />
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </>
  );
};
